export default {
  common: {
    elven: 'elven',
    page: '页',
    team: '团队',
    tel: '电话',
    language: '语言',
    yourPassword: '你的密码',
    phoneNumber: '电话号码',
    email: '邮箱',
    emailAddress: '邮箱地址',
    password: '密码登录',
    captcha: '验证码',
    sendCaptchaCountdown: '秒',
    agreementText: '阅读并同意', // 同意协议文案
    userService: '服务条款',
    serviceAgreement: '《隐私政策》', // 服务协议
    noData: '暂无数据',
    address: '地址',
    addresses: '地址',
    addressName: '地址名称',
    dateTime: '时间',
    amountIn: '金额（流入）',
    amountOut: '金额（流出）',
    FromTo: '付款方/收款方',
    currency: '币种',
    lineChart: '折线图',
    barChart: '柱状图',
    numberChart: '数字',
    equal: '等于',
    greaterThan: '大于',
    lessThan: '小于',
    range: '范围',
    start: '开始',
    end: '结束',
    to: '到',
    allRightsReserved: '版权所有',
    updatedOn: '更新于 ',
    syncedOn: '同步于',
    dataSources: '数据来源',
    share: '分享',
    financials: '财务',
    business: '业务',
    valuation: '估值',
    project: '项目',
    projects: '项目',
    totalProject: '项目总数',
    list: '列表',
    results: '结果',
    result: '结果',
    group: '分组',
    groupView: '分组视图',
    secondaryGroup: '二级分组',
    tertiaryGroup: '三级分组',
    color: '颜色',
    block: '块',
    size: '大小',
    axise: '轴',
    rulesForSubtotal: '小计规则',
    keepGroup: '保持分组视图',
    choose: '选择',
    stick: '固定',
    unstick: '取消固定',
    hideColumn: '隐藏列',
    sortAscending: '生序排列',
    sortDescending: '降序排列',
    monitor: '监控',
    if: '如果',
    then: '然后',
    frequency: '频率',
    name: '名称',
    condition: '条件',
    soon: '即将到来',
    total: '总计',
    marketTotal: '总',
    addFilter: '新增筛选条件',
    filter: '筛选',
    pleaseChoose: '请选择',
    searchMetrics: '搜索指标',
    createdOn: '创建时间',
    lastExcution: '上次执行时间',
    runsEveryDay: '每天执行',
    everyDay: '每天',
    keepAlerts: '保留提醒',
    alertTime: '每天UTC 00:30发送。',
    account: '账户',
    contact: '联系人',
    openingBalance: '期初余额',
    closingBalance: '期末余额',
    journalEntries: '记账凭证',
    debit: '借方',
    credit: '贷方',
    event: '事件',
    txCount: '交易计数',
    amount: '金额',
    millions: '百万',
    billions: '十亿',
    thousands: '千',
    individuals: '个位',
    reportUnit: '单位：{unit}{currency}',
    amountsUnit: '金额以{unit}美元为单位',
    willBeComingSoon: '即将上线',
    comingSoon: '即将来临',
    description: '描述',
    chain: '链',
    chains: '链',
    custody: '托管',
    payment: '支付',
    basedAddressOnChain: '基于{chainCount}条{chain}上的{addressCount}条{address}',
    allMetrics: '所有指标',
    addMetric: '添加指标',
    basicInfo: '基础信息',
    followTableSorting: '按照表格排序进行操作',
    numberOfGroups: '将被分成{count}个组',
    fieldTotalRule: '{field}总计规则：',
    fieldSubtotalRule: '{field}子总计规则：',
    Is: '是',
    'Is not': '不是',
    'Equal to': '等于',
    'More than': '大于',
    'Less than': '小于',
    within: '介于',
    'Within range': '在范围内',
    'Outside range': '在范围外',
    'Is empty': '为空',
    'Is not empty': '非空',
    shareTo: '分享至',
    selectAccountingPeriod: '选择会计期间：',
    createAccount: '创建账户',
    activateYourAccount: '激活您的账户',
    verifyEmail: '验证您的电子邮件',
    continue: '继续',
    continueWithEmail: '通过 邮箱 继续操作',
    signInWithVerificationCode: '通过验证码继续操作',
    signInWithPassword: '通过密码继续操作',
    or: '或者',
    sameInAllEntities: '在所有主体中相同',
    expandControlPanel: '展开控制面板',
    copied: '已复制',
    moreInformation: '有关更多信息，请',
    selected: '选中',
    select: '选择',
    syncFailed: '同步失败',
    syncing: '同步中',
    updating: '更新中',
    sum: '总计',
    type: '类型',
    status: '状态',
    priority: '优先级',
    conditions: '条件',
    actions: '操作',
    date: '日期',
    is: '是',
    in: '在',
    accountName: '科目名称',
    accountType: '账户类型',
    accountTag: '账户标签',
    counterpartyTag: '对手方标签',
    counterpartyAccount: '对手方账户',
    contactType: '联系人类型',
    equals: '等于',
    doesNotEqual: '不等于',
    isGreaterThanOrEqualTo: '大于或等于',
    isGreaterThan: '大于',
    isLessThanOrEqualTo: '小于或等于',
    isLessThan: '小于',
    isBetween: '介于',
    isOn: '在',
    isBefore: '在之前',
    isAfter: '在之后',
    on: '在',
    before: '之前',
    after: '之后',
    between: '介于',
    includes: '包括',
    notIn: '不在',
    api: 'API',
    wallet: '钱包',
    wallets: '钱包',
    exchange: '交易所',
    exchanges: '交易所',
    fileName: '文件名',
    csvFiles: 'CSV文件',
    customizedAPI: '自定义API',
    entity: '主体',
    entities: '主体',
    users: '用户',
    month: '月',
    annually: '年',
    free: '免费',
    basic: '基础',
    pro: '专业版',
    enterprise: '企业版',
    unlimited: '无限制',
    CEO: '首席执行官 (CEO)',
    CFO: '首席财务官 (CFO)',
    CAO: '首席会计官 (CAO)',
    financeManager: '财务经理',
    accountingManager: '会计经理',
    financialAnalyst: '财务分析师',
    financialAdvisor: '财务顾问',
    taxSpecialist: '税务专家',
    others: '其他',
    other: '其他',
    label: '标签',
    cryptoNativeFirms: '加密原生公司',
    assetManagementFirms: '资产管理公司',
    onChainProjects: '链上项目',
    MiningStakingNodeOperators: '挖矿、质押和节点运营商',
    ventureCapitals: '风险投资',
    done: '完成',
    transactionsEntitiesUsers: '交易，主体，用户',
    enterprisePlanInfo: '适用于交易量大、支持和性能需求更高的大型组织。',
    projectSettings: '项目设置',
    entitySettings: '主体设置',
    general: '通用设置',
    integrations: '集成',
    integration: '集成',
    member: '成员',
    members: '成员',
    PlanBilling: '版本和账单',
    security: '安全',
    customPricing: '自定义定价',
    subscription: '订阅',
    currentPlan: '当前计划',
    startDate: '开始日期',
    expirationDate: '到期日期',
    subscriptionStatus: '订阅状态',
    deleteMember: '删除成员',
    deleteEntity: '删除主体',
    administrator: '管理员',
    report: '报告',
    reports: '报告',
    settings: '设置',
    automation: '自动化',
    view: '视图',
    ledger: '账本',
    viewRecords: '查看记录',
    viewListPermission: '查看{operating}列表',
    viewDetailPermission: '查看{operating}详情',
    addNewPermission: '新增{operating}',
    editPermission: '编辑{operating}',
    deletePermission: '删除{operating}',
    exportPermission: '导出{operating}',
    generatePermission: '生成{operating}',
    reviewPermission: '审核{operating}',
    categorizePermission: '分类{operating}',
    executePermission: '执行{operating}',
    syncToErp: '同步到ERP',
    source: '来源',
    add: '添加',
    edit: '编辑',
    delete: '删除',
    export: '导出',
    review: '审核',
    rule: '规则',
    rules: '规则',
    active: '激活',
    expired: '已过期',
    next: '下一步',
    prev: '上一步',
    skip: '跳过',
    alreadyUser: '已经是用户？',
    newToElven: '新用户？',
    file: '文件',
    uploadFile: '上传文件',
    bank: '银行',
    client: '客户端',
    activity: '活动',
    uploadAttachments: '上传附件',
    balance: '余额',
    '90D Revenue': '90天收入',
    '90D Expense': '90天支出',
    '90D Profit': '90天利润',
    ruleName: '规则名称',
    selectConditionsInfo: '如果满足以下所有条件：',
    selectCondition: '选择一个条件',
    selectAColumn: '选择一列',
    selectTheDataType: '选择数据类型',
    performActions: '执行以下操作：',
    categorizeTransaction: '将交易分类为',
    otherBank: '其他银行',
    sort: '排序',
    data: '数据',
    updateMethod: '更新机制',
    period: '时间范围',
    updated: '最近更新时间',
    onChain: '链上',
    transferIn: '转入',
    transferOut: '转出',
    transactionFee: '交易费',
    bankFee: '银行费',
    exchangeFee: '交易所费',
    custodyFee: '托管费',
    paymentFee: '支付费',
    deposit: '存款',
    withdraw: '提现',
    trade: '交易记录',
    tradeIn: '交易进',
    tradeOut: '交易出',
    capture: '捕获',
    completed: '已完成',
    chargeBack: '退单',
    refund: '退款',
    payout: '支付',
    stake: '质押',
    unstake: '解质押',
    reward: '奖励',
    realizedPnL: '已实现盈亏',
    borrow: '借款',
    repay: '还款',
    fundingFee: '资金费',
    outgoingFX: '出口FX',
    incomingFX: '进口FX',
    auto: '自动',
    daily: '每日',
    mannual: '手动',
    all: '全部',
    bookkeeping: '记账',
    custodian: '托管',
    openApi: 'Open API',
    tag: '标签',
    dataIntegration: '数据集成',
    dataPeriod: '数据期间',
    tagsAndMemo: '标签和备注',
    counterparty: '对手方',
    contentTextName: '{content}名字',
    accountId: '账户ID',
    courseAccountId: '科目编码',
    parentAccountId: '父科目编码',
    accountNumber: '账户号码',
    bankAccount: '银行账户',
    posted: '已过账',
    difference: '差异',
    quarter: '季度',
    year: '年',
    day: '日',
    Month: '月',
    week: '周',
    dateRange: '日期范围',
    price: '价格',
    newestToOldest: '由近到远',
    oldestToNewest: '由远到近',
    groupByEvent: '按事件分组',
    businessEvent: '业务卡片',
    collapseAll: '折叠明细',
    expandAll: '展开明细',
    summary: '摘要',
    history: '历史',
    platform: '平台',
    platformType: '平台类型',
    timezone: '时区',
    passed: '已通过',
    failed: '已失败',
    notVerified: '未验证',
    code: '代码',
    createdBy: '创建人',
    system: '系统',
    default: '默认',
    deleted: '已删除',
    dataName: '数据名称',
    totalLines: '总行数',
    original: '原始',
    originalData: '原始数据',
    originalDataLines: '原始数据行数',
    identifiedJournals: '已识别记账凭证',
    identificationStatus: '识别状态',
    cannotBeChanged: '完成设置后无法修改',
    allActivity: '所有活动',
    allEntity: '所有主体',
    allMember: '所有成员',
    login: '登录',
    accountTreasury: '账户 (财务)',
    manualCreated: '手动创建',
    value: '值',
    customOptions: '自定义选项',
    operation: '操作',
    crypto: '加密货币',
    fiatFX: '法币 (FX)',
    foreignCurrency: '外币',
    inventory: '库存',
    brokerInventory: 'OTC 库存管理',
    tags: '标签',
    totalAmount: '总金额',
    direction: '方向',
    excludes: '排除',
    successfully: '成功',
    failure: '失败',
    item: '项',
    items: '项',
    verify: '验证',
    verifying: '验证中',
    options: '选项',
    subgroup: '二级分组',
    uncategorized: '未分类',
    accountingLedger: '会计账簿',
    cashBasis: '收付实现制',
    accrualBasis: '权责发生制',
    text: '文本',
    number: '数字',
    futures: '合约',
    futureOpen: '合约开仓',
    futureClose: '合约平仓',
    Options: '期权',
    optionBuy: '期权买入',
    optionSell: '期权卖出',
    optionExercise: '期权行权',
    spotBuy: '现货买入',
    spotSell: '现货卖出',
    foreignExchange: '外汇交易',
    swap: '互换',
    futureRealized: '合约已实现',
    futureUnrealized: '合约未实现',
    optionRealized: '期权已实现',
    optionUnrealized: '期权未实现',
    totalKeys: '总密钥',
    created: '已创建',
    totalAsset: '总资产',
    addAsset: '添加资产',
    editAsset: '编辑资产',
    spam: '垃圾邮件',
    input: '请输入',
    asset: '资产',
    method: '方法',
    future: '合约',
    table: '表格',
    origin: '来源',
    symbol: '符号',
    weekly: '每周',
    Monthly: '每月',
    updatedAt: '最近更新时间',
    preview: '预览',
    allCurrencies: '全部币种',
    trial: '试用',
    mainServicesAgreement: '服务条款',
    reminder: '提醒',
    updateNow: '现在更新',
    entries: '分录',
    entry: '分录',
    standard: '标准',
    customized: '定制',
    mostPopular: '最受欢迎',
    customizedVersion: '定制化版本',
    since: '于{time}订阅',
    nextPayment: '下一次付款日期{time}',
    unsubscribe: '取消订阅',
    resubscribe: '重新订阅',
    canceled: '已取消',
    exceededLimit: '超出限制',
    paymentFailed: '支付失败',
    usage: '用量',
    invoiceNo: '账单编号',
    paid: '已支付',
    activate: '激活',
    dataTypes: '数据类型',
    filters: '筛选项',
    beta: '测试版',
    rebate: '退款',
    protocol: '协议',
    checkPoint: '日切时间点',
    ExportDate: '导出日期',
    categorizing: '分类中',
    dataUntil: '之前的数据（包含当日）',
    dataSince: '之后的数据（包含当日）'
  },
  report: {
    Total: '总计',
    dataSource: '数据源',
    journals: '记账凭证',
    Journal: '记账凭证',
    JournalList: '凭证列表',
    actionJournal: '操作 / 记账凭证',
    actionCreateJournal: '操作 / 创建记账凭证',
    actionCreateValuationData: '操作 / 创建调账数据',
    actionEntry: '操作 / 分录',
    entry: '分录',
    reconciliation: '对账',
    actionCreateData: '操作 / 创建数据',
    actionIdentifyCounterparty: '操作 / 识别对手方',
    actionReconciliation: '操作 / 对账',
    relatedTransaction: '相关交易',
    relatedTrade: '相关交易',
    relatedGainLoss: '相关 盈利/亏损',
    relatedJournal: '相关记账凭证',
    basedOnCategorizing: '基于关联凭证',
    basedOnReconciliation: '基于对账',
    Address: '地址',
    Account: '科目',
    'Address Name': '地址名称',
    Status: '状态',
    Datetime: '时间',
    Date: '日期',
    Currency: '币种',
    TrailBalanceStatus: '过账状态',
    'Auxiliary code': '辅助核算',
    'Chart of account': '科目',
    'Treasury account': '财务账户',
    Amount: '金额',
    'Amount (In)': '金额（流入）',
    'Amount (Out)': '金额（流出）',
    'From/To': '付款方/收款方',
    'Dr/Cr': 'Dr/Cr',
    journalType: '凭证模板',
    journalTypeName: '凭证模板名称',
    dateJournalNo: '日期&凭证编号',
    referenceNo: '索引号',
    auxiliaryCode: '辅助核算',
    Match: '匹配',
    balanceVerify: '余额校验状态',
    verifyBalance: '余额校验',
    verifyingBalance: '正在余额校验',
    refreshingBalance: '正在刷新余额',
    balanceExport: '余额导出',
    balanceDelete: '余额删除',
    Description: '描述',
    Debit: '借方',
    Credit: '贷方',
    Event: '事件',
    'Opening Balance': '期初余额',
    'Closing Balance': '期末余额',
    totalInflow: '总流入金额',
    totalOutflow: '总流出金额',
    totalDebit: '总借方金额',
    totalCredit: '总贷方金额',
    'Type of Assets': '资产类型',
    'Nominal Amount': '名义金额',
    'Market Value': '市场价值',
    '% of total net assets': '占总净资产的百分比',
    Multiples: '倍数',
    IRR: '内部收益率',
    'Cost basis': '成本基础',
    'Realized Proceeds': '已实现收益',
    'Cost basis of Realized Proceeds': '已实现收益成本基础',
    ON_CHAIN: '链上口径',
    OFF_CHAIN: '链下口径',
    GLOBAL: '合并口径',
    syncingWalletsOrExchanges: '同步中 钱包/交易所',
    synchronizingTotal: '{total}个来源正在同步',
    synchronizingTotals: '{progress}/{total}的来源正在同步',
    syncingSources: '同步来源 ({progress}/{total})',
    syncTransactionsTotal: '条交易已经导入，',
    syncTransactionsComplete: '同步完成，',
    syncComplete: '同步完成，请点击“重新生成报告”按钮更新报告。',
    deletedAccountTitle: '删除{name}',
    deletedAccountInfo: '将从此账户删除所有交易和来源，此操作无法恢复。',
    batchEdit: '批量修改',
    totalContacts: '{total} 个联系人',
    contactType: '联系人类型',
    employee: '员工',
    vendor: '供应商',
    customer: '客户',
    investor: '投资者',
    community: '社区',
    debtor: '债务人',
    sponsor: '赞助商',
    memo: '备注',
    transaction: '交易',
    sent: '发送',
    received: '接收',
    internal: '内部转账',
    source: '来源',
    Source: '来源',
    Name: '名称',
    Memo: '备注',
    Assets: '资产',
    assets: '资产',
    assetsPortfolio: '资产组合',
    liabilities: '负债',
    equity: '权益',
    Quantity: '数量',
    'Cost Basis': '成本基础',
    'Fair Value': '公允价值',
    'Address/Bank Account': '钱包地址/银行账户',
    'Address/Account': '钱包地址/账户号码',
    'Significant holdings report': '重大持股报告',
    allSources: '所有来源',
    Transactions: '交易',
    transactionDetail: '交易详情',
    datetime: '时间',
    amount: '金额',
    from: '从',
    to: '到',
    accounting: '账单',
    transactionsTotal: '{total} 条交易',
    regenerateReports: '重新生成报表',
    regenerating: '正在重新生成',
    capitalGainORLoss: '资本损益',
    cryptoFairValueMeasurement: '加密货币公允价值计量',
    FXRate: '汇率',
    priceProvider: '价格源',
    priceDatetime: '价格时间 (UTC)',
    editTimeUTC: '用户创建/编辑的时间 (UTC)',
    salesNo: '销售编号',
    pricePair: '价格对',
    FXProvider: '汇率提供商',
    FXDatetime: '汇率时间 (UTC)',
    FXPair: '汇率对',
    FXPrice: '汇率价格',
    marketValue: '市值',
    sourceName: '来源名称',
    accountNumber: '账户号码',
    key: 'Key',
    secret: 'Secret',
    passphrase: 'Passphrase',
    accountingPreview: '会计分录预览:',
    accountingPreviewInfo: '会计分录将在更新报表时生效',
    regenerateTheReports: '交易数据已更改，点击重新生成报告',
    regenerated: '已重新生成',
    generating: '正在生成',
    'Rule Engine Configure Accounting Entries and Cost Basis': '自动化规则配置会计分录和成本基础',
    'Generate Income Statement report': '生成损益表报告',
    'Generate Balance Sheet report': '生成资产负债表报告',
    'Generate Restrictions of Crypto Assets report': '生成加密资产限制报告',
    'Generate Cash Flow Statement report': '生成现金流量表报告',
    'Generate Crypto Assets Roll Forward report': '生成加密资产滚动报告',
    'Generate Significant Holdings report': '生成重大持股报告',
    'Generate note L1': '生成注释L1',
    'Generate note L2': '生成注释L2',
    Tabulating: '正在制表',
    'Reports have been regenerated successfully': '报告已成功重新生成',
    'Regenerate Failed, please check your transaction data': '重新生成失败，请检查您的交易数据',
    deleting: '正在删除',
    reportRegeneratedSuccess: '报告已重新生成',
    noSet: '未设置',
    transactionsRule: '{total}笔交易符合规则:',
    similarTransactions: '{total}笔相似交易:',
    setEvent: '设置事件',
    resolutionCenter: '解决中心',
    resolve: '解决',
    wellDone: '干得好',
    transactionsCategorized: '所有交易被分类。',
    duplicateTransactions: '没有可疑的重复交易',
    keepAll: '保留所有',
    keepAllConfirm: '这些交易看起来像是重复数据，您确定要保留它们吗？',
    possibleDuplicates: '可能的重复',
    possibleDuplicatesInfo: '{total}组交易彼此相似，它们可能是相同的吗？',
    possibleDuplicateInfo: '{total}组交易彼此相似，它们可能是相同的吗？',
    unknownEvent: '未知事件',
    unknownEventInfo: '{total}笔交易无法识别业务活动。',
    unknownEventsInfo: '{total}笔交易无法识别业务活动。',
    disposalExceedsBalance: '处置超过余额 - {currency}',
    disposalExceedsBalanceInfo: '在某些交易中，{currency}处置超过了余额。请验证来源或类别的准确性。',
    errorReport: '{sheet}计算错误',
    errorReportInfo: '报告中存在一些数字错误',
    discard: '丢弃',
    batchConfirm: '批量确认',
    batchSettingEvents: '批量设置事件',
    categorizeTransactionAs: '将交易分类为',
    transactionType: '交易类型',
    inflow: '流入',
    outflow: '流出',
    manuallyExecuteRules: '手动执行所有规则',
    rulesAreExecuting: '规则正在执行',
    operating: '经营活动',
    financing: '融资',
    investing: '投资',
    internalTransfer: '内部转账',
    gainOrLossAdjusting: '损益调整',
    generalJournal: '普通日记账',
    automaticallyReviewed: '自动标记为已审核',
    deleteRule: '删除规则',
    executeRule: '执行规则',
    individual: '个人',
    organization: '组织',
    unnamed: '未识别',
    identified: '已识别',
    unidentified: '未识别',
    named: '已识别',
    addYourWallet: '添加您的第一个钱包',
    addWalletInfo: '添加一个钱包以导入链上交易数据',
    addYourExchange: '添加您的第一个交易所',
    addExchangeInfo: '从交易所获取交易数据使用API或Auth',
    uploadCSVFile: '上传一个CSV文件',
    uploadCSVFileInfo: '通过上传CSV文件导入离线交易数据',
    welcomeUser: '欢迎， {userName}',
    startCreateProject: '现在开始创建您的第一个项目！',
    choosePlan: '选择订阅计划',
    applyForTrial: '申请试用',
    applyForTrialInfo: '感谢您的关注，请填写下面的表格，我们会尽快与您联系。',
    applyWaiting: '您已在等待列表中，请耐心等待我们的回复',
    yourOrganization: '您的组织',
    yourPosition: '您的职位',
    industry: '行业',
    numberOfHistoricalTransactions: '历史交易数量',
    otherCryptoFinancialProductsUsed: '使用的其他加密金融产品',
    howCanElvenHelpYou: 'Elven如何帮助您？',
    projectName: '项目名称',
    entityName: '主体名称',
    projectLogo: '项目Logo',
    entityLogo: '主体Logo',
    countryRegion: '国家/地区',
    GSTSetting: '销售税设置',
    enableGST: '启用销售税',
    dataStartingTime: '数据起始时间',
    costBasisMethodology: '成本计价方法',
    fairValueCostBasisMethodology: '公允价值成本计价方法',
    defaultCurrency: '默认币种',
    functionalCurrency: '功能币种',
    preferredCurrencyUnit: '首选货币单位',
    projectHasBeenCreated: '您的项目已创建！',
    getStartedOnFewEasySteps: '开始几个简单的步骤：',
    cancelRequestInfo: '您已申请试用，目前正在等待批准。您现在要取消吗？',
    needHelp: '需要帮助？',
    bookDemoTitle: '预约演示',
    bookDemoInfo: '您可以预约远程视频演示，我们的专家团队将指导您使用Elven。',
    ReadDocsTitle: '阅读文档',
    ReadDocsInfo: '阅读我们的全面帮助文档，它将指导您完成使用Elven的每一步。',
    ruleCount: '{total}条规则',
    startingFirstProject: '从创建您的第一个项目开始。',
    category: '类别',
    fileTemplate: '文件模板',
    totalLines: '总行数',
    unrecognizableLines: '不可识别行数',
    transactionsDiscarded: '将被丢弃的交易',
    transactionsImported: '将被导入的交易',
    recognizedLines: '可识别行数',
    onlyGenericFormatCSV: '仅支持通用格式CSV，',
    onlyAcceptCSV: '仅接受符合Elven标准格式的CSV文件，',
    clickDownloadTemplate: '点击下载模板文件',
    uploading: '正在上传',
    parsingFile: '正在解析文件',
    parsingFailed: '解析失败',
    uploadCompleted: '上传完成',
    wrongDataFormat: '数据格式错误',
    businessDataCSVFormatError: '此CSV文件的格式与{name}数据不一致',
    unableRecognizeYourFile: '无法识别您的文件',
    dragUploadOrBrowse: '拖放上传，或浏览文件。',
    uploadCSVMaxSize: '支持文件类型：csv，最大大小：100m',
    selectAccountInThisFile: '选择此文件中的账户',
    added: '添加时间',
    addedBy: '添加人',
    addedDate: '添加日期',
    addedMethod: '添加方法',
    manualAdded: '手动添加',
    automaticSynced: '自动同步',
    dataCutoffTime: '更新时间',
    editWallet: '编辑钱包',
    editExchange: '编辑交易所',
    editCSV: '编辑CSV',
    generalTransactions: '一般交易',
    customTransactions: '定制交易',
    bankTransactions: '银行交易',
    exchangeTrade: '交易所（交易）',
    exchangeDeposit: '交易所（充值）',
    exchangeWithdraw: '交易所（提现）',
    completeHistory: '完整历史数据',
    dataBefore: '之前的数据',
    dataAfter: '之后的数据',
    dataBetween: '之间的数据',
    transactionDataOption: '交易数据选项',
    addSources: '添加来源',
    addSourcesInfo: '为您的项目添加链上钱包或交易所，或上传CSV文件。',
    reviewTransactions: '审阅交易',
    reviewTransactionsInfo: '审阅交易并将每笔交易与正确的业务活动标签匹配。',
    generateReports: '生成报告',
    generateReportsInfo: '只需点击一下即可生成财务报表和加密资产披露！',
    addMemo: '添加备注',
    groupsDuplicatesTotal: '{total}组交易可能是重复的：',
    platformSource: '{platform}数据源',
    chartOfCount: '会计科目表',
    accountBalance: '科目余额表',
    preparer: '处理人',
    approver: '审核人',
    approve: '审核',
    reject: '驳回',
    unMatchedAmount: '未匹配金额',
    matches: '已匹配',
    matchSetMatches: '已匹配记录数',
    approved: '已审核',
    prepared: '已编制',
    notPrepared: '未编制',
    matchSetDetail: '对账工作详情',
    unmatched: '未匹配',
    unmatch: '取消匹配',
    submitAsPrepared: '提交为已编制',
    matchedBy: '匹配人',
    matchDetail: '匹配详情',
    matchId: '匹配ID',
    matched: '已匹配',
    matchSets: '对账工作',
    inflowTransactions: '流入交易',
    outflowTransactions: '流出交易',
    holdling: '持有',
    processing: '处理中',
    treasuryAccount: '财务账户',
    businessData: '业务数据',
    originalAmount: '原始金额',
    reportingAmount: '报告金额',
    originalCurrency: '原始币种',
    cashAccount: '现金账户',
    'Datasource provider': '数据源',
    calculatedBalance: '显示根据交易和手动添加的余额计算出的余额',
    syncedBalance: '显示从{source}同步的余额',
    refreshBalanceInfo: '您每天可以刷新余额{total}次，当前还剩{left}次。',
    'Base Asset': '标的资产',
    transfer: '转账记录',
    transferId: '转账标识',
    positionAndSide: '仓位方向',
    baseAndAmount: '标的资产金额',
    counterAndAmount: '计价资产金额',
    feeAndAmount: '手续费',
    counterAsset: '计价资产',
    feeAsset: '手续费币种',
    tradeId: '交易ID',
    tradeDetail: '交易细节',
    gainLossAsset: '收益（损失）计量资产',
    gainLoss: '收益(损失)',
    gainLossAmount: '收益（损失）金额',
    'Asset Symbol': '衍生品名称',
    'Position Side': '仓位方向',
    'Margin Asset': '保证金资产',
    'Unrealized P&L': '未实现盈亏',
    gainLossID: '损益记录ID',
    identifyCounterparty: '识别对手方',
    accountName: '账户名',
    companyFullName: '公司全称',
    employees: '员工数量',
    website: '网站',
    yourFirstName: '您的名字',
    yourLastName: '您的姓氏',
    yourJobTitle: '您的职位',
    yourWorkEmail: '您的工作邮箱',
    projectReadyForUse: '您的项目已经可以使用了',
    upEntityInformation: '请设置您的主体信息。',
    taskName: '任务名称',
    dashboardPeriod: '周期',
    'Account/Protocol': '账户/协议',
    Type: '类型',
    Pool: '池',
    Supply: '供应',
    'Pending Rewards': '待领取奖励',
    Borrow: '借款'
  },
  title: {
    loginPageTitle: '强大的编辑功能使数据显示更加清晰。',
    loginPageTitle1: '创建我自己的筛选器！',
    loginToExploreFurther: '登录以进一步探索',
    setPasswordsTitle: '必须先设置密码，以后才能密码登录。',
    exceedsExportLimit: '超出导出限制',
    connectIntegrationWalletInfo: '将{platform}链接到您的钱包，以便将钱包的记账凭证数据自动同步到您的Elven。',
    connectIntegrationInfo: '将Elven链接到您的账户，以便将Elven的记账凭证数据自动同步到您的{platform}。',
    AccountInPlatform: '{platform}账户',
    disconnectWithPlatform: '断开与{platform}的连接',
    deleteAttachment: '删除附件',
    addChainWallet: '添加{chain}钱包',
    addPlatformAccount: '添加{platform}账户',
    addContactInformation: '添加联系人信息',
    editContactInformation: '编辑联系人信息',
    contactInformation: '联系人信息',
    setupTwoFactorAuthentication: '设置双重验证',
    twoFactorAuthentication: '双重验证',
    authenticatorEnabled: '已启用身份验证应用',
    authenticatorDisabled: '未启用身份验证应用',
    twoFactorVerificationCode: '双重验证验证码',
    disableTwoFactorAuthentication: '禁用双重验证',
    currentPassword: '当前密码',
    newPassword: '新密码',
    backupCodes: '备份代码',
    createNewSetBackupCodes: '创建新的备份代码',
    securelyStoreYourBackupCodes: '安全存储您的备份代码',
    configureYourTwoFactorClient: '配置您的双重验证客户端',
    verifyTheConfiguration: '验证配置',
    allCrypto: '所有加密货币',
    allFiat: '所有法币',
    allPair: '所有交易对',
    deleteBalance: '删除余额',
    deleteBusinessEvent: '删除业务活动',
    configureBusinessEvent: '配置业务活动',
    addBusinessEvent: '添加业务活动',
    addJournal: '新增记账凭证',
    editJournal: '编辑记账凭证',
    createJournal: '新增记账凭证',
    createJournalCell: '生成记账凭证',
    memoAndAttachment: '备注和附件',
    entryLines: '分录行',
    GSTRate: 'GST 税率',
    projectEnded: '您的免费试用已结束',
    deleteJournal: '删除记账凭证',
    deleteCustomTitle: '删除{name}',
    businessDataIntegration: '业务数据集成',
    additionalItem: '附加项目',
    balanceType: '方向',
    newAccount: '新科目',
    editAccount: '编辑科目',
    parentAccount: '父科目',
    accountId: '科目编码',
    accountName: '科目名称',
    checkingAccountStatus: '检查科目状态中',
    deleteAccount: '删除科目',
    newJournalType: '新增凭证模板',
    editJournalType: '编辑凭证模板',
    journalTypeName: '凭证模板名称',
    checkingJournalTypeStatus: '检查凭证模板状态中',
    deleteJournalType: '删除凭证模板',
    treasuryAccountMapping: '账户与科目映射',
    accountMapping: '科目映射',
    accountMappingDesc: '在导出Xero的试算平衡表之前，请完成账户映射',
    chartOfAccounts: '会计科目表',
    mapping: '映射',
    editMapping: '编辑映射',
    businessEventName: '业务卡片名称',
    groupingCriterion: '分组标准',
    includedJournals: '包含记账凭证',
    eventSummary: '事件摘要',
    journalName: '记账凭证名称',
    auxiliaryCodeName: '辅助核算名称',
    auxiliaryCodeType: '辅助核算类型',
    auxiliaryCodeValue: '辅助核算选项',
    addAuxiliaryCode: '添加辅助核算',
    editAuxiliaryCode: '编辑辅助核算',
    identificationCode: '识别代码',
    onChainAddress: '链上地址',
    bankAccount: '银行账户',
    brokerInventoryType: 'OTC 库存类型',
    setCostBasisMethod: '设置成本计价方法',
    setCostBasisMethodTitle: '选择计算成本基础的方法.',
    saleDetail: '销售明细',
    exportData: '导出数据',
    fileIsImporting: '{name}正在导入',
    fileImportCompleted: '{name}导入完成',
    fileImportFailed: '{name}导入失败',
    deleteIdentificationCode: '删除识别代码',
    selectCounterparty: '选择对手方',
    startingDate: '开始日期',
    importLots: '导入批次',
    importInventory: '导入库存',
    addMatchSet: '添加对账工作',
    editMatchSet: '编辑对账工作',
    deleteMatchSet: '删除对账工作',
    importOnChainWalletAccount: '导入链上钱包账户',
    thisAccountCanNotBeDeleted: '此帐户不能删除',
    deletedAccounts: '已删除账户',
    checkingDeleteAccountStatus: '检查账户状态',
    deleteAccountDialogTitle: '删除账户',
    openAPIIntegration: 'OpenAPI 集成',
    addOpenAPIkey: '添加OpenAPI密钥',
    editOpenAPIkey: '编辑OpenAPI密钥',
    deleteAPIKeySecret: '删除API Key/Secret',
    searchByName: '按名称搜索',
    assetName: '资产名称',
    smartContract: '智能合约',
    symbolCode: '符号/代码',
    decimal: '小数',
    assetLogo: '资产图标',
    addContract: '添加合约',
    contract: '合约',
    position: '仓位',
    executeAllRules: '执行所有规则',
    executeRuleName: '执行规则 {name}',
    addPriceProvider: '添加价格源',
    uploadPriceData: '上传价格数据',
    adjustmentFrequency: '调账频率',
    conditionsHaveChanged: '条件已更改',
    changePriceProvider: '更改价格源',
    basedOnWhichInformation: '基于哪些信息',
    RegexForExtractingIdentificationCodeFromMemo: '从备注中提取识别代码的正则表达式',
    matchToWhichKeyOfTheElvenCounterpartyIdentificationCode: '匹配到Elven对手方识别代码的哪个键',
    caseSensitiveMatch: '区分大小写匹配',
    importMultipleBalance: '导入多个账户的余额',
    sourceName: '数据源名称',
    sourceAccount: '所属账户',
    sourceIntegration: '数据集成方式',
    sourceData: '包含数据类型',
    counterpartyName: '对手方名称',
    transactionCategory: '关联凭证',
    transactionCategorize: '生成记账凭证',
    ruleName: '规则名称',
    refNo: '索引号',
    assetsName: '资产名称',
    assetCategory: '资产类型',
    priceName: '价格源名称',
    priceMethod: '接入方式',
    journalTypeId: '模板编码',
    journalTypeCode: '凭证前缀',
    matchSetTransactions: '已匹配交易数',
    matchSetLedger: '已匹配分录数',
    matchSetName: '规则名称',
    settingMembers: '成员管理',
    settingTransactions: '交易数',
    securityActivity: '操作记录',
    createNewCounterparty: '新增对手方',
    reportPeriod: '报告周期',
    executeIdentifyCounterpartyRules: '运行识别对手方自动化规则',
    deleteChart: '删除图表',
    uploadChartOfAccount: '上传会计科目',
    editAccountMappingName: '标记科目映射名称',
    deleteAccountMapping: '删除科目映射',
    activationRequired: '激活要求',
    freePlanLimitReached: '免费项目已达上限',
    saveAccountMappingName: '保存科目映射名称',
    transactionAndBalance: '交易&余额',
    journalImport: '记账凭证',
    importJournal: '导入记账凭证',
    importedJournals: '已导入记账凭证',
    importMethod: '导入方式',
    originalFilename: '原始文件名',
    importedBy: '导入人',
    imported: '导入时间',
    projectDeactivated: '项目已停用',
    generalTemplate: '通用模板',
    xeroJournalExport: 'Xero默认模板',
    yourPlan: '你的计划',
    addPaymentMethod: '添加付款方式',
    cancelProject: '取消项目',
    confirmCancelation: '确认取消',
    currentPlan: '当前版本',
    billingHistory: '历史账单',
    historicalTransactions: '历史交易数',
    upgradeToStandardPlan: '升级到标准版',
    confirmUnSubscription: '确认取消订阅',
    functions: '功能',
    basicModules: '基础模块',
    advancedModules: '高级模块',
    customModules: '自定义模块',
    supportAndServices: '支持和服务',
    applyFreeAccess: '申请免费使用',
    referral: '来源',
    reconciliationTasks: '对账任务',
    createReconciliationTask: '创建对账任务',
    editReconciliationTask: '编辑对账任务',
    duplicateReconciliationTask: '复制对账任务',
    dataSetName: '数据集名称',
    counterpartyAccount: '对手方账户',
    explainUnmatchedLedger: '解释未匹配分录',
    explainUnmatchedTransfer: '解释未匹配转账',
    changeCheckPoint: '更换日切时间点',
    fileIsExporting: '准备{name}导出数据',
    fileIsExportComplete: '已准备{name}用于导出',
    fileIsExportFailed: '{name}导出失败',
    cryptoSummary: '加密资产-摘要',
    fiatSummary: '法币 (FX)-摘要',
    brokerInventorySummary: 'OTC库存管理-摘要',
    notCategorized: '未分类',
    notCreatedByCategorizing: '未通过分类创建',
    accountingStartDate: '会计开始日期'
  },
  button: {
    clear: '清除',
    delete: '删除',
    save: '保存',
    saveAs: '另存为',
    saveFailed: '保存失败',
    discard: '丢弃',
    contactUs: '联系我们',
    filter: '筛选',
    editTable: '编辑表格',
    groupBy: '以及分组',
    addGroup: '添加分组',
    subGroup: '二级分组',
    export: '导出',
    saveChanges: '保存更改',
    cancel: '取消',
    addCondition: '添加条件',
    ok: '确定',
    yes: '是的',
    no: '否',
    sendCaptchaText: '发送验证码', // 发送之前验证码按钮文案
    sentCaptchaButtonText: '重新发送', // 点击发送之后 验证码按钮文案
    login: '登录', // 登录按钮
    logOut: '退出登录', // 退出登录
    signOut: '退出登录',
    loginButtonTextInDetail: '点击登录', // 详情页登录按钮
    myAccount: '我的账号', // 我的账号
    bindEmail: '绑定邮箱', // 绑定邮箱
    saveAlert: '保存提醒',
    newAlert: '新建提醒',
    editAlert: '编辑提醒',
    clickView: '点击查看',
    connect: '连接',
    continue: '继续',
    upload: '上传',
    uploaded: '上传于',
    uploadNewVersion: '上传新版本',
    type: '类型',
    sources: '来源',
    events: '事件',
    categories: '类别',
    currencies: '币种',
    contacts: '联系人',
    dateTime: '日期和时间',
    allTime: '全部时间',
    reset: '重置',
    deleteContact: '删除联系人',
    deleteContacts: '批量删除联系人',
    deleteAccount: '删除账户',
    deleteAccounts: '批量删除账户',
    edit: '编辑',
    confirm: '确认',
    backList: '返回列表',
    addRule: '新增规则',
    editRule: '编辑规则',
    addWallet: '添加钱包',
    addExchange: '添加交易所',
    upLoadCSV: '上传CSV',
    learnMore: '了解更多',
    create: '创建',
    apply: '应用',
    applyNow: '立即申请',
    talkToSales: '联系销售',
    cancelRequest: '取消申请',
    submit: '提交',
    createProject: '创建项目',
    start: '开始',
    dismiss: '忽略',
    confirmCancel: '确认取消',
    addEntity: '新增主体',
    editEntity: '编辑主体',
    addMember: '新增成员',
    editMember: '编辑成员',
    add: '添加',
    changeEmail: '更改邮件地址',
    changePassword: '更改密码',
    otherSign: '其他登录方式',
    signIn: '登录',
    signUp: '注册',
    getStarted: '开始',
    upgrade: '升级',
    markAllAsReviewed: '全部标记为已审核',
    markSelectedAsReviewed: '标记所选为已审核',
    iKnow: '我知道了',
    helpDocuments: '帮助和文档',
    helpCenter: '帮助中心',
    feedback: '提交反馈',
    switchToV2: '切换到V2',
    syncIntegration: '同步到 {platform}',
    disconnect: '断开连接',
    accountMappings: '账户映射',
    finish: '完成',
    resendInvitation: '重新发送邀请',
    goReview: '前往审核交易',
    reuploadFile: '重新上传文件',
    uploadAnotherFile: '上传另一个文件',
    uploadToExistingAccount: '上传到现有账户',
    createNewAccount: '创建新账户',
    loadMore: '加载更多',
    addSource: '添加数据源',
    addPlatformSource: '添加{platform}数据源',
    editSource: '编辑数据源',
    addAccount: '新增账户',
    editAccount: '编辑账户',
    addCounterparty: '添加对手方',
    uploadXeroCOA: '上传Xero科目表',
    editCounterparty: '编辑对手方',
    addData: '添加数据',
    addBalance: '添加余额',
    addNew: '新增',
    addNewJournal: '录入凭证',
    newMatchSet: '新增对账工作',
    addNewEvent: '添加新事件',
    accountSettings: '账户设置',
    disable: '禁用',
    setupNow: '立即设置',
    next: '下一步',
    viewDetail: '查看详情',
    createGeneralJournal: '创建日记账',
    recalculateCostBasis: '重新计算成本基础',
    recalculate: '重新计算成本',
    postToLedger: '过账到总账',
    contactSales: '联系销售',
    recategorize: '重新分类',
    removeCategory: '移除分类',
    addNewIntegration: '新增集成',
    addCustomButton: '添加{name}',
    goToAddAccount: '前往添加账户',
    addAuxiliaryCode: '添加辅助核算',
    addANewOption: '添加新选项',
    close: '关闭',
    followParentSetting: '遵循父科目设置',
    configuration: '配置',
    regenerate: '重新生成',
    setUpNow: '立即设置',
    addJournal: '添加记账凭证',
    clickToGenerate: '点击生成',
    addNewAuxiliaryType: '新增辅助核算类型',
    createNew: '创建新的',
    addTo: '添加到',
    addToCounterparty: '添加到已有对手方',
    createNewCounterparty: '创建对手方',
    deleteCounterparty: '删除对手方',
    changeToManualAssignment: '切换到手动分配',
    changeToAutomateAssignment: '切换到自动分配',
    selectInventory: '选择库存',
    change: '更改',
    startMatching: '开始匹配',
    exportMatchSet: '导出对账工作',
    import: '导入',
    clickDownloadAndReEdit: '点击下载并重新编辑',
    refreshBalance: '刷新余额',
    addLine: '添加行',
    auxiliary: '辅助',
    CSVUpload: 'CSV上传',
    openAPIIntegration: '开放API集成',
    APIDocument: 'API文档',
    execute: '执行',
    changeCounterparty: '更换对手方',
    exportTreasuryAccount: '导出账户看板',
    importAccount: '批量导入账户',
    importCounterparty: '批量导入',
    addChart: '添加图表',
    editChart: '编辑图表',
    later: '稍后执行',
    Execute: '立即执行',
    autoCalculate: '自动计算',
    addAccountsInTreasury: '前往账户管理添加账户',
    generateReportsInReports: '前往财务报表生成报表',
    exportFromElven: '导出',
    importToElven: '导入',
    contractSales: '联系销售',
    continueWithBasic: '创建基础版项目',
    continueWithStandard: '创建标准版项目',
    subscribe: '订阅',
    continueCreating: '继续创建',
    cancelProject: '取消项目',
    upgradePlan: '升级计划',
    retryPayment: '重新支付',
    addTask: '添加任务',
    createReconciliationAdjustmentJournal: '创建对账调节记账凭证',
    removeReconciliationAdjustmentJournal: '移除对账调节记账凭证',
    clearFormula: '清除公式',
    download: '下载'
  },
  message: {
    sortSuccess: '排序成功',
    sendEmail: '发送通知到电子邮件',
    enterAlertName: '请输入您的提醒名称',
    confirmEmailLogin: '请确认您的电子邮件登录',
    loginTitle: '欢迎进入', // 登录弹框title
    loginEmailPlaceholder: '请输入正确的邮箱地址', // 登录弹框邮箱提示 和 校验提醒
    loginEmailError: '邮箱地址有误，请重新输入', // 校验提醒
    captchaPlaceholder: '请输入验证码', // 登录弹框验证码提示和校验提醒
    pleaseInputPassword: '请输入邮箱密码！',
    passwordError: '至少12个字符，包含数字、大写字母、小写字母和符号',
    captchaWarning: '请输入验证码',
    captchaError: '验证码错误，请重新输入',
    emailError: '邮箱或密码错误，请重新输入',
    pleaseInput: '请输入',
    pleaseInputEmail: '请输入邮箱！',
    pleaseInputName: '请输入您的名字! ',
    PleaseInputCode: '请输入邮箱验证码！',
    saveSuccess: '保存成功',
    copyFailed: '复制失败',
    copySuccessfully: '复制成功',
    signInSuccess: '登录成功！',
    signUpSuccess: '注册成功！',
    exportSuccess: '导出成功',
    onlyScreeners: '你只能创建10个筛选器！',
    saveChangeGroup: '您已更改了分组视图，是否保存此更改？',
    pleaseEnterFilter: '请为筛选输入一个数值。',
    pleaseSelectFilter: '请为筛选选择一个指标。',
    rangeRequirements: '范围不符合要求。',
    alertEmpty: '提醒历史记录为空。',
    createdAlert: '您还没有创建任何提醒。',
    areYouSure: '确定吗?',
    saveChangeAlert: '您已更改提醒条件，是否保存更改？',
    reportOnlySee100: '只显示最近的100个项目，如果您需要查看所有数据，请',
    reportOnlySee20: '每个账户仅显示最新的20个项目，如果您需要查看所有数据，请',
    alertUpdatedSuccuss: '提醒更新成功!',
    alertCreatedSuccuss: '提醒创建成功!',
    fillAllBlanks: '请把所有的空都填上!',
    renameFailed: '文件重命名失败',
    pleaseEnterSourceName: '请输入此来源的名称。',
    pleaseEnterIntegrationName: '请输入此集成的名称',
    pleaseEnterAccountName: '请输入账户名称。',
    pleaseEnterAccountNo: '请输入账户号码',
    pleaseEnterNameOfAccount: '请输入此账户的名称',
    pleaseEnterTheNameOfThisMatchSet: '请输入此对账工作的名称',
    enterSourceName: '输入的来源名称不一致。',
    enterTheNameOfThisMatchSet: '输入的对账工作名称不一致。',
    enterAccountName: '输入的账户名称不一致。',
    noPermission: '您当前没有此权限。',
    pleaseEnterContactName: '请输入联系人的名称。',
    pleaseSelectContactType: '请选择当前未设置的联系人类型。',
    exportLimitMessage: '导出限制为每次1000条，可使用筛选器进行筛选后再导出。',
    editSuccess: '编辑成功',
    deleteSuccess: 'Delete Success',
    regenerateFailed: '重新生成失败，请检查您的交易数据',
    reportError: '报告已重新生成，但存在一些错误，请检查您的数据',
    transactionCategorized: '交易已分类',
    transactionIgnored: '交易已忽略',
    transactionKeepAll: '交易已保留',
    ruleEnabled: '规则已启用',
    ruleDisabled: '规则已禁用',
    priorityChanged: '优先级已更改',
    ruleSavedSuccessfully: '规则保存成功',
    pleaseWaitRules: '请等待规则执行结束',
    executeRuleMessage: '您是否要手动执行所有规则以处理所有交易数据？',
    executeRuleBusinessDataMessage: '您是否要手动执行所有规则以处理所有业务数据？',
    executeRuleCostBasisMessage: '您是否要手动执行所有规则以处理所有成本基础？',
    deleteRuleMessage: '删除规则“{name}”？',
    deleteChartMessage: '您确定要删除“{name}”吗？',
    addElevenConditions: '添加最多十一个条件',
    addCountConditions: '添加最多{count}个条件',
    deleteMemberMessage: '删除成员“{email}”？',
    deleteEntityMessage: '删除主体“{name}”？',
    cancelSuccess: '取消成功',
    submitSuccess: '提交成功',
    uploadSuccess: '上传成功',
    uploadError: '上传失败，请检查您的文件',
    createProjectSuccess: '创建项目成功',
    addEntitySuccess: '添加主体成功',
    agreedTermsOfService: '第一次登录将自动创建一个账户，这意味着您已阅读并同意',
    LoginAgreedTermsOfService: '您的登录和注册行为表示您已阅读并同意',
    pleaseEnterVerificationCode: '请输入已发送到{email}的验证码',
    resendAfter: '{tmp}秒后重新发送',
    loginInfoTitle: '专业的加密财务软件',
    loginInfoDesc: '一站式加密会计和报告解决方案',
    upgradePro: '升级到专业版',
    needMoreEntities: '需要更多主体？',
    needMoreMembers: '需要更多成员？',
    reviewSuccess: '审核成功',
    someTransactionsReviewedInfo: '有一些交易不能被审阅',
    maximumTransactionsReviewedInfo: '单次批量审阅的交易上限是 {total} 条',
    reviewedSuccessfullyTotal: '{total} 条交易被成功审阅',
    restReviewTotal: '{total} 条交易需要手动指定业务活动标签',
    subscriptionExpired: '您的订阅已过期。请重新购买。',
    subscriptionLimitError: '您的使用量已超出限制。请升级您的计划。',
    syncIntegrationSuccess: '账户已同步到{platform}',
    disconnectWithPlatformInfo: '我们将清除与{platform}的对应关系。当您想要再次同步时，您需要重新关联。',
    authorizationFailed: '授权失败',
    syncIntegrationInfo: '正在生成会计分录，请等待生成完成后再次同步。',
    connectedSuccessfully: '成功连接到{platform}',
    successfullyDisconnected: '成功断开与{platform}的连接',
    accountMappingMessage: '同步完成后，账户映射将显示在此处。',
    success: '成功',
    resendInvitationEmailSuccess: '重新发送邀请邮件成功',
    sourceImportedSuccess: '您已成功导入{total}笔交易，其中{total}笔需要审核。',
    someLinesError: '由于格式错误、缺少内容等原因，此文件中的某些行未被识别。',
    counterpartySomeLinesError: '部分数据不正确，无法上传。',
    importCSVErrorInfo: '找不到与{account}匹配的任何交易。没有交易可以导入。',
    transactionsWillBeImported: '将导入交易',
    transactionsWillBeImportedAccount: '将导入交易到{account}',
    uploadCSVWarningInfo: '只有与所选账户匹配的交易才会被导入，其他不相关的交易将被丢弃。',
    deleteAttachmentInfo: '您确定要删除“{fileName}”这个附件吗？',
    sourceSyncTimeoutInfo: '您的地址可能需要更长的同步时间。同步完成后，我们会通知您。',
    balanceVerificationPassed: '余额校验通过',
    balanceVerificationFailed: '余额校验失败',
    balanceNotVerified: '余额未校验',
    BalanceVerificationCompleted: '余额校验完成',
    BalanceVerificationStarted: '余额校验开始',
    refreshBalanceStarted: '刷新余额开始',
    accountBalanceEmptyInfo: '此账户下没有资产',
    deleteBalanceInfo: '将删除此余额记录，此操作无法恢复。',
    deleteBusinessEventInfo: '您确定要删除业务活动“{event}”吗？',
    deleteBusinessEventJournalInfo: '您确定要删除记账凭证“{journal}”吗？',
    deletedSourceInfo: '将删除此来源下的所有交易，此操作无法恢复。',
    deletedJournalSourceInfo: '将删除此来源下的所有记账凭证，此操作无法恢复。',
    deletedIntegrationBusinessDataInfo:
      '将删除此集成下的所有业务数据，此操作无法恢复。所有基于此数据生成的估值原始数据也将被删除。所有基于此数据生成的估值原始数据也将被删除。',
    deletedBusinessDataSourceInfo:
      '将删除此数据源的所有业务数据，此操作无法恢复。所有基于此数据生成的估值原始数据也将被删除。',
    deletedBusinessDataSourceWithJournalInfo: '删除此数据源创建的所有记账凭证',
    createJournalSuccess: '创建记账凭证成功',
    emptyChartOfCountInfo: '选择币种和账户定义记账凭证',
    manuallyEnterFunctionalCurrencyAmountTip: '手动输入功能币种金额',
    recalculateCostBasisInfo: '记账凭证已更新，建议重新计算成本基础',
    recalculateCostBasisSuccess: '成本基础已成功重新计算',
    recalculateCostBasisStart: '成本基础开始重新计算',
    postToLedgerStart: '记账凭证开始过账到总账',
    postToLedgerInfo: '该日记账数据已更新，建议过账到总账。',
    regenerateReportsTips: '记账凭证已更新，建议重新生成报告',
    sourceSynchronizationIsCompleted: '来源同步已完成',
    TransactionInProgress: '来源正在同步中，请在同步结束后再试!',
    sourceInExecuting: '请等待数据源处理完成后再执行。',
    treasuryAccountTips: '非特定账户，由货币和辅助核算确定',
    createJournalFailed: '创建记账凭证失败',
    transactionDeletedInfo: '相关交易已被删除',
    addEthereumWalletInfo: '您可能需要手动添加起始时间的余额。',
    addAccountCSVSourceInfo: '创建账户后，上传CSV文件',
    deleteJournalInfo: '将删除记账凭证{journalNo}，此操作无法恢复。',
    batchDeleteJournalInfo: '您确定要删除{total}条记账凭证吗？',
    deleteJournalSuccess: '记账凭证删除成功',
    noIntegrationsAdded: '没有添加任何集成',
    integrationDataNameInfo: '数据名称“{dataName}”已被使用。',
    noAccountFound: '没有找到账户',
    isItACashAccount: '这是一个现金科目吗？',
    enableOriginalCurrencyAccounting: '启用原始币种记账',
    journalTypesAssociatedWithThisAccount: '与此账户关联的凭证模板',
    journalsAssociatedWithThisAccount: '与此账户关联的记账凭证',
    automationsAssociatedWithThisAccount: '与此账户关联的自动化',
    treasuryAccountsAssociatedWithThisAccount: '与此账户关联的财务账户',
    deletedChartOfCountInfo: '将删除科目{account}，此操作无法恢复。',
    pleaseEnterChartOfAccountName: '请输入科目名称',
    pleaseEnterJournalTypeName: '请输入此凭证模板的名称',
    unableDeleteChartOfAccountInfo: '无法删除“{account}”。存在以下关系，阻止删除：',
    unableToDeleteJournalTypeOfAutomation: '无法删除凭证模板。请先删除与任何现有记账凭证或自动化规则的关联。',
    unableToDeleteJournalTypeOfJournal:
      '凭证模板“{journalType}”正在被其他数据使用，您无法禁用此凭证模板，直到这些数据被删除。',
    enterChartOfAccountName: '输入的科目名称不一致',
    deletedJournalTypeInfo: '将删除凭证模板{journalType}，此操作无法恢复。',
    editJournalTypeOfJournalInfo: '此凭证模板正在被其他数据使用，您无法编辑分录行。',
    editJournalTypeOfAutomationInfo: '此凭证模板用于自动处理；分录行无法编辑。',
    businessEventsEmpty: '您还没有定义任何业务卡片，',
    businessEventsEmptyRegenerate: '请创建一个业务卡片，',
    regeneratingBusinessEvents: '重新生成业务活动',
    deleteAuxiliaryCodeTypeInfo: '您确定要删除{type}“{name}”吗？',
    deleteCounterpartyInfo: '您确定要删除对手方{name}吗？',
    batchDeleteCounterpartyInfo: '你确定要删除{total}个对手方吗？',
    counterpartyFeeInfo: '系统创建的对手方，用于标记需要支付的{platformType}交易费用。',
    changeToManualAssignmentInfo: '切换到手动分配后，此销售对应的库存将不再受规则影响，您可以手动指定库存。',
    changeToAutomateAssignmentInfo: '切换到自动分配后，匹配的库存将被删除，需要手动执行规则重新匹配。',
    dataNameEmptyInfo: '请输入数据名称',
    exportDataInfo: '我们将在后台准备您的文件，并稍后将下载链接发送到您的电子邮件。',
    pleaseRecalculateTheCost: '数据已更改，请重新计算成本。',
    deleteIdentificationCodeInfo: '您确定要删除识别代码“{name}”吗？',
    deleteContactInfo: '您确定要删除{contact}吗？',
    cannotDeleteSource: '此类型source不允许删除',
    startRefresh: '开始刷新余额',
    copiedToClipboard: '已复制到剪贴板',
    brokerSaleInfo:
      '此记账凭证不符合生成销售记录的规则。借方应只有一个费用账户，贷方必须包括原始币种中的资产账户或负债账户。',
    brokerInventoryErrorInfo:
      '分录行不符合经纪商库存规则，因为借方或贷方科目中至少有一个在原始币种中被分类为资产或负债科目。',
    deleteMatchRuleInfo: '此规则生成的所有匹配记录将被永久删除，无法撤销。',
    deleteAccountUnavailableInfo: '该帐户包括在交易表中标记为匹配的交易。',
    deleteAccountRecoverTipInfo: '将删除此帐户，此操作无法恢复.',
    deleteAccountSourceTipInfo: '此帐户的所有数据源、交易和余额记录将被删除。',
    deleteAccountJournalTipInfo: '与此账户关联的记账凭证将被保留。您可以通过账户筛选器删除它们。',
    addViaCOntractAddress: '通过合约地址添加',
    addAssetSuccessfully: '资产添加成功',
    editAssetSuccessfully: '资产编辑成功',
    noContractAddress: '该资产下无合约地址。',
    noRawDataEndingInfo: '在本调整期间没有可视为期末记录的未实现盈亏记录。',
    noRawDataRealizedInfo: '在本调整期间没有已实现盈亏记录。',
    adjustmentFrequencyInfo: '修改调整频率会触发系统重新计算未实现盈亏的变化。',
    assetSelectionInfo: '更改资产选项后，请单击重新计算按钮以应用更改。',
    deleteBusinessDataTransformerInfo: '删除此规则将自动删除所有基于此规则生成的原始数据。',
    conditionsHaveChangedInfo: '当此规则执行时，如果业务数据不符合新条件，系统将自动删除基于此规则生成的原始数据。',
    importBalanceMultipleTipInfo: '余额将根据账户自动拆分为多个来源，并保存到相应的账户中。',
    importBalanceMultipleNameInfo: '来源名称将自动创建为 “{format}”.',
    importBalanceMultipleNameFormat: '账户 名称-余额-时间',
    executeIdentifyCounterpartyRulesInfo: '对手方信息已保存。是否立即运行自动化规则，以更新交易对手方信息？',
    switchToAutoCalculateAmountFC: '切换到自动计算功能币种金额',
    sorted: '已排序',
    createProjectSuccessTipInfo: '您已经成功创建了一个新项目。要激活您的项目，请检查您的电子邮件并点击激活链接。',
    receiveEmailTipInfo: '没有收到邮件?',
    resendEmailTipInfo: '确保检查你的垃圾邮件或垃圾文件夹。您也可以重新发送激活邮件',
    resendEmailLinkInfo: '重新发送邮件',
    freePlanReachedTipInfo: '您已达到免费项目的最大限制\n要创建优质项目，请联系我们的销售团队。',
    agreeServicesAgreement: '我同意Elven的{agreement} .',
    createProjectTermsOfService:
      '使用我们的服务即表示您同意您的数据会安全地存储在我们的网络服务提供商（AWS）上，并根据我们的{policy}使用，包括营销目的。',
    createFreeProjectTipInfo: '填写表格以创建您的项目。一旦获得批准，它将可以免费使用。',
    enterWebsiteUrlWarningInfo: '请输入以http://或https://开头的网址',
    freeProjectReviewFailedTipInfo: '{reminder}: 请在{time}之前更新您的项目信息，以避免暂停。{updateNow}',
    freeProjectReviewSuccessTipInfo: '您的项目信息已经更新，正在审核中。您将通过电子邮件收到结果。',
    emailSendSuccessTipInfo: '邮件发送成功',
    monthlyPayment: '按月支付',
    customizedPlanMsg: '最大化地提升各种规模的企业的财务需求',
    chooseYourSubscriptionPlan: '选择您的订阅计划',
    welcomeOfferForBeginners: '小微企业最佳选择',
    addPaymentMethodTip: '请添加您的付款方式，以完成订阅并使用完整的服务',
    wantToCancelProject: '您确认要取消该项目吗？',
    exceededUsageLimitTip: '您的项目已超过数据使用限制',
    exceededTransactionLimitTip: '当您的交易数达到上限后，每增加 10,000 条交易需要额外付费 $50 每月',
    billingHistoryMonths: '显示最近 12 个月的历史账单',
    projectUnSubscribedBanner: '您的订阅已取消，将在{time}后停用.   {handler}',
    projectPaymentFailedBanner: '自动扣款失败，请于{time}前完成支付，以避免您的项目被停用。{handler}',
    projectUpgradePlanBanner: '您的项目己超出数据使用限制。某些功能将受到限制。{handler}',
    historicalTransactionsTip:
      '历史交易计数包括两部分:\n1.通过账户（链上钱包、交易所、银行、托管、支付平台）导入并解析后的历史交易笔数，经过 Elven 的标准化处理后，可能与原始交易笔数有一定出入 \n2.通过业务系统集成导入的业务数据',
    upgradeStandardPlan: '您确认要升级到标准版吗？升级后，您的下一笔账单将按照标准版收取。',
    unSubscriptionTip: '您确认要取消订阅吗？您的项目将持续保留到{time}。\n在此之后，您的项目将会被停止。',
    reSubscriptionTip: '你确认要恢复订阅吗？您当前的订阅将会被延续，后续会继续每月生成账单并扣费。',
    reSubscriptionPaymentTip: '你确认要恢复订阅吗？您需要立即支付本期账单，后续会继续每月生成账单并扣费。',
    limitReachedTip: '您的交易数已到达该版本的上限，当前您不能上传新的数据，如果继续使用，请升级到更高版本',
    limitReachedAccountTip: '您的交易数已到达该版本的上限，当前您不能添加新的交易，如果继续使用，请升级到更高版本',
    limitReachedCategorizeTip:
      '您的交易数已到达该版本的上限，当前您不能继续执行打标签操作，如果继续使用，请升级到更高版本',
    subscriptionSuccessTip: '项目订阅成功',
    xeroTemplateCSVInfo: '请使用默认Xero模板导出并保存为CSV。',
    applyFreeProjectTipInfo: '请完整填写以下表单,我们收到您提交的申请后,会尽快审核并为您开通免费使用权限。',
    submissionSuccessful: '提交成功',
    submissionSuccessfulInfo: '感谢您的申请，我们会尽快审核，并会以邮件方式通知您审核结果。',
    activationSuccessfully: '激活成功',
    editTaskSourceDataTipInfo: '如果更改条件导致某些数据减少，则与该被删除数据相关的匹配记录和解释记录将被删除。',
    noRawDataValidatorEndingInfo: '本期间没有可以被视为结束记录的余额记录',
    noRawDataValidatorWithdrawInfo: '在此期间没有退款记录',
    noRawDataValidatorDepositInfo: '在此期间没有存款记录',
    viewDetailedRecords: '查看明细记录',
    changeCheckPointTipTitleMessage: '您正在切换日切时间点，此操作将导致以下变化',
    changeCheckPointTipMessageOne: '估值模块下的各种调账数据将根据新的日切时间点重新计算。',
    changeCheckPointTipMessageTwo: '重新计算完成后，将过账到总账，并且重新生成报表。',
    changeCheckPointTipMessageThree: '',
    changeCheckPointHelpMessage:
      '您可在报表模块切换日切时间点，估值模块下的各种调\n账数据和财务报表，都将根据日切时间点生成',
    changeCheckPointSelectMessage: '重新计算成本/估值动化规则/更新账本/更新报表 任务执行\n中，暂不可进行调整。'
  },
  input: {
    nameLabel: '你的名字',
    passwordsLabel: '密码(12-20个字符)',
    setPasswordsLabel: '设置密码12-20个字符)',
    enterAuthenticatorCode: '输入您的身份验证应用程序生成的6位验证码。'
  },
  label: {
    addressOrXpubRrecommend: '地址 / Xpub (推荐)',
    addresses: '地址',
    inputs: '输入'
  },
  placeholder: {
    enterEmail: ' 请输入您的电子邮件地址',
    enterVerificationCode: '输入您的验证码',
    enterNumber: ' 输入你的验证码',
    enterPassword: ' 输入你的密码',
    enterName: ' 输入你的名字',
    passwordCharacters: '8-16个字符',
    min: '最小',
    max: '最大',
    descriptionAccount: '此账户的描述',
    inputTag: '输入标签...',
    inputCurrencyCode: '输入货币代码',
    txHashOrOrderId: '交易哈希或订单ID...',
    selectAccount: '选择账户...',
    pleaseDataName: '请输入数据名称',
    dataNameEmptyInfo: '请输入数据名称',
    enterNameForSearch: '输入搜索名称...',
    search: '搜索...',
    inputAssetName: '请输入资产名称',
    inputSymbolCode: '请输入符号或编码',
    inputContractAddress: '请输入合约地址',
    inputChartName: '请输入图表名称',
    legalNameOfCompany: '您公司的法律主体全称',
    beginWithWebsites: '  网站以http或https开头',
    firstName: '名字',
    lastName: '姓氏',
    jobTitle: '职位',
    workEmailTip: '请输入一个工作邮箱',
    useWorkEmailApply: '请使用您的工作邮箱申请',
    referralTip: '您是如何了解Elven的？'
  },
  automation: {
    forNewTransfers: '对于新的转账:',
    forNewTransfersInfo: '每次转账只会执行优先级最高的匹配规则。',
    forExistingTransfers: '对于现有的转账:',
    forEewTradeRecords: '对于新的交易记录:',
    forExistingTradeRecords: '对于现有的交易记录:',
    forNewGainLossRecords: '对于新的损益记录:',
    forExistingGainLossRecords: '对于现有的损益记录:',
    forNewBusinessData: '对于新的业务数据:',
    forNewBusinessDataInfo: '此规则将用于匹配的记录。',
    forExistingBusinessData: '对于现有的业务数据:',
    forAllTransfers: '对于所有转账:',
    forNewRecords: '对于新的记录:',
    forExistingRecords: '对于现有的记录:',
    forEewRecordsInfo: '每个记录将执行所有匹配的规则。',
    existingRecordsInfoForAdjustments: '如果原始匹配规则没有调整，则之前创建的凭证保持不变。',
    existingRecordsInfoForAction: '如果原始匹配规则更改了操作，则之前创建的凭证将被更新。',
    existingRecordsInfoNotLongerMatched: '如果原始匹配规则不再匹配，则之前创建的凭证保持不变。',
    existingRecordsInfoIsMatched: '如果匹配了新规则，将创建新的凭证，之前创建的凭证保持不变。',
    existingRecordsInfoAreMatched: '如果匹配了新规则，将创建新的凭证。',
    ruleHasNoAdjustmentsInfo: '如果此规则没有调整，则之前创建的凭证保持不变。',
    ruleHasNoAdjustmentsValuationInfo: '如果此规则没有调整，则之前创建的估值数据保持不变。',
    ruleChangesTheActionInfo: '如果此规则更改了操作，则之前创建的凭证将被更新。',
    ruleChangesTheActionValuationInfo: '如果此规则更改了操作，则之前创建的估值数据将被更新。',
    ruleChangesTheConditionInfo: '如果此规则更改了条件:',
    previouslyMatchedRecords: '如果原始匹配规则不再匹配，则之前创建的凭证保持不变。',
    previouslyMatchedValuationRecords: '如果原始匹配规则不再匹配，则之前创建的估值数据保持不变。',
    newlyMatchedRecordsInfo: '如果新规则匹配，将创建新的凭证。',
    newlyMatchedRecordsValuationInfo: '如果新规则匹配，将创建新的估值数据。',
    existingRecordsInfoForValuationAdjustments: '如果原始匹配规则没有调整，则之前创建的凭证保持不变。',
    existingRecordsInfoForValuationAction: '如果原始匹配规则更改了操作，则之前创建的估值数据将被更新。',
    existingRecordsInfoNotLongerMatchedValuation: '如果原始匹配规则不再匹配，则之前创建的估值数据保持不变。',
    existingRecordsInfoAreMatchedValuation: '如果匹配了新规则，将创建新的估值数据。',
    manuallySetCounterpartiesInfo: '手动设置的转账对手方不会被规则修改。',
    priorityMatchedRuleForcounterpartyInfo: '每次转账只会执行优先级最高的匹配规则。'
  },
  country: {
    HongKong: '香港',
    Japan: '日本',
    Philippines: '菲律宾',
    Singapore: '新加坡',
    SouthKorea: '韩国',
    UnitedKingdom: '英国',
    UnitedStates: '美国'
  },
  bank: {
    星展银行: '星展银行',
    汇丰银行: '汇丰银行',
    中银香港: '中银香港',
    花旗银行: '花旗银行',
    东亚银行: '东亚银行',
    华侨银行: '华侨银行',
    工银亚洲: '工银亚洲',
    德意志银行: '德意志银行',
    渣打银行: '渣打银行',
    恒生银行: '恒生银行',
    富国银行: '富国银行',
    美国银行: '美国银行',
    大华银行: '大华银行',
    瑞士银行: '瑞士银行',
    巴克莱银行: '巴克莱银行',
    摩根大通: '摩根大通',
    招商银行: '招商银行',
    交通银行: '交通银行',
    中国银行: '中国银行',
    农业银行: '农业银行',
    建设银行: '建设银行',
    邮政储蓄银行: '邮政储蓄银行',
    工商银行: '工商银行',
    MCB: 'MCB',
    Signet: 'Signet',
    'BCB Blinc': 'BCB Blinc',
    BCB: 'BCB',
    Signature: 'Signature',
    'Customer Bank': 'Customer Bank',
    其他银行: '其他银行'
  },
  project: {
    nav: {
      financials: '财务',
      fundReports: '基金报告',
      chartOfCount: '会计科目表',
      metrics: '指标',
      charts: '图表',
      balanceSheet: '资产负债表',
      incomeStatement: '利润表',
      cashFlowStatement: '现金流量表',
      generalLedger: '总账表',
      trialBalance: '试算平衡表',
      transactions: '交易管理',
      wallets: '钱包地址表',
      portfolioStatement: '投资组合表',
      totalReturn: '总收益率表',
      changeInNetAssets: '净资产变动表',
      tokenFlowStatement: '代币流量表',
      sources: '数据源',
      treasury: '账户',
      accounts: '账户管理',
      contacts: '联系',
      rules: '规则',
      disclosure: '披露表',
      SignificantHoldings: '重要持仓',
      RestrictionsCryptoAssets: '加密资产限制',
      Restrictions: '加密资产限制',
      rollForward: '加密资产变动表',
      RollForward: '加密资产变动表',
      forReview: '待审核',
      duplicates: '重复',
      reviewed: '已审核',
      categorized: '已分类',
      changeInBalance: '余额变动表',
      defiPosition: 'Defi 仓位',
      totalPosition: '总持仓'
    },
    transaction: {
      allTransactions: '所有交易',
      onChainTransactions: '链上交易',
      bankTransactions: '银行交易'
    },
    costBasis: {
      inventoryLots: '库存批次',
      disposals: '处置',
      method: '成本计价方法',
      costMethod: '成本计价方法',
      token: '代币',
      remain: '剩余',
      lotNo: '批次号',
      journalNo: '日记账号',
      costbasis: '成本基础',
      remainQuantity: '剩余数量',
      soldPrice: '售价',
      gainOrLoss: '损益记录',
      dateNo: '日期和编号',
      lots: '批次',
      costRule: '成本基础规则',
      acquisitionPrice: '购入价',
      lotDetail: '批次详情',
      lotRemainingQuantity: '批次剩余数量',
      long: '看涨',
      short: '看跌'
    },
    dashboard: {
      totalBalance: '总余额',
      fiatBalance: '法币余额',
      cryptoBalance: '加密货币余额',
      totalAssets: '总资产',
      totalLiabilities: '总负债',
      totalEquity: '总权益',
      revenue: '收入',
      expense: '费用',
      profit: '净利润',
      last30Days: '最近30天',
      thisMonth: '本月',
      thisQuarter: '本季度',
      thisYear: '本年',
      metric: '指标',
      trends: '趋势图',
      distribution: '分布图',
      viewByDay: '按天查看',
      viewByMonth: '按月查看',
      viewByQuarter: '按季度查看',
      ledgerDistribution: '总账分布',
      positiveValues: '仅看正数',
      negativeValues: '仅看负数'
    },
    categorize: '分类',
    utcDatetime: 'utc时间',
    reportingDatetime: '报告时间',
    originalDatetime: '原始时间',
    standardRatedSupplies: '标准税率供应',
    zeroRatedSupplies: '零税率供应',
    exemptSupplies: '免税供应',
    taxablePurchases: '应税购买',
    none: '无',
    purchase: '加密货币买入记录',
    sale: '加密货币销售记录',
    fifo: '先进先出 (FIFO)',
    costAverage: '加权平均',
    specificId: '特定标识',
    manualAssignedBy: '手动分配给{name}',
    basicEdition: '基础版',
    standardEdition: '标准版',
    customizedEdition: '定制版',
    transactions: '交易数',
    view: '查看',
    limitReached: '已到达用量上限',
    upgradeToStandard: '升级到标准版',
    canceledOn: '于{time}取消订阅',
    dataType: '数据类型',
    editData: '编辑数据',
    addData: '添加数据',
    dataName: '数据名称',
    accountIs: '该账户为',
    typeIs: '该类型为',
    changeInBalance: '余额变动'
  },
  desc: {
    configureYourTwoFactorClientInfo: '请使用身份验证应用程序扫描上面的二维码（例如Google Authenticator或1Password）',
    configureYourTwoFactorClientManuallyInfo: '或者，手动输入以下OTP密钥：',
    verifyTheConfigurationInfo: '输入身份验证应用程序生成的6位验证码。',
    createNewBackupCodesInfo: '请注意，继续操作后，所有现有的备份代码将失效，无法再用于访问您的账户。此操作无法撤销。',
    securelyStoreBackupCodesInfo: '如果您无法访问身份验证应用，我们将要求您提供备份代码。',
    projectLockedInfo: '为了继续享受所有功能和优势，请考虑购买订阅。',
    projectDeactivatedInfo: '如果您想继续使用这个项目，请联系我们的销售团队。',
    changeBalanceDifference: '计算余额变化并比较差异',
    entityTimeZoneTipInfo:
      '从第三方获取的原始数据的时间戳将转换为所选时区进行显示。会计分录和报表生成将基于您选择的时区进行处理。',
    entityGSTSettingTipInfo: '如果您的业务需要遵守新加坡的 GST 规定，请启用此功能。',
    entityAccountingStartDateTipInfo:
      '这是您希望开始录入交易的日期。设置后，您将无法添加该日期之前的交易或凭证。如果您在此日期之前有交易，您需要手动输入期初余额。{handler}',
    entityFunctionalCurrencyTipInfo: '进行会计核算和报表编制时使用的主要货币，通常与企业的主要经营活动货币一致。',
    preferredCurrencyUnitTipInfo: '设置货币默认的显示格式。可根据主体的财务金额规模选择不同的默认单位。',
    learnChooseAccountingDate: '如何选择会计开始日期'
  },
  bubbleGuide: {
    forReview: {
      step1:
        '待审核：您需要在此处先审核您的交易。<br/>重复项：您需要在此检查是否有重复的交易。<br/>已分类：已审核的交易。',
      step2: '通过点击勾选按钮，完成对单笔交易的审核。',
      step3: '批量审核按钮，您可以一起审核筛选出来的交易。'
    },
    duplicates: {
      step1: '如果您确认某笔交易是重复的，您可以点击此按钮将该交易设置为忽略。',
      step2: '如果您确认这些交易都不是重复的，您可以点击此按钮将它们从重复列表中删除。'
    },
    balanceSheet: {
      step1: '点击此按钮重新生成财务报告。'
    },
    journalToLedger: {
      step1: '日记账列表已移至总账模块'
    }
  },
  log: {
    addSource: '添加了数据源“{sourceName}”',
    editSource: '修改了数据源“{sourceName}”',
    deleteSource: '删除了数据源“{sourceName}”',
    addBusinessData: '添加了业务数据: “{name}”',
    addBusinessDataSource: '添加了业务数据源“{name}”',
    deleteBusinessDataSource: '删除了业务数据源“{name}”',
    addJournal: '添加了日记账: {journalTypeName}({journalNo})',
    editJournal: '修改了日记账: {journalTypeName}({journalNo})',
    deleteJournal: '删除了日记账: {journalTypeName}({journalNo})',
    recalculateCostBasis: '重新计算成本',
    uploadCsv: "上传了 CSV 文件'{fileName}'",
    categorizeTransaction: '把交易“{txId}”标记为{category}',
    batchCategorizeTransaction: '把{total}笔交易“{txIds}”标记为{category}',
    exportTransaction: '导出了{total}条交易',
    regenerateReports: '重新生成了报表',
    addContact: '添加了对手方“{contactName}”',
    batchAddContact: '批量添加了{total}个对手方“{contactNames}”',
    editContact: '修改了对手方“{contactName}”',
    deleteContact: '删除了对手方“{contactName}”',
    batchDeleteContact: '批量删除了{total}个对手方“{contactNames}”',
    addRule: '创建了规则“{ruleName}”',
    editRule: '修改了规则“{ruleName}”',
    deleteRule: '删除了规则“{ruleName}”',
    manuallyExecuteRules: '手动执行了自动化规则',
    manuallyExecuteBusinessDataRule: '手动执行业务数据规则 “{ruleName}”',
    addAccount: '添加了账户“{accountName}”',
    editAccount: '修改了账户“{accountName}”',
    deleteAccount: '删除了账户“{accountName}”',
    batchDeleteAccount: '批量删除了{total}个账户“{accountNames}”',
    addTreasuryBalanceCheckpoint: '为账户“{accountName}”添加了余额：{balance} {currency}，{datetime}',
    deleteTreasuryBalanceCheckpoint: '删除了账户“{accountName}”在{datetime}的余额记录',
    verifyTreasuryBalance: '执行了余额校验',
    exportTreasuryBalance: '导出了科目余额表',
    addChartOfAccount: '添加了科目 “{name}”',
    editChartOfAccount: '编辑了科目 “{name}”',
    deleteChartOfAccount: '删除了科目 “{name}”',
    addJournalType: '添加了日记账类型 “{name}”',
    editJournalType: '编辑了日记账类型 “{name}”',
    deleteJournalType: '删除了日记账类型 “{name}”',
    addEntity: '创建了 Entity “{name}”',
    editEntity: '编辑了 Entity “{name}”',
    deleteEntity: '删除了 Entity “{name}”',
    inviteMember: '邀请 “{name}” 加入项目',
    editMemberPermission: '编辑了成员 “{name}” 的权限',
    removeMember: '移除了成员 “{name}”',
    addChartOfCount: '添加了科目 “{name}”',
    editChartOfCount: '编辑了科目 “{name}”',
    deleteChartOfCount: '删除了科目 “{name}”',
    addNewBusinessEvent: '添加了业务活动 “{name}”',
    editBusinessEvent: '编辑了业务活动 “{name}”',
    deleteBusinessEvent: '删除了业务活动 “{name}”',
    regeneratedBusinessEventsList: '重新生成了业务活动列表',
    addAuxiliaryType: '添加了辅助核算字段 “{name}”',
    editAuxiliaryType: '编辑了辅助核算字段 “{name}”',
    deleteAuxiliaryType: '删除了辅助核算字段 “{name}”',
    addReconciliationMatchSet: '创建了对账工作 “{name}”',
    deleteReconciliationMatchSet: '删除了对账工作 “{name}”',
    editReconciliationMatchSetDetail: '修改了对账工作 “{name}”的详情',
    editReconciliationMatchSetFilter: '修改了对账工作 “{name}”的筛选器',
    addReconciliationMatchSetRule: '在对账工作“{setNo}”中添加了规则“{ruleName}”',
    editReconciliationMatchSetRule: '在对账工作“{setNo}”中修改了规则“{ruleName}”',
    executeReconciliationMatchSet: '在对账工作“{name}”中执行了自动对账',
    deleteReconciliationMatchRecord: '删除了一条匹配记录“{name}”',
    addReconciliationMatchRecord: '手动添加了一条匹配记录“{name}”'
  },
  menus: {
    'My Projects': '我的项目',
    sources: '数据源',
    Reports: '报告',
    reports: '财务报表',
    transactions: '交易管理',
    ledger: '总账',
    contacts: '联系人管理',
    automation: '自动化规则',
    settings: '项目设置',
    Fundamentals: '基础面',
    'Money Flows': '资金流',
    Social: '社交媒体',
    alert: '我的提醒',
    rules: '规则设置',
    history: '历史记录',
    market: '市场',
    marketMap: '市场地图',
    emergingSpaces: '新兴领域',
    heatmap: '热度图',
    screeners: '我的筛选器',
    valuation: '估值',
    dashboard: ' 仪表盘'
  },
  progressStatus: {
    syncingSources: '正在同步数据',
    deletingSources: '正在删除数据源',
    executingRules: '正在应用自动化规则',
    recalculatingCostBasis: '正在执行调账运算',
    regeneratingReports: '正在更新报表',
    regeneratingBusinessEvents: '正在更新业务卡片',
    performingAutomaticReconciliation: '正在应用对账规则',
    postingToLedger: '正在更新账本',
    refreshBalance: '正在更新余额',
    deletingAccount: '正在删除账户'
  },
  progressStatusToast: {
    syncingSourcesSuccess: '数据源同步成功',
    syncingSourcesError: '数据源同步失败',
    deletingSourcesSuccess: '数据源删除成功',
    deletingSourcesError: '数据源删除失败',
    importingAccountSuccess: '账户导入成功',
    importingAccountError: '账户导入失败',
    transferAutomatingSuccess: '转账自动化规则成功',
    transferAutomatingError: '转账自动化规则失败',
    tradeAutomatingSuccess: '交易自动化规则成功',
    tradeAutomatingError: '交易自动化规则失败',
    gainLossAutomatingSuccess: '损益 自动化规则成功',
    gainLossAutomatingError: '损益 自动化规则失败',
    businessDataAutomatingSuccess: '业务数据自动化规则成功',
    businessDataAutomatingError: '业务数据自动化规则失败',
    derivativeAdjustmentAutomatingSuccess: '衍生品调整自动化规则成功',
    derivativeAdjustmentAutomatingError: '衍生品调整自动化规则失败',
    counterpartyAutomatingSuccess: '对手方自动化规则成功',
    counterpartyAutomatingError: '对手方自动化规则失败',
    postingSuccess: 'Posting 成功',
    postingError: 'Posting 失败',
    reportingSuccess: '报告生成成功',
    reportingError: '报告生成失败',
    journalGroupConfigExecuteSuccess: '业务卡片已重新生成',
    journalGroupConfigExecuteError: '业务卡片已重新生成，但存在一些错误',
    performingAutomaticReconciliationSuccess: '自动对账成功',
    performingAutomaticReconciliationError: '自动对账已执行，但存在一些错误',
    recalculateCostBasisSuccess: '成本基础已重新计算',
    recalculateCostBasisError: '成本基础已重新计算，但存在错误',
    refreshBalance: '刷新余额',
    refreshBalanceSuccess: '余额刷新成功',
    refreshBalanceError: '余额刷新失败',
    refreshBalanceTimeout: '余额刷新失败：刷新超时',
    accountDeletingSuccess: '账户删除成功',
    accountDeletingError: '账户删除失败',
    reconciliationTaskExecutedSuccess: '对账任务"{name}"规则执行成功',
    reconciliationTaskExecutedError: '对账任务"{name}"规则执行失败',
    reconciliationTaskRefreshedSuccess: '对账任务"{name}"数据装载成功',
    reconciliationTaskRefreshedError: '对账任务"{name}"数据装载失败',
    reconciliationTaskDeletedSuccess: '对账任务删除成功',
    reconciliationTaskDeletedError: '对账任务"{name}"删除失败',
    exportDataFailed: '导出数据失败',
    exportDataSuccess: '导出数据成功'
  },
  totalPosition: {
    provider: '数据源',
    category: '资金类型',
    asset: '币种',
    syncedAmount: '第三方余额',
    inUSDT: '折算为 USDT'
  },
  valuation: {
    selectMethod: '选择计算成本基础的方法',
    fifo: '先进先出 (FIFO)',
    fifoCarryover: '先进先出 (FIFO) 前进',
    average: '加权平均',
    specificID: '特定标识',
    tolerance: '容差',
    fifoCondition: '使用FIFO规则转移成本。',
    manualAssignment: '手动分配',
    automateAssignment: '自动分配',
    summary: '调账数据概览',
    validatorStaking: '验证者质押',
    calculationBeginningTip: '使用上一期的期末余额。如果是第一期，则从 0 开始。',
    crypto: {
      records: '调账计算',
      beginFairValue: '公允价值（期初）',
      endFairValue: '公允价值（期末）',
      costBasis: '成本基础',
      unrealizedGainLoss: '未实现收益（损失）',
      shortTermRealizedGainLoss: '短期已实现收益（损失）',
      longTermRealizedGainLoss: '长期已实现收益（损失）',
      liabilities: '负债',
      acquisition: '获取加密资产(负债)记录',
      acquisitionMemo: '记录加密资产和负债的获取数量和价格',
      lots: '加密资产(负债)持有记录',
      lotsMemo: '记录批次的数量、成本和使用情况',
      disposal: '处置加密资产(负债)记录',
      disposalMemo: '加密资产和负债的处置数量、价格以及已实现的收益和损失',
      adjusting: '未实现损益调账记录',
      adjustingMemo: '由于加密货币价格变化而产生的未实现收益和损失',
      fiatAdjustingMemo: '外币汇率波动导致的汇兑收益和损失',
      purchaseMemo: '买入加密货币相关的记账凭证、买入价格、数量',
      inventory: '库存',
      inventoryMemo: '加密货币库存的剩余数量、成本',
      sales: '销售',
      salesMemo: '卖出加密货币相关的记账凭证、关联的库存记录',
      acquisitionNo: '获取编号',
      referenceNo: '索引号',
      quantity: '数量',
      acquisitionPrice: '获取价格',
      lot: '批次',
      lotNo: '批次编号',
      remainQuantity: '剩余数量',
      averageCostPrice: '平均成本价',
      disposalNo: '处置编号',
      disposalPrice: '处置价格',
      disposalAverageCostPrice: '平均成本价',
      STRGL: '短期已实现收益（损失）',
      LTRGL: '长期已实现收益（损失）',
      adjustingId: '调账编号',
      chartOfAccount: '科目表',
      opening: '开端',
      closingPrice: '收盘价',
      journalNo: '日记编号',
      fiatExchangeGainLoss: '汇兑损益',
      exchangeRateFluctuations: '汇率波动',
      purchaseNo: '购买编号',
      purchasePrice: '购买价格',
      inventoryNo: '库存编号',
      salesNo: '销售编号',
      matchedBy: '匹配者',
      soldPrice: '销售价格',
      grossProfit: '毛利润',
      currencyPair: '货币对',
      closing: '结束'
    },
    fiat: {
      adjusting: '汇兑损益调账记录'
    },
    inventory: {
      inventory: '加密货币库存记录',
      sales: '加密货币销售记录',
      assetSelection: '资产选择'
    },
    assets: {
      navAssets: '资产管理',
      quotaAsset: '配额资产',
      derivative: '衍生品',
      cryptocurrency: '加密货币',
      stablecoin: '稳定币',
      marginAsset: '保证金资产',
      priceProviderSource: '价格供应商来源:',
      nonePriceProvider: '没有价格供应商包含该资产',
      assetAlreadyInList: '资产{address}已经在您的资产列表中',
      contractAddressAlreadyIn: '资产{address}的合约地址已经在资产列表中',
      contractAddressBelongsIn: '此合约地址属于{address}。要添加{addresslink}吗?',
      notIncludeAssetsPrice: '所选的价格提供商不包含该资产的价格'
    },
    price: {
      navPrice: '价格源',
      quote: '计价币种',
      providerName: '数据源名称',
      startDataTime: '开始数据时间',
      latestDataTime: '最新数据时间',
      totalPriceProvider: '价格源总数',
      priceRetrievalMethod: '价格获取方法',
      dataSources: '数据源',
      coingeckoAPI: 'Coingecko接口',
      exchangeRateAPI: '汇率接口',
      addWithOutPrice: '添加无价格源',
      notCovered: '未覆盖'
    },
    future: {
      unrealizedAndAdjustment: '未实现损益调账',
      calculation: '调账记录',
      rawData: '损益数据',
      origin: '来源',
      symbol: '合约资产',
      unrealizedAndChange: '未实现盈亏变动',
      ending: '期末未实现损益',
      beginning: '期初未实现损益',
      period: '期间',
      realizedPAndL: '已实现盈亏',
      journalNo: '凭证号',
      PAndL: '盈亏',
      adjustingDate: '调整日期'
    },
    options: {
      options: '期权',
      quoteAsset: '报价资产'
    },
    validator: {
      stakingRewards: '质押奖励',
      rewardsChange: '质押奖励变动'
    }
  },
  reconciliation: {
    dataSets: '对账数据',
    detailsReconciliation: '对账明细',
    automation: '自动对账规则',
    matches: '已对账',
    report: '对账报告',
    history: '对账操作记录',
    deleteData: '删除数据',
    refreshData: '刷新数据',
    dataRange: '数据范围',
    editTask: '编辑任务',
    backToTaskList: '返回任务列表',
    deleteReconciliationTaskDataMsg: '您即将从对账任务中删除此数据。与此数据相关的匹配记录和解释记录将被删除。',
    removeReconciliationJournalsDataTip: '删除与此数据相关的调节日志',
    pending: '待处理',
    processed: '已处理',
    matchesInThisReconciliationTask: '此对账任务中的匹配',
    automationTitleInfo: '自动对账的自定义规则',
    startAutoMatching: '开始自动匹配',
    treasuryAccountBalance: '科目余额',
    deleteReconciliationTask: '删除对账任务',
    explained: '已解释',
    explain: '解释',
    explainCreateJournal: '解释/创建凭证',
    clearExplanation: '清除解释',
    dataNotAdded: '数据未添加',
    transactionId: '交易ID',
    reason: '原因',
    differenceReason: '差异原因',
    cannotBeRemoved: '无法被删除',
    manualMatch: '手动匹配',
    matchedByRule: '由规则{ruleName}匹配',
    refreshDataWaringInfo: '刷新数据将导致某些数据被删除。与此数据相关的匹配记录和解释记录将被删除。',
    createReconciliationAdjustmentJournal: '创建对账调整凭证',
    reconciliationAdjustmentJournal: '对账调整凭证',
    automaticallyCreateAdjustingEntries: '自动创建调整凭证',
    toleranceExplain: '宽容度解释',
    taskDataSetChangeTipInfo: '数据集条件发生变化，请点击按钮更新数据。',
    coa: '科目',
    reportForThisReconciliationTask: '此对账任务的对账报告',
    childInParent: '{parent} 中的 {child}',
    perTransaction: '每笔交易',
    perLedger: '按账本分类',
    createReconciliationTaskTip: '创建了对账工作“{name}”',
    modifiedReconciliationTaskTip: '修改了对账工作“{name}”详情',
    modifiedFiltersReconciliationTaskTip: '修改了对账工作“{name}”的筛选器',
    deletedReconciliationTaskTip: '删除了对账工作“{name}”',
    addRuleForReconciliationTaskTip: '在对账工作“{name}”中添加了规则“{ruleName}”',
    modifiedRuleForReconciliationTaskTip: '在对账工作“{name}”中修改了规则“{ruleName}”',
    automaticRuleExecutionTip: '在对账工作“{name}”中执行了自动对账',
    deleteMatchRecordTip: '删除了一条匹配记录“{name}”',
    manuallyAddedMatchRecord: '手动添加了一条匹配记录“{name}”',
    addDateSetForReconciliationTaskTip: '在对账工作“{name}”中添加了数据组“{dataName}',
    modifiedDateSetForReconciliationTaskTip: '在对账工作“{name}”中修改了数据组“{dataName}”',
    deleteDateSetForReconciliationTaskTip: '在对账工作“{name}”中删除了数据组“{dataName}”',
    explainedDateSetForReconciliationTaskTip: '在对账工作“{name}”中执行了数据“{dataName}”的解释',
    clearedExplanationForReconciliationTaskTip: '在对账工作“{name}”中清除了数据“{dataName}”的解释',
    createJournalForReconciliationTaskTip: '在对账工作“{name}”中创建了对账调节凭证“{journalName}”',
    deleteJournalForReconciliationTaskTip: '在对账工作“{name}”中删除了对账调节凭证“{journalName}”',
    differenceExplanation: '差值解释',
    differenceExplanationInfo: '所选记录之间的差值为{amount}。请填写下面的表格，提供差值的解释并继续匹配。',
    matching: '匹配中',
    loading: '装载中',
    reconciliationReports: '对账报告'
  }
}
