export default {
  common: {
    elven: 'elven',
    page: 'Page',
    team: 'team',
    tel: 'Tel',
    language: 'Language',
    yourPassword: 'Your password',
    phoneNumber: 'Phone Number',
    email: 'Email',
    emailAddress: 'Email Address',
    password: 'Password',
    captcha: 'Verification Code',
    sendCaptchaCountdown: 's',
    agreementText: 'I have read & agree to the ', // 同意协议文案
    userService: 'Terms of Service ', // 用户协议
    serviceAgreement: 'Privacy Policy', // 隐私政策
    noData: 'No Data',
    address: 'Address',
    addresses: 'addresses',
    addressName: 'Address Name',
    dateTime: 'Datetime',
    currency: 'Currency',
    amountIn: 'Amount(in)',
    amountOut: 'Amount(Out)',
    FromTo: 'From/To',
    lineChart: 'Line chart',
    barChart: 'bar chart',
    numberChart: 'number chart',
    equal: '=',
    greaterThan: '>',
    lessThan: '<',
    range: 'range',
    start: 'from',
    end: 'to',
    to: 'to',
    allRightsReserved: 'All rights reserved.',
    updatedOn: 'Updated on ',
    syncedOn: 'Synced on',
    dataSources: 'Data source',
    share: 'Share',
    financials: 'Financials',
    business: 'Business',
    valuation: 'Valuation',
    project: 'Project',
    projects: 'Projects',
    totalProject: 'Total Project',
    list: 'List',
    results: 'results',
    result: 'result',
    group: 'Group',
    groupView: 'Group View',
    secondaryGroup: 'Secondary Group',
    tertiaryGroup: 'Tertiary Group',
    color: 'COLOR',
    block: 'BLOCK',
    size: 'SIZE',
    axise: 'Axise',
    rulesForSubtotal: 'Rules for Subtotal',
    keepGroup: 'Keep the group view',
    choose: 'Choose',
    stick: 'Stick',
    unstick: 'Unstick',
    hideColumn: 'Hide Column',
    sortAscending: 'Sort ascending',
    sortDescending: 'Sort descending',
    monitor: 'Monitor',
    if: 'if',
    then: 'Then',
    frequency: 'Frequency',
    name: 'Name',
    condition: 'Condition',
    soon: 'SOON',
    total: 'Total',
    marketTotal: 'Total',
    addFilter: 'Add filter',
    filter: 'Filter',
    pleaseChoose: 'Please Choose',
    searchMetrics: 'Search Metrics',
    createdOn: 'Created On',
    lastExcution: 'Last excution',
    everyDay: 'Every Day',
    runsEveryDay: 'Runs Every Day',
    keepAlerts: 'Keep the Alert',
    alertTime: 'Sent At UTC 00 ：30 Every Day',
    account: 'Account',
    contact: 'Contact',
    openingBalance: 'Opening Balance',
    closingBalance: 'Closing Balance',
    journalEntries: 'Journal Entries',
    debit: 'Debit',
    credit: 'Credit',
    event: 'Event',
    txCount: 'Tx Count',
    amount: 'Amount',
    millions: 'Millions',
    billions: 'Billions',
    equals: 'equals',
    thousands: 'Thousands',
    individuals: 'Individual units',
    reportUnit: 'In {unit} of {currency}',
    amountsUnit: 'Amounts in { unit }, USD',
    willBeComingSoon: 'Will be coming soon',
    comingSoon: 'Coming soon',
    description: 'Description',
    chain: 'Chain',
    chains: 'chains',
    custody: 'Custody',
    payment: 'Payment',
    basedAddressOnChain: 'Based on {addressCount} {address} on {chainCount} {chain}',
    allMetrics: 'All Metrics',
    addMetric: 'Add metric',
    basicInfo: 'BASIC INFO',
    followTableSorting: 'Follow the table sorting',
    numberOfGroups: 'Will Be Divided Into {count} Groups',
    fieldTotalRule: '{field} Total Rule: ',
    fieldSubtotalRule: '{field} Subtotal Rule: ',
    Is: 'Is',
    'Is not': 'Is not',
    'Equal to': 'Equal to',
    'More than': 'More than',
    'Less than': 'Less than',
    within: 'Within',
    'Within range': 'Within range',
    'Outside range': 'Outside range',
    'Is empty': 'Is empty',
    'Is not empty': 'Is not empty',
    shareTo: 'Share to',
    selectAccountingPeriod: 'Select the accounting period: ',
    createAccount: 'Create an account',
    activateYourAccount: 'Activate your account',
    verifyEmail: 'Verify your email',
    expandControlPanel: 'Expand Control Panel',
    copied: 'copied',
    moreInformation: 'For more information, please ',
    selected: 'Selected',
    select: 'Select',
    syncFailed: 'Sync failed',
    syncing: 'Syncing',
    updating: 'Updating',
    sum: 'SUM',
    type: 'Type',
    status: 'Status',
    priority: 'Priority',
    conditions: 'Conditions',
    actions: 'Actions',
    date: 'Date',
    is: 'is',
    in: 'in',
    accountName: 'Account name',
    accountType: 'Account type',
    accountTag: 'Account Tag',
    contactType: 'Contact type',
    counterpartyTag: 'Counterparty Tag',
    counterpartyAccount: 'Counterparty Account',
    doesNotEqual: 'does not equal',
    isGreaterThanOrEqualTo: 'is greater than or equal to',
    isGreaterThan: 'is greater than',
    isLessThanOrEqualTo: 'is less than or equal to',
    isLessThan: 'is less than',
    isBetween: 'is between',
    isOn: 'is on',
    isBefore: 'is before',
    isAfter: 'is after',
    on: 'on',
    before: 'before',
    after: 'after',
    between: 'between',
    includes: 'includes',
    notIn: 'not in',
    api: 'API',
    wallet: 'Wallet',
    wallets: 'Wallets',
    exchange: 'Exchange',
    exchanges: 'Exchanges',
    fileName: 'Filename',
    csvFiles: 'CSV Files',
    customizedAPI: 'Customized API',
    entity: 'Entity',
    entities: 'Entities',
    users: 'Users',
    month: 'month',
    annually: 'Annually',
    free: 'Free',
    basic: 'Basic',
    pro: 'Pro',
    enterprise: 'Enterprise',
    unlimited: 'Unlimited',
    CEO: 'Chief Executive Officer (CEO)',
    CFO: 'Chief Financial Officer (CFO)',
    CAO: 'Chief Accounting Officer (CAO)',
    financeManager: 'Finance Manager',
    accountingManager: 'Accounting Manager',
    financialAnalyst: 'Financial Analyst',
    financialAdvisor: 'Financial Advisor',
    taxSpecialist: 'Tax Specialist',
    others: 'Others',
    other: 'Other',
    label: 'Label',
    cryptoNativeFirms: 'Crypto Native Firms',
    assetManagementFirms: 'Asset Management Firms',
    onChainProjects: 'On-Chain Projects',
    MiningStakingNodeOperators: 'Mining, Staking and Node operators',
    ventureCapitals: 'Venture Capitals',
    done: 'Done',
    transactionsEntitiesUsers: 'Transactions, Entities, Users',
    enterprisePlanInfo: 'For large organization with more transaction, support and performance needs.',
    projectSettings: 'Project Settings',
    entitySettings: 'Entity Settings',
    general: 'General',
    integration: 'Integration',
    integrations: 'Integrations',
    members: 'Members',
    member: 'Member',
    PlanBilling: 'Plan & Billing',
    security: 'Security',
    customPricing: 'Custom Pricing',
    subscription: 'Subscription',
    currentPlan: 'Current Plan',
    startDate: 'Start date',
    expirationDate: 'Expiration date',
    subscriptionStatus: 'Subscription status',
    deleteMember: 'Delete member',
    deleteEntity: 'Delete entity',
    administrator: 'Administrator',
    report: 'Report',
    reports: 'Reports',
    settings: 'Settings',
    automation: 'Automation',
    view: 'View',
    ledger: 'Ledger',
    viewRecords: 'View records',
    viewListPermission: 'View {operating} list',
    viewDetailPermission: 'View {operating} detail',
    addNewPermission: 'Add new {operating}',
    editPermission: 'Edit {operating}',
    deletePermission: 'Delete {operating}',
    exportPermission: 'Export {operating}',
    generatePermission: 'Generate {operating}',
    reviewPermission: 'Review {operating}',
    categorizePermission: 'Categorize {operating}',
    executePermission: 'Execute {operating}',
    syncToErp: 'Sync to ERP',
    source: 'source',
    add: 'add',
    edit: 'edit',
    delete: 'delete',
    export: 'export',
    review: 'review',
    rule: 'Rule',
    rules: 'Rules',
    active: 'Active',
    expired: 'Expired',
    next: 'Next',
    prev: 'Prev',
    skip: 'Skip',
    continue: 'Continue',
    continueWithEmail: 'Continue with Email',
    signInWithVerificationCode: 'Sign in with verification code',
    signInWithPassword: 'Sign in with password',
    or: 'OR',
    sameInAllEntities: 'Same in all entities',
    alreadyUser: 'Already a user?',
    newToElven: 'New to Elven?',
    file: 'File',
    uploadFile: 'Upload file',
    bank: 'Bank',
    client: 'Client',
    activity: 'Activity',
    uploadAttachments: 'Upload attachments',
    balance: 'Balance',
    '90D Revenue': '90D Revenue',
    '90D Expense': '90D Expense',
    '90D Profit': '90D Profit',
    ruleName: 'Rule name',
    selectConditionsInfo: 'If all of the following conditions are met:',
    selectCondition: 'Select a condition',
    selectAColumn: 'Select a column',
    selectTheDataType: 'Select the data type',
    performActions: 'Perform the following actions:',
    categorizeTransaction: 'Categorize transaction',
    otherBank: 'Other Bank',
    sort: 'Sort',
    data: 'Data',
    updateMethod: 'Update method',
    period: 'Period',
    updated: 'Updated',
    onChain: 'On-Chain',
    transferIn: 'Transfer In',
    transferOut: 'Transfer Out',
    transactionFee: 'Transaction Fee',
    bankFee: 'Bank Fee',
    exchangeFee: 'Exchange Fee',
    custodyFee: 'Custody Fee',
    paymentFee: 'Payment Fee',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    trade: 'Trade',
    tradeIn: 'Trade In',
    tradeOut: 'Trade Out',
    capture: 'Capture',
    completed: 'Completed',
    chargeBack: 'Charge Back',
    refund: 'Refund',
    payout: 'Payout',
    stake: 'Stake',
    unstake: 'Unstake',
    reward: 'Reward',
    realizedPnL: 'RealizedPnL',
    borrow: 'Borrow',
    repay: 'Repay',
    fundingFee: 'Funding Fee',
    outgoingFX: 'Outgoing FX',
    incomingFX: 'Incoming FX',
    auto: 'Auto',
    daily: 'Daily',
    mannual: 'Mannual',
    all: 'All',
    bookkeeping: 'Bookkeeping',
    custodian: 'Custodian',
    openApi: 'Open API',
    tag: 'Tag',
    dataIntegration: 'Data Integration',
    dataPeriod: 'Data period',
    tagsAndMemo: 'Tags and memo',
    counterparty: 'Counterparty',
    contentTextName: '{content} Name',
    accountId: 'Account ID',
    courseAccountId: 'Account ID',
    parentAccountId: 'Parent Account ID',
    accountNumber: 'Account Number',
    bankAccount: 'Bank Account',
    posted: 'Posted',
    difference: 'Difference',
    quarter: 'Quarter',
    year: 'Year',
    day: 'Day',
    week: 'Week',
    Month: 'Month',
    dateRange: 'Date Range',
    price: 'Price',
    newestToOldest: 'Newest to Oldest',
    oldestToNewest: 'Oldest to Newest',
    groupByEvent: 'Group by event',
    businessEvent: 'Business event',
    collapseAll: 'Collapse all',
    expandAll: 'Expand all',
    summary: 'Summary',
    history: 'History',
    platform: 'Platform',
    platformType: 'Platform Type',
    timezone: 'Timezone',
    passed: 'Passed',
    failed: 'Failed',
    notVerified: 'Not Verified',
    code: 'Code',
    createdBy: 'Created By',
    system: 'System',
    default: 'Default',
    deleted: 'Deleted',
    dataName: 'Data name',
    totalLines: 'Total lines',
    original: 'Original',
    originalData: 'Original data',
    originalDataLines: 'Original data lines',
    identifiedJournals: 'Identified journals',
    identificationStatus: 'Identification Status',
    cannotBeChanged: 'Cannot be changed once set.',
    allActivity: 'All activity',
    allEntity: 'All entity',
    allMember: 'All member',
    login: 'Login',
    accountTreasury: 'Account (Treasury)',
    manualCreated: 'Manual Created',
    value: 'Value',
    customOptions: 'Custom Options',
    operation: 'Operation',
    crypto: 'Crypto',
    fiatFX: 'Fiat (FX)',
    foreignCurrency: 'Foreign Currency',
    inventory: 'Inventory',
    brokerInventory: 'Broker Inventory',
    tags: 'Tags',
    totalAmount: 'Total amount',
    direction: 'Direction',
    excludes: 'excludes',
    successfully: 'Successfully',
    failure: 'Failure',
    item: 'item',
    items: 'items',
    verify: 'Verify',
    verifying: 'Verifying',
    options: 'Options',
    subgroup: 'Subgroup',
    uncategorized: 'Uncategorized',
    accountingLedger: 'Accounting Ledger',
    cashBasis: 'Cash basis',
    accrualBasis: 'Accrual basis',
    text: 'Text',
    number: 'Number',
    futures: 'Futures',
    futureOpen: 'Future Open',
    futureClose: 'Future Close',
    Options: 'Options',
    optionBuy: 'Option Buy',
    optionSell: 'Option Sell',
    optionExercise: 'Option Exercise',
    spotBuy: 'Spot Buy',
    spotSell: 'Spot Sell',
    foreignExchange: 'Foreign Exchange',
    swap: 'Swap',
    futureRealized: 'Future Realized',
    futureUnrealized: 'Future Unrealized',
    optionRealized: 'Option Realized',
    optionUnrealized: 'Option Unrealized',
    totalKeys: 'Total keys',
    created: 'Created',
    totalAsset: 'Total asset',
    addAsset: 'Add Asset',
    editAsset: 'Edit Asset',
    spam: 'Spam',
    input: 'Input',
    asset: 'Asset',
    method: 'Method',
    future: 'Future',
    table: 'Table',
    origin: 'Origin',
    symbol: 'Symbol',
    weekly: 'Weekly',
    Monthly: 'Monthly',
    updatedAt: 'Updated At',
    preview: 'Preview',
    allCurrencies: 'All Currencies',
    trial: 'Trial',
    mainServicesAgreement: 'Main Services Agreement',
    reminder: 'Reminder',
    updateNow: 'Update Now',
    entries: 'entries',
    entry: 'entry',
    standard: 'Standard',
    customized: 'Customized',
    mostPopular: 'Most popular',
    customizedVersion: 'Customized Version',
    since: 'Since {time}',
    nextPayment: 'Next payment{time}',
    unsubscribe: 'Unsubscribe',
    resubscribe: 'Resubscribe',
    canceled: 'Canceled',
    exceededLimit: 'Exceeded limit',
    paymentFailed: 'Payment failed',
    usage: 'Usage',
    invoiceNo: 'Invoice No.',
    paid: 'Paid',
    activate: 'Activate',
    dataTypes: 'Data Types',
    filters: 'Filters',
    beta: 'Beta',
    rebate: 'Rebate',
    protocol: 'Protocol',
    checkPoint: 'Checkpoint',
    ExportDate: 'Export Date',
    categorizing: 'Categorizing',
    dataUntil: 'Data until',
    dataSince: 'Data since'
  },
  report: {
    Total: 'Total',
    dataSource: 'Data source',
    journals: 'Journals',
    Journal: 'Journal',
    JournalList: 'Journal list',
    actionJournal: 'Action / Journal',
    actionCreateJournal: 'Action / Create Journal',
    actionCreateValuationData: 'Action / Create Valuation Data',
    actionEntry: 'Action / Entry',
    entry: 'Entry',
    reconciliation: 'Reconciliation',
    actionReconciliation: 'Action / Reconciliation',
    actionCreateData: 'Action / Create Data',
    actionIdentifyCounterparty: 'Action / Identify Counterparty',
    relatedTransaction: 'Related Transfer',
    relatedTrade: 'Related Trade',
    relatedGainLoss: 'Related Gain/Loss',
    relatedJournal: 'Related Journal',
    basedOnCategorizing: 'Based on Categorizing',
    basedOnReconciliation: 'Based on Reconciliation',
    Address: 'Address',
    Account: 'Account',
    'Address Name': 'Address Name',
    Date: 'Date',
    Status: 'Status',
    Datetime: 'Datetime',
    TrailBalanceStatus: 'Status',
    Currency: 'Currency',
    'Auxiliary code': 'Auxiliary code',
    'Chart of account': 'Chart of account',
    'Treasury account': 'Treasury account',
    Amount: 'Amount',
    'Amount (In)': 'Amount (In)',
    'Amount (Out)': 'Amount (Out)',
    'From/To': 'From/To',
    Match: 'Match',
    balanceVerify: 'Balance Verify',
    verifyBalance: 'Verify Balance',
    verifyingBalance: 'Verifying Balance',
    refreshingBalance: 'Refreshing Balance',
    balanceExport: 'Balance Export',
    balanceDelete: 'Balance Delete',
    'Dr/Cr': 'Dr/Cr',
    journalType: 'Journal Type',
    journalTypeName: 'Journal Type Name',
    dateJournalNo: 'Date & Journal No.',
    referenceNo: 'Reference No.',
    auxiliaryCode: 'Auxiliary Code',
    Description: 'Description',
    Debit: 'Debit',
    Credit: 'Credit',
    Event: 'Event',
    'Opening Balance': 'Opening Balance',
    'Closing Balance': 'Closing Balance',
    totalInflow: 'Total Inflow',
    totalOutflow: 'Total Outflow',
    totalDebit: 'Total Debit',
    totalCredit: 'Total Credit',
    'Type of Assets': 'Type of Assets',
    'Nominal Amount': 'Nominal Amount',
    'Market Value': 'Market Value',
    '% of total net assets': '% of total net assets',
    Multiples: 'Multiples',
    IRR: 'IRR',
    'Cost basis': 'Cost basis',
    'Realized Proceeds': 'Realized Proceeds',
    'Cost basis of Realized Proceeds': 'Cost basis of Realized Proceeds',
    ON_CHAIN: 'On-Chain',
    OFF_CHAIN: 'Off-Chain',
    GLOBAL: 'Consolidated',
    syncingWalletsOrExchanges: 'Syncing Wallets / Exchanges',
    synchronizingTotal: '{total} source is synchronizing',
    synchronizingTotals: '{progress}/{total} sources are synchronizing',
    syncingSources: 'Syncing sources ({progress}/{total})',
    syncTransactionsTotal: ' Transactions have been imported, ',
    syncTransactionsComplete: 'Sync complete, ',
    syncComplete: "Sync complete, please click the 'regenerate reports' button to update the reports.",
    deletedAccountTitle: 'Delete {name}',
    deletedAccountInfo: 'Will delete all transactions & sources from this account, this operation cannot be recovered.',
    batchEdit: 'Batch Edit',
    totalContacts: '{total} Contacts',
    contactType: 'Contact Type',
    employee: 'Employee',
    vendor: 'Vendor',
    customer: 'Customer',
    investor: 'Investor',
    community: 'Community',
    debtor: 'Debtor',
    sponsor: 'Sponsor',
    memo: 'Memo',
    transaction: 'Transaction',
    sent: 'SENT',
    received: 'RECEIVED',
    internal: 'INTERNAL',
    source: 'Source',
    Source: 'Source',
    Name: 'Name',
    Memo: 'Memo',
    Assets: 'Assets',
    assets: 'assets',
    liabilities: 'Liabilities',
    equity: 'Equity',
    assetsPortfolio: 'Assets Portfolio',
    Quantity: 'Quantity',
    'Cost Basis': 'Cost Basis',
    'Fair Value': 'Fair Value',
    'Address/Bank Account': 'Address/Bank Account',
    'Address/Account': 'Address/Account',
    'Significant holdings report': 'Significant holdings report',
    allSources: 'All Sources',
    Transactions: 'Transactions',
    transactionDetail: 'Transaction Detail',
    datetime: 'Datetime',
    amount: 'Amount',
    from: 'From',
    to: 'To',
    accounting: 'Accounting',
    transactionsTotal: '{total} Transactions',
    regenerateReports: 'Regenerate reports',
    regenerating: 'Regenerating',
    capitalGainORLoss: 'Capital Gain/Loss',
    cryptoFairValueMeasurement: 'Crypto Fair Value Measurement',
    FXRate: 'FX Rate',
    priceProvider: 'Price Provider',
    priceDatetime: 'Price Datetime (UTC)',
    editTimeUTC: 'Edit Time (UTC)',
    salesNo: 'Sales No.',
    pricePair: 'Price Pair',
    FXProvider: 'FX Provider',
    FXDatetime: 'FX Datetime (UTC)',
    FXPair: 'FX Pair',
    FXPrice: 'FX Price',
    marketValue: 'Market Value',
    sourceName: 'Source name',
    accountNumber: 'Account Number',
    key: 'Key',
    secret: 'Secret',
    passphrase: 'Passphrase',
    accountingPreview: 'Accounting entries preview:',
    accountingPreviewInfo: 'The accounting entries will be effective when the statements are updated',
    regenerateTheReports: 'Transaction data have changed, Click to regenerate the reports',
    regenerated: 'Regenerated',
    generating: 'Generating',
    'Rule Engine Configure Accounting Entries and Cost Basis':
      'Rule Engine Configure Accounting Entries and Cost Basis',
    'Generate Income Statement report': 'Generate Income Statement report',
    'Generate Balance Sheet report': 'Generate Balance Sheet report',
    'Generate Restrictions of Crypto Assets report': 'Generate Restrictions of Crypto Assets report',
    'Generate Cash Flow Statement report': 'Generate Cash Flow Statement report',
    'Generate Crypto Assets Roll Forward report': 'Generate Crypto Assets Roll Forward report',
    'Generate Significant Holdings report': 'Generate Significant Holdings report',
    'Generate note L1': 'Generate note L1',
    'Generate note L2': 'Generate note L2',
    'Reports have been regenerated successfully': 'Reports have been regenerated successfully',
    Tabulating: 'Tabulating',
    'Regenerate Failed, please check your transaction data': 'Regenerate Failed, please check your transaction data',
    deleting: 'Deleting',
    reportRegeneratedSuccess: 'Report has been regenerated',
    noSet: 'No set',
    transactionsRule: '{total} transactions match the rule:',
    similarTransactions: '{total} similar transactions:',
    setEvent: 'Set Event',
    resolutionCenter: 'Resolution Center',
    resolve: 'Resolve',
    wellDone: 'Well Done',
    transactionsCategorized: 'All transactions are categorized',
    duplicateTransactions: 'No suspected duplicate transactions',
    keepAll: 'Keep All',
    keepAllConfirm: 'These transactions look like duplicate data, are you sure you want to keep them anyway?',
    possibleDuplicates: 'Possible Duplicates',
    possibleDuplicatesInfo:
      '{total} groups of transactions are similar to each other, is it possible that they are the same?',
    possibleDuplicateInfo:
      '{total} group of transactions are similar to each other, is it possible that they are the same?',
    unknownEvent: 'Unknown Event',
    unknownEventsInfo: '{total} transactions can not identify business activities.',
    unknownEventInfo: '{total} transaction can not identify business activities.',
    disposalExceedsBalance: 'Disposal exceeds balance - {currency}',
    disposalExceedsBalanceInfo:
      'In certain transactions, {currency} disposal exceeds balance. Please verify sources or category accuracy.',
    errorReport: '{sheet} calculation error',
    errorReportInfo: 'There are some numerical errors in the report',
    discard: 'Discard',
    batchConfirm: 'Batch Confirm',
    batchSettingEvents: 'Batch setting events',
    categorizeTransactionAs: 'Categorize transaction as',
    transactionType: 'Transaction type',
    inflow: 'Inflow',
    outflow: 'Outflow',
    manuallyExecuteRules: 'Manually execute all rules',
    rulesAreExecuting: 'Rules are executing',
    operating: 'Operating',
    financing: 'Financing',
    investing: 'Investing',
    internalTransfer: 'Internal Transfer',
    gainOrLossAdjusting: 'Gain/Loss adjusting',
    generalJournal: 'General Journal',
    automaticallyReviewed: 'Automatically mark as reviewed',
    deleteRule: 'Delete rule',
    executeRule: 'Execute rule',
    individual: 'Individual',
    organization: 'Organization',
    unnamed: 'Unnamed',
    identified: 'Identified',
    unidentified: 'Unidentified',
    named: 'Named',
    addYourWallet: 'Add your first wallet',
    addWalletInfo: 'Add an wallet to import on-chain transaction data',
    addYourExchange: 'Add your first exchange',
    addExchangeInfo: 'Get trading data from an exchange use API or Auth',
    uploadCSVFile: 'Upload a CSV file',
    uploadCSVFileInfo: 'Import offline trading data by uploading a CSV file',
    welcomeUser: 'Welcome, {userName}',
    startCreateProject: 'Start creating your first project now!',
    choosePlan: 'Choose a subscription plan',
    applyForTrial: 'Apply for Trial',
    applyForTrialInfo:
      'Thanks for your interest, please fill out the form below and we will contact you as soon as possible',
    applyWaiting: 'You are already on the waiting list, please wait patiently for our reply',
    yourOrganization: 'Your organization',
    yourPosition: 'Your position',
    industry: 'Industry',
    numberOfHistoricalTransactions: 'Number of historical transactions',
    otherCryptoFinancialProductsUsed: 'Other crypto financial products used',
    howCanElvenHelpYou: 'How can Elven help you?',
    projectName: 'Project name',
    entityName: 'Entity name',
    projectLogo: 'Project logo',
    entityLogo: 'Entity logo',
    countryRegion: 'Country/Region',
    GSTSetting: 'GST Setting',
    enableGST: 'Enable GST',
    dataStartingTime: 'Data starting Time',
    costBasisMethodology: 'Cost basis methodology',
    fairValueCostBasisMethodology: 'Fair value cost basis methodology',
    defaultCurrency: 'Default currency',
    functionalCurrency: 'Functional currency',
    preferredCurrencyUnit: 'Preferred currency unit',
    projectHasBeenCreated: 'Your project has been created!',
    getStartedOnFewEasySteps: 'Get started on a few easy steps:',
    cancelRequestInfo:
      'You have applied for a trial and it is currently pending approval. Do you want to cancel it now?',
    needHelp: 'Need help?',
    bookDemoTitle: 'Book a Demo',
    bookDemoInfo: 'You can schedule a remote video demo where our team of experts will guide you through using Elven.',
    ReadDocsTitle: 'Read this Docs',
    ReadDocsInfo:
      'Following our comprehensive help documentation, which will guide you through each step of using Elven.',
    ruleCount: '{total} rules',
    startingFirstProject: 'Starting from creating your first project.',
    category: 'Category',
    fileTemplate: 'File template',
    totalLines: 'Total lines',
    unrecognizableLines: 'Unrecognizable lines',
    transactionsDiscarded: 'Transactions to be discarded',
    transactionsImported: 'Transactions to be imported',
    recognizedLines: 'Recognized lines',
    onlyGenericFormatCSV: 'Only generic format CSV is supported, ',
    onlyAcceptCSV: 'Only accept CSV files that adhere to the Elven standard format, ',
    clickDownloadTemplate: 'Click to download the template file',
    uploading: 'Uploading',
    parsingFile: 'Parsing file',
    parsingFailed: 'Parsing failed',
    uploadCompleted: 'Upload completed',
    wrongDataFormat: 'Wrong data format',
    businessDataCSVFormatError: 'The format of this CSV file is inconsistent with the data {name}',
    unableRecognizeYourFile: 'Unable to recognize your file',
    dragUploadOrBrowse: 'Drag and drop to upload, or browse files.',
    uploadCSVMaxSize: 'Supported file types: csv, max size: 100m',
    selectAccountInThisFile: 'Select account in This File',
    added: 'Added',
    addedBy: 'Added By',
    addedDate: 'Added Date',
    addedMethod: 'Added Method',
    manualAdded: 'Manual Added',
    automaticSynced: 'Automatic Synced',
    dataCutoffTime: 'Data cutoff time',
    editWallet: 'Edit Wallet',
    editExchange: 'Edit Exchange',
    editCSV: 'Edit CSV',
    generalTransactions: 'General Transactions',
    customTransactions: 'Custom Transactions',
    bankTransactions: 'Bank Transactions',
    exchangeTrade: 'Exchange (Trade)',
    exchangeDeposit: 'Exchange (Deposit)',
    exchangeWithdraw: 'Exchange (Withdraw)',
    completeHistory: 'Complete history',
    dataBefore: 'Data before',
    dataAfter: 'Data after',
    dataBetween: 'Data between',
    transactionDataOption: 'Transaction data option',
    addSources: 'Add sources',
    addSourcesInfo: 'Add on-chain wallet or exchange for your project, or upload CSV files.',
    reviewTransactions: 'Review transactions',
    reviewTransactionsInfo: 'Review transactions and match each one with the correct business activity tag.',
    generateReports: 'Generate reports',
    generateReportsInfo: 'Generate financial statements and an crypto asset disclosure with just one click!',
    addMemo: 'Add memo',
    groupsDuplicatesTotal: '{total} groups of transactions may be duplicates:',
    platformSource: '{platform} Source',
    chartOfCount: 'chartOfCount',
    accountBalance: 'Account Balance',
    preparer: 'Preparer',
    approver: 'Approver',
    approve: 'Approve',
    reject: 'Reject',
    unMatchedAmount: 'Unmatched Amount',
    matches: 'Matches',
    matchSetMatches: 'Matches',
    approved: 'Approved',
    prepared: 'Prepared',
    notPrepared: 'Not Prepared',
    matchSetDetail: 'Match Set Detail',
    unmatched: 'Unmatched',
    unmatch: 'Unmatch',
    submitAsPrepared: 'Submit as Prepared',
    matchedBy: 'Matched By',
    matchDetail: 'Match detail',
    matchId: 'Match ID',
    matched: 'Matched',
    matchSets: 'Match Sets',
    inflowTransactions: 'Inflow transactions',
    outflowTransactions: 'Outflow transactions',
    holdling: 'Holdling',
    processing: 'Processing',
    treasuryAccount: 'Treasury Account',
    businessData: 'Business Data',
    originalAmount: 'Original Amount',
    reportingAmount: 'Reporting Amount',
    originalCurrency: 'Original Currency',
    cashAccount: 'Cash Account',
    'Datasource provider': 'Datasource provider',
    calculatedBalance: 'Display the balance calculated based on transactions and manual added balances',
    syncedBalance: 'Display the balance synced from {source}',
    refreshBalanceInfo: 'You can refresh your balance {total} times each day, currently have {left} left.',
    'Base Asset': 'Base Asset',
    transfer: 'Transfer',
    transferId: 'Transfer ID',
    positionAndSide: 'Position Side',
    baseAndAmount: 'Base Amount',
    counterAndAmount: 'Counter Amount',
    feeAndAmount: 'Fee Amount',
    counterAsset: 'Counter Asset',
    feeAsset: 'Fee Asset',
    tradeId: 'Trade ID',
    tradeDetail: 'Trade detail',
    gainLossAsset: 'G/L Asset',
    gainLoss: 'Gain(Loss)',
    gainLossAmount: 'Gain(Loss) Amount',
    'Asset Symbol': 'Asset Symbol',
    'Position Side': 'Position Side',
    'Margin Asset': 'Margin Asset',
    'Unrealized P&L': 'Unrealized P&L',
    gainLossID: 'Gain/Loss ID',
    identifyCounterparty: 'Identify Counterparty',
    accountName: 'Name',
    companyFullName: 'Company full name',
    employees: 'Employees',
    website: 'Website',
    yourFirstName: 'Your first name',
    yourLastName: 'Your last name',
    yourJobTitle: 'Your job title',
    yourWorkEmail: 'Your work email',
    projectReadyForUse: 'Your project is ready for use',
    upEntityInformation: 'Please set up your entity information.',
    taskName: 'Task Name',
    dashboardPeriod: 'Period',
    'Account/Protocol': 'Account/Protocol',
    Type: 'Type',
    Pool: 'Pool',
    Supply: 'Supply',
    'Pending Rewards': 'Pending Rewards',
    Borrow: 'Borrow'
  },
  title: {
    loginPageTitle: 'Powerful editing capabilities make the data display clearer.',
    loginPageTitle1: 'Create my own screener！',
    loginToExploreFurther: 'Login to explore further',
    setPasswordsTitle: 'You must set a password, later can password login.',
    exceedsExportLimit: 'Exceeds export limit',
    connectIntegrationWalletInfo: 'Link {platform} to synchronize your hosted wallet.',
    connectIntegrationInfo:
      "Link Elven to your {platform} account to automatically sync Elven's journal entry data to your {platform}.",
    AccountInPlatform: 'Account in {platform}',
    disconnectWithPlatform: 'Disconnect with {platform}',
    deleteAttachment: 'Delete attachment',
    addChainWallet: 'Add {chain} Wallet',
    addPlatformAccount: 'Add {platform} Account',
    addContactInformation: 'Add Contact Information',
    editContactInformation: 'Edit Contact Information',
    contactInformation: 'Contact Information',
    setupTwoFactorAuthentication: 'Setup Two-factor Authentication',
    twoFactorAuthentication: 'Two-factor authentication',
    authenticatorEnabled: 'Authenticator apps are enabled',
    authenticatorDisabled: 'Authenticator app is not enabled',
    twoFactorVerificationCode: 'Two-factor verification code',
    disableTwoFactorAuthentication: 'Disable Two-factor Authentication',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    backupCodes: 'Backup Codes',
    createNewSetBackupCodes: 'Create a new set of backup codes',
    securelyStoreYourBackupCodes: 'Securely store your backup codes',
    configureYourTwoFactorClient: 'Configure your two factor client',
    verifyTheConfiguration: 'Verify the configuration',
    allCrypto: 'All Crypto',
    allFiat: 'All Fiat',
    allPair: 'All Pair',
    deleteBalance: 'Delete Balance',
    deleteBusinessEvent: 'Delete Business Event',
    configureBusinessEvent: 'Configure Business Event',
    addBusinessEvent: 'Add Business Event',
    addJournal: 'Add Journal',
    editJournal: 'Edit Journal',
    createJournal: 'Create Journal',
    createJournalCell: 'Create Journal',
    memoAndAttachment: 'Memo & Attachment',
    entryLines: 'Entry lines',
    GSTRate: 'GST Rate',
    projectEnded: 'Your free trial has ended',
    deleteJournal: 'Delete Journal',
    deleteCustomTitle: 'Delete {name}',
    businessDataIntegration: 'Business data integration',
    additionalItem: 'Additional Item',
    balanceType: 'Balance Type',
    newAccount: 'New account',
    editAccount: 'Edit account',
    parentAccount: 'Parent account',
    accountId: 'Account ID',
    accountName: 'Account name',
    deleteAccount: 'Delete Account',
    checkingAccountStatus: 'Checking account status',
    newJournalType: 'New Journal Type',
    editJournalType: 'Edit Journal Type',
    journalTypeName: 'Journal type name',
    checkingJournalTypeStatus: 'Checking journal type status',
    deleteJournalType: 'Delete Journal Type',
    treasuryAccountMapping: 'Treasury account mapping',
    accountMapping: 'Account Mapping',
    accountMappingDesc: 'Before exporting the trial balance for xero, please complete account mapping',
    chartOfAccounts: 'Chart of Accounts',
    mapping: 'Mapping',
    editMapping: 'Edit mapping',
    businessEventName: 'Business event Name',
    groupingCriterion: 'Grouping criterion',
    includedJournals: 'Included Journals',
    eventSummary: 'Event Summary',
    journalName: 'Journal name',
    auxiliaryCodeName: 'Auxiliary code name',
    auxiliaryCodeType: 'Auxiliary code type',
    auxiliaryCodeValue: 'Value',
    addAuxiliaryCode: 'Add Auxiliary code',
    editAuxiliaryCode: 'Edit Auxiliary code',
    identificationCode: 'Identification code',
    onChainAddress: 'On-Chain address',
    bankAccount: 'Bank account',
    brokerInventoryType: 'Broker inventory type',
    setCostBasisMethod: 'Set cost basis method',
    setCostBasisMethodTitle: 'Select the method for calculating your Cost Basis.',
    saleDetail: 'Sale Detail',
    exportData: 'Export Data',
    fileIsImporting: '{name} is importing',
    fileImportCompleted: '{name} import completed',
    fileImportFailed: '{name} import failed',
    deleteIdentificationCode: 'Delete Identification code',
    selectCounterparty: 'Select Counterparty',
    startingDate: 'Starting Date',
    importLots: 'Import Lots',
    importInventory: 'Import Inventory',
    addMatchSet: 'Add Match Set',
    editMatchSet: 'Edit Match Set',
    deleteMatchSet: 'Delete Match Set',
    importOnChainWalletAccount: 'Import On-chain Wallet Account',
    thisAccountCanNotBeDeleted: 'This account can not be deleted',
    deletedAccounts: 'deleted accounts',
    checkingDeleteAccountStatus: 'Checking account status',
    deleteAccountDialogTitle: 'Delete account',
    openAPIIntegration: 'OpenAPI Integration',
    addOpenAPIkey: 'Add OpenAPI key',
    editOpenAPIkey: 'Edit OpenAPI key',
    deleteAPIKeySecret: 'Delete API Key/Secret',
    searchByName: 'Search by name',
    assetName: 'Asset Name',
    smartContract: 'Smart Contract',
    symbolCode: 'Symbol/Code',
    decimal: 'Decimal',
    assetLogo: 'Asset Logo',
    addContract: 'Add contract',
    contract: 'Contract',
    position: 'Position',
    executeAllRules: 'Execute all rules',
    executeRuleName: 'Execute rule {name}',
    addPriceProvider: 'Add Price Provider',
    uploadPriceData: 'Upload Price Data',
    adjustmentFrequency: 'Adjustment Frequency',
    conditionsHaveChanged: 'Conditions have changed',
    changePriceProvider: 'Change Price Provider',
    basedOnWhichInformation: 'Based on Which Information',
    RegexForExtractingIdentificationCodeFromMemo: 'Regex for Extracting Identification code from Memo',
    matchToWhichKeyOfTheElvenCounterpartyIdentificationCode:
      'Match to which key of the Elven counterparty Identification code',
    caseSensitiveMatch: 'Case Sensitive Match',
    importMultipleBalance: 'Import Balance For Multiple accounts',
    sourceName: 'Name',
    sourceAccount: 'Account',
    sourceIntegration: 'Integration',
    sourceData: 'Data',
    counterpartyName: 'Name',
    transactionCategory: 'Category',
    transactionCategorize: 'Categorize',
    ruleName: 'Name',
    refNo: 'Ref No.',
    assetsName: 'Name',
    assetCategory: 'Category',
    priceName: 'Name',
    priceMethod: 'Method',
    journalTypeId: 'ID',
    journalTypeCode: 'Code',
    matchSetTransactions: 'Transactions',
    matchSetLedger: 'Ledger',
    matchSetName: 'Name',
    settingMembers: 'Members',
    settingTransactions: 'Transactions',
    securityActivity: 'Activity',
    createNewCounterparty: 'Create New Counterparty',
    reportPeriod: 'Period',
    executeIdentifyCounterpartyRules: 'Execute Identify Counterparty rules',
    deleteChart: 'Delete Chart',
    uploadChartOfAccount: 'Upload Chart Of Account',
    editAccountMappingName: 'Edit Account Mapping Name',
    deleteAccountMapping: 'Delete Account Mapping',
    activationRequired: 'Activation Required',
    freePlanLimitReached: 'Free Plan Limit Reached',
    saveAccountMappingName: 'Save Account Mapping Name',
    transactionAndBalance: 'Transaction&Balance',
    journalImport: 'Journal Import',
    importJournal: 'Import Journal',
    importedJournals: 'Imported journals',
    importMethod: 'Import method',
    originalFilename: 'Original Filename',
    importedBy: 'Imported by',
    imported: 'Imported',
    projectDeactivated: 'Project Has Been Deactivated',
    generalTemplate: 'General Template',
    xeroJournalExport: 'Xero journal export',
    yourPlan: 'Your plan',
    addPaymentMethod: 'Add Payment Method',
    cancelProject: 'Cancel Project',
    confirmCancelation: 'Confirm Cancelation',
    currentPlan: 'Current plan',
    billingHistory: 'Billing History',
    historicalTransactions: 'Historical Transactions',
    upgradeToStandardPlan: 'Upgrade to Standard Plan',
    confirmUnSubscription: 'Confirm Unsubscription',
    functions: 'Functions',
    basicModules: 'Basic modules',
    advancedModules: 'Advanced modules',
    customModules: 'Custom modules',
    supportAndServices: 'Support and Services',
    applyFreeAccess: 'Apply Free Access',
    referral: 'referral',
    reconciliationTasks: 'Reconciliation Tasks',
    createReconciliationTask: 'Create reconciliation task',
    editReconciliationTask: 'Edit reconciliation task',
    duplicateReconciliationTask: 'Duplicate reconciliation task',
    dataSetName: 'Data Set Name',
    counterpartyAccount: 'Counterparty account',
    explainUnmatchedLedger: 'Explain Unmatched Ledger',
    explainUnmatchedTransfer: 'Explain Unmatched Transfer',
    changeCheckPoint: 'Change Checkpoint',
    fileIsExporting: 'Preparing {name} Data for Export',
    fileIsExportComplete: 'Prepared {name} Data for Export',
    fileIsExportFailed: '{name} Export Failed',
    cryptoSummary: 'Crypto-Summary',
    fiatSummary: 'Fiat (FX)-Summary',
    brokerInventorySummary: 'Broker Inventory-Summary',
    notCategorized: 'Not categorized',
    notCreatedByCategorizing: 'Not created by categorizing',
    accountingStartDate: 'Accounting start date'
  },
  button: {
    clear: 'Clear',
    delete: 'Delete',
    save: 'Save',
    saveAs: 'Save as',
    saveFailed: 'Save failed',
    discard: 'DisCard',
    contactUs: 'Contact Us',
    filter: 'Filter',
    editTable: 'Edit Table',
    groupBy: 'Group By',
    addGroup: 'Add Group',
    subGroup: 'Sub Group',
    export: 'Export',
    saveChanges: 'Save Changes',
    cancel: 'Cancel',
    addCondition: 'Add condition',
    ok: 'OK',
    yes: 'Yes',
    no: 'No',
    sendCaptchaText: 'send', // 发送之前验证码按钮文案
    sentCaptchaButtonText: 'Resend', // 点击发送之后 验证码按钮文案
    login: 'Login', // 登录按钮
    loginButtonTextInDetail: 'Log in', // 详情页登录按钮
    logOut: 'Log out',
    signOut: 'Sign out',
    myAccount: 'My Account', // 我的账号
    bindEmail: 'Email', // 绑定邮箱
    saveAlert: 'Save Alert',
    newAlert: 'New Alert',
    editAlert: 'Edit Alert',
    clickView: 'click to view',
    connect: 'Connect',
    continue: 'Continue',
    upload: 'Upload',
    uploaded: 'Uploaded',
    uploadNewVersion: 'Upload new version',
    type: 'Type',
    sources: 'Sources',
    events: 'Events',
    categories: 'Categories',
    currencies: 'Currencies',
    contacts: 'Contacts',
    dateTime: 'Datetime',
    allTime: 'All Time',
    reset: 'Reset',
    deleteContact: 'Delete Contact',
    deleteContacts: 'Delete Contacts',
    deleteAccount: 'Delete Account',
    deleteAccounts: 'Delete Accounts',
    edit: 'Edit',
    confirm: 'Confirm',
    backList: 'Back to list',
    addRule: 'Add Rule',
    editRule: 'Edit Rule',
    addWallet: 'Add Wallet',
    addExchange: 'Add Exchange',
    upLoadCSV: 'Upload CSV',
    learnMore: 'Learn more',
    create: 'Create',
    apply: 'Apply',
    applyNow: 'Apply now',
    talkToSales: 'Talk to sales',
    cancelRequest: 'Cancel Request',
    submit: 'Submit',
    createProject: 'Create Project',
    start: 'Start',
    dismiss: 'Dismiss',
    confirmCancel: 'Confirm Cancel',
    addEntity: 'Add Entity',
    editEntity: 'Edit Entity',
    addMember: 'Add Member',
    editMember: 'Edit Member',
    add: 'Add',
    changeEmail: 'Change Email',
    changePassword: 'Change Password',
    otherSign: 'Other ways to sign in',
    signIn: 'Sign in',
    signUp: 'Sign up',
    getStarted: 'Get started',
    upgrade: 'Upgrade',
    markAllAsReviewed: 'Mark all as reviewed',
    markSelectedAsReviewed: 'Mark selected as reviewed',
    iKnow: 'I know',
    helpDocuments: 'Help & Documents',
    feedback: 'Feedback',
    helpCenter: 'Help Center',
    switchToV2: 'Switch to V2',
    syncIntegration: 'Sync to {platform}',
    disconnect: 'Disconnect',
    accountMappings: 'Account Mappings',
    finish: 'Finish',
    resendInvitation: 'Resend invitation',
    goReview: 'Go to review transactions',
    reuploadFile: 'Re-upload file',
    uploadAnotherFile: 'Upload another file',
    uploadToExistingAccount: 'Upload to existing account',
    createNewAccount: 'Create new account',
    loadMore: 'Load more',
    addSource: 'Add Source',
    addPlatformSource: 'Add {platform} Source',
    editSource: 'Edit Source',
    addAccount: 'Add Account',
    editAccount: 'Edit Account',
    addCounterparty: 'Add Counterparty',
    uploadXeroCOA: 'Upload Xero COA',
    editCounterparty: 'Edit Counterparty',
    addData: 'Add data',
    addBalance: 'Add balance',
    addNew: 'Add New',
    addNewJournal: 'Add New',
    newMatchSet: 'New Match Set',
    addNewEvent: 'Add new event',
    accountSettings: 'Account Settings',
    disable: 'Disable',
    setupNow: 'Setup Now',
    next: 'Next',
    viewDetail: 'View Detail',
    createGeneralJournal: 'Create general journal',
    recalculateCostBasis: 'Recalculate Cost Basis',
    recalculate: 'Recalculate',
    postToLedger: 'Post to ledger',
    contactSales: 'Contact Sales',
    recategorize: 'Recategorize',
    removeCategory: 'Remove category',
    addNewIntegration: 'Add new integration',
    addCustomButton: 'Add {name}',
    goToAddAccount: 'Go to add account',
    addAuxiliaryCode: 'Add auxiliary code',
    addANewOption: 'Add a new option',
    close: 'Close',
    followParentSetting: 'Follow parent setting',
    configuration: 'Configuration',
    regenerate: 'Regenerate',
    setUpNow: 'set up now',
    addJournal: 'Add journal',
    clickToGenerate: 'Click to generate',
    addNewAuxiliaryType: 'Add new auxiliary type',
    createNew: 'Create new',
    addTo: 'Add to',
    addToCounterparty: 'Add to',
    createNewCounterparty: 'Create new',
    deleteCounterparty: 'Delete Counterparty',
    changeToManualAssignment: 'Change to manual assignment',
    changeToAutomateAssignment: 'Change to automate assignment',
    selectInventory: 'Select Inventory',
    change: 'Change',
    startMatching: 'Start Matching',
    exportMatchSet: 'Export match set',
    import: 'Import',
    clickDownloadAndReEdit: 'Click Download and re-edit',
    refreshBalance: 'Refresh Balance',
    addLine: 'Add line',
    auxiliary: 'Auxiliary',
    CSVUpload: 'CSV Upload',
    openAPIIntegration: 'OpenAPI Integration',
    APIDocument: 'API Document',
    execute: 'Execute',
    changeCounterparty: 'Change Counterparty',
    exportTreasuryAccount: 'Export',
    importAccount: 'Import',
    importCounterparty: 'Import',
    addChart: 'Add Chart',
    editChart: 'Edit Chart',
    later: 'Later',
    Execute: 'Execute',
    autoCalculate: 'Auto-calculate',
    addAccountsInTreasury: 'Add accounts in treasury',
    generateReportsInReports: 'Generate reports in Reports',
    exportFromElven: 'Export from Elven',
    importToElven: 'Import to Elven',
    contractSales: 'Contract sales',
    continueWithBasic: 'Continue with Basic',
    continueWithStandard: 'Continue with Standard',
    subscribe: 'Subscribe',
    continueCreating: 'Continue creating',
    cancelProject: 'Cancel project',
    upgradePlan: 'Upgrade plan',
    retryPayment: 'Retry Payment',
    addTask: 'Add Task',
    createReconciliationAdjustmentJournal: 'Create Reconciliation Adjustment Journal',
    removeReconciliationAdjustmentJournal: 'Remove Reconciliation Adjustment Journal',
    clearFormula: 'Clear formula',
    download: 'download'
  },
  message: {
    sortSuccess: 'Sort success',
    sendEmail: 'Send notices to Email',
    enterAlertName: 'Enter your alert name',
    confirmEmailLogin: 'Please confirm your email to Log in.',
    loginTitle: 'Welcome to ', // 登录弹框title
    loginEmailPlaceholder: 'Please input the email address correctly', // 登录弹框邮箱提示 和 校验提醒
    loginEmailError: 'Please enter the correct email address.', // 校验提醒
    captchaPlaceholder: 'Enter verification code', // 登录弹框验证码提示和校验提醒
    captchaWarning: 'Please enter verification code.', // 登录弹框验证码提示和校验提醒
    captchaError: 'The verification code you entered is incorrect, please try again',
    emailError: 'Email or password is wrong, please try again',
    pleaseInput: 'Please input',
    pleaseInputEmail: 'Please input email',
    pleaseInputName: 'Please enter your name',
    passwordError: 'Minimum 12 characters with numbers, upper/lowercase letters, and symbols',
    pleaseInputPassword: 'Please input password',
    PleaseInputCode: 'Please input code',
    saveSuccess: 'Success',
    copyFailed: 'Copy failed',
    copySuccessfully: 'Copy successfully',
    signInSuccess: 'Sign in success',
    signUpSuccess: 'Sign up success',
    exportSuccess: 'Export Success',
    onlyScreeners: 'You can only create 10 screeners!',
    saveChangeGroup: 'You have changed the group view, save this change?',
    pleaseEnterFilter: 'Please enter a value for filtering.',
    pleaseSelectFilter: 'Please select a metric for filtering.',
    rangeRequirements: "Range doesn't meet the requirements.",
    alertEmpty: 'Alert history is empty.',
    createdAlert: 'You haven’t created any alert.',
    areYouSure: 'Are you sure?',
    saveChangeAlert: 'You have changed the Alert conditions, save this change?',
    reportOnlySee100: 'Only the latest 100 items are displayed, if you need to see all the data, please',
    reportOnlySee20: 'Only the latest 20 items in each account are displayed, if you need to see all the data, please',
    alertUpdatedSuccuss: 'Alert updated succussfully!',
    alertCreatedSuccuss: 'Alert created succussfully!',
    fillAllBlanks: 'Please fill in all the blanks!',
    renameFailed: 'Rename Failed',
    pleaseEnterSourceName: 'Please enter the name of this source',
    pleaseEnterIntegrationName: 'Please enter the name of this integration',
    pleaseEnterAccountName: 'Please enter the account name',
    pleaseEnterAccountNo: 'Please enter the account number',
    pleaseEnterNameOfAccount: 'Please enter the name of this account',
    pleaseEnterTheNameOfThisMatchSet: 'Please enter the name of this match set',
    enterSourceName: 'The entered Source Name is inconsistent',
    enterTheNameOfThisMatchSet: 'The entered Name of this match set is inconsistent',
    enterAccountName: 'The entered Account Name is inconsistent',
    noPermission: 'You currently do not have this permission.',
    pleaseEnterContactName: 'Please enter the contact name.',
    pleaseSelectContactType: 'Please select a contact type, currently unset.',
    exportLimitMessage: 'The export limit is 1000 items per export, please use the filter to censor and export',
    editSuccess: 'Edit Success',
    deleteSuccess: 'Delete Success',
    regenerateFailed: 'Regenerate Failed, please check your transaction data',
    reportError: 'Report has been regenerated, but there are some errors, please check your data',
    transactionCategorized: 'Transaction categorized',
    transactionIgnored: 'Transaction ignored',
    transactionKeepAll: 'Transaction keep all',
    ruleEnabled: 'Rule is enabled',
    ruleDisabled: 'Rule is disabled',
    priorityChanged: 'Priority changed',
    ruleSavedSuccessfully: 'Rule saved successfully',
    pleaseWaitRules: 'Please wait for the end of the rules execution',
    executeRuleMessage: 'Do you want to execute all rules manually for all transaction data?',
    executeRuleBusinessDataMessage: 'Do you want to execute all rules manually for all business data?',
    executeRuleCostBasisMessage: 'Do you want to execute all rules manually for all cost basis?',
    deleteRuleMessage: 'Delete rule “{name}” ?',
    deleteChartMessage: 'Are you sure you want to delete “{name}” ?',
    addElevenConditions: 'Add up to eleven conditions',
    addCountConditions: 'Add up to {count} conditions',
    deleteMemberMessage: 'Delete member “{email}” ?',
    deleteEntityMessage: 'Delete entity “{name}” ?',
    cancelSuccess: 'Cancel Success',
    submitSuccess: 'Submit Success',
    uploadSuccess: 'Upload Success',
    uploadError: 'Upload Failed, please try again',
    createProjectSuccess: 'Create Project Success',
    addEntitySuccess: 'Add Entity Success',
    agreedTermsOfService:
      'The first login will automatically create an account and means you have read and agreed to the ',
    LoginAgreedTermsOfService: 'Your login and registration action means you have read and agreed to the ',
    pleaseEnterVerificationCode: 'Please enter the verification code that has been sent to {email}',
    resendAfter: 'Resend after {tmp} s',
    loginInfoTitle: 'Professional crypto  accounting software',
    loginInfoDesc: 'One-stop solution for your crypto accounting and reporting',
    upgradePro: 'Upgrade to Pro',
    needMoreEntities: 'Need more entities? ',
    needMoreMembers: 'Need more members? ',
    reviewSuccess: 'Review Success',
    someTransactionsReviewedInfo: 'Some transactions can not be reviewed',
    maximumTransactionsReviewedInfo: 'The maximum number of transactions for each batch operation is {total}',
    reviewedSuccessfullyTotal: '{total} transactions have been reviewed successfully',
    restReviewTotal: '{total} transactions require manual specification of events',
    subscriptionExpired: 'Your subscription has expired. Please purchase again.',
    subscriptionLimitError: 'Your usage has exceeded the limit. Please upgrade your plan.',
    syncIntegrationSuccess: 'entries has been synchronized to {platform}',
    disconnectWithPlatformInfo:
      'We will clear the corresponding relationship with {platform}. When you want to sync again, you need to re-associate.',
    authorizationFailed: 'Authorization failed',
    syncIntegrationInfo:
      'Journal entries are being generated, please wait for the generation to complete before syncing again.',
    connectedSuccessfully: 'Successfully connected to {platform}',
    successfullyDisconnected: 'Successfully disconnected with {platform}',
    accountMappingMessage: 'Account mappings will show here after synchronization is completed.',
    success: 'Success',
    resendInvitationEmailSuccess: 'Resend invitation email successfully',
    sourceImportedSuccess:
      'You have successfully imported {total} transactions, and it appears that {total} of them require review.',
    someLinesError: 'Some lines in this file are not recognized because of formatting errors, missing content, etc.',
    counterpartySomeLinesError: 'Partial data is incorrect and cannot be uploaded.',
    importCSVErrorInfo: "Couldn't find any transaction matching {account}. No transactions to be imported.",
    transactionsWillBeImported: 'Transactions will be imported',
    transactionsWillBeImportedAccount: 'Transactions will be imported to {account}',
    uploadCSVWarningInfo:
      'Only transactions that match the selected Account will be imported, and other unrelated transactions will be discarded.',
    deleteAttachmentInfo: 'Are you sure you want to delete this attachment of {fileName}?',
    sourceSyncTimeoutInfo: "Your address may require a longer sync time. We'll notify you once it's complete.",
    balanceVerificationPassed: 'Balance verification passed',
    balanceVerificationFailed: 'Balance verification failed',
    balanceNotVerified: 'Balance not verified',
    BalanceVerificationCompleted: 'Balance verification completed',
    BalanceVerificationStarted: 'Balance verification started',
    refreshBalanceStarted: 'Refresh balance started',
    accountBalanceEmptyInfo: 'There are no assets under this account',
    deleteBalanceInfo: 'Will delete this balance record, this operation cannot be recovered.',
    deleteBusinessEventInfo: 'Are you sure you want to delete business {event} ?',
    deleteBusinessEventJournalInfo: 'Are you sure you want to delete journal {journal}?',
    deletedSourceInfo: 'Will delete all transactions form this source, this operation cannot be recovered.',
    deletedJournalSourceInfo:
      'Will delete all journals imported from this data source, this operation cannot be recovered.',
    deletedIntegrationBusinessDataInfo:
      'Will delete all business data from this integration, this operation cannot be recovered. All valuation raw data generated based on this data will also be deleted.',
    deletedBusinessDataSourceInfo:
      'Will delete all business data from this data source, this operation cannot be recovered. All valuation raw data generated based on this data will also be deleted.',
    deletedBusinessDataSourceWithJournalInfo: 'Delete all journals created by this data source',
    createJournalSuccess: 'Create journal success',
    emptyChartOfCountInfo: 'Select currency and account to define entry',
    manuallyEnterFunctionalCurrencyAmountTip: 'Manually enter functional currency amount',
    recalculateCostBasisInfo: 'The journal data has been updated, it is recommended to recalculate the Cost Basis',
    recalculateCostBasisSuccess: 'Cost basis has been successfully recalculated',
    recalculateCostBasisStart: 'Cost basis starts to recalculate',
    postToLedgerStart: 'Post to ledger starts',
    postToLedgerInfo: 'The journal data has been updated, it is recommended to post to the ledger',
    regenerateReportsTips: 'The journal data has been updated, it is recommended to regenerate reports',
    sourceSynchronizationIsCompleted: 'Source synchronization is completed',
    TransactionInProgress: 'The source is being synchronized. Please try again after the synchronization ends!',
    sourceInExecuting: 'Please wait for the data source to finish processing before executing.',
    treasuryAccountTips: 'Non-specific account, determined by the currency and auxiliary code',
    createJournalFailed: 'Create journal failed',
    transactionDeletedInfo: 'The related transaction has been deleted',
    addEthereumWalletInfo: 'You may need to manually add the balance of the starting time.',
    addAccountCSVSourceInfo: 'Upload the CSV file after you have finished creating account',
    deleteJournalInfo: 'Will delete journal {journalNo}, this operation cannot be recovered.',
    batchDeleteJournalInfo: 'Are you sure you want to delete {total} journals?',
    deleteJournalSuccess: 'Delete journal success',
    noIntegrationsAdded: 'No integrations added',
    integrationDataNameInfo: 'The data name "{dataName}" has already been used.',
    dataNameEmptyInfo: 'Please enter the data name',
    noAccountFound: 'No account found',
    isItACashAccount: 'Is it a cash account?',
    enableOriginalCurrencyAccounting: 'Enable original currency accounting?',
    journalTypesAssociatedWithThisAccount: 'Journal types associated with this account',
    journalsAssociatedWithThisAccount: 'Journals associated with this account',
    automationsAssociatedWithThisAccount: 'Automations associated with this account',
    treasuryAccountsAssociatedWithThisAccount: 'Treasury accounts associated with this account',
    deletedChartOfCountInfo: 'Will delete account {account}, this operation cannot be recovered.',
    pleaseEnterChartOfAccountName: 'Please enter the name of this account',
    pleaseEnterJournalTypeName: 'Please enter the name of this journal type',
    unableDeleteChartOfAccountInfo:
      'Unable to delete "{account}". The following relationships exist, preventing the deletion:',
    unableToDeleteJournalTypeOfAutomation:
      'Unable to delete Journal Type. Please remove associations with any existing Journals or Automation Rules first.',
    unableToDeleteJournalTypeOfJournal:
      'The journal type "{journalType}" is being used by other data, you cannot disable this journal type until these data are deleted.',
    enterChartOfAccountName: 'The entered Account Name is inconsistent',
    deletedJournalTypeInfo: 'Will delete journal type {journalType}, this operation cannot be recovered.',
    editJournalTypeOfJournalInfo: 'This journal type is being used by other data, you cannot edit entry lines',
    editJournalTypeOfAutomationInfo: 'This journal type is for automated processing; entries cannot be edited.',
    businessEventsEmpty: "You haven't defined any business events yet,",
    businessEventsEmptyRegenerate: 'No business event card has been created yet,',
    regeneratingBusinessEvents: 'Regenerating business events',
    deleteAuxiliaryCodeTypeInfo: 'Are you sure you want to delete {type} {name} ?',
    deleteCounterpartyInfo: 'Are you sure you want to delete counterparty {name}?',
    batchDeleteCounterpartyInfo: 'Are you sure you want to delete {total} counterparties ?',
    counterpartyFeeInfo:
      'System-created Counterparty, used to mark the fees needed to be paid for {platformType} transactions.',
    changeToManualAssignmentInfo:
      'After switching to manual assignment, the inventory corresponding to this sale will no longer be affected by rules. You can manually specify the inventory.',
    changeToAutomateAssignmentInfo:
      'After switching to automate assignment, matched Inventory will be removed and rules need to be manually executed for re-matching.',
    exportDataInfo: 'We will prepare your file in the background and send the download link to your email later.',
    pleaseRecalculateTheCost: 'Data has changed, please recalculate the cost.',
    deleteIdentificationCodeInfo: 'Are you sure you want to delete identification code {name} ?',
    deleteContactInfo: 'Are you sure you want to delete {contact}?',
    cannotDeleteSource: 'You cannot delete this type source',
    startRefresh: 'Start Refreshing',
    copiedToClipboard: 'Copied to clipboard',
    brokerInventoryErrorInfo:
      'The entry lines do not comply with the broker inventory rules, as at least one of the debit or credit accounts is classified as an asset or liability account in the original currency.',
    deleteMatchSetInfo: 'Match Set {desc} will be deleted, including all rules and matches of this match set.',
    deleteMatchRuleInfo: 'All match records generated by this rule will be deleted permanently and cannot be undone.',
    deleteAccountUnavailableInfo: 'The account includes transactions marked as matched in the Reconciliation.',
    deleteAccountRecoverTipInfo: 'Will delete this account, this operation can not be recovered.',
    deleteAccountSourceTipInfo: 'All data sources ,transactions and balance records of this account will be deleted.',
    deleteAccountJournalTipInfo:
      'Journals associated with this account will be retained. You can filter by account to delete them.',
    addViaCOntractAddress: 'Add via contract address',
    addAssetSuccessfully: 'Asset added successfully',
    editAssetSuccessfully: 'Asset edit successfully',
    noContractAddress: 'No contract address under this assets.',
    noRawDataEndingInfo:
      'No records of unrealized P&L that can be considered as ending record in this adjustment period.',
    noRawDataRealizedInfo: 'No realized P&L records in this adjustment period.',
    adjustmentFrequencyInfo:
      'Modifying the adjustment frequency triggers the system to recalculate the change in unrealized gain/loss.',
    assetSelectionInfo: 'After changing asset options, please click the recalculate button to apply the changes.',
    deleteBusinessDataTransformerInfo:
      'Deleting this rule automatically removes all raw data generated based on this rule.',
    conditionsHaveChangedInfo:
      'When this rule is executed, if the business data fails to meet the new conditions, the system will automatically delete the raw data generated based on this rule.',
    importBalanceMultipleTipInfo:
      'The balances will be automatically split into multiple sources based on the account, and saved under the corresponding accounts.',
    importBalanceMultipleNameInfo: 'The source name will be automatically created as “{format}”.',
    importBalanceMultipleNameFormat: 'account name-balance-time',
    executeIdentifyCounterpartyRulesInfo: `Counterparty information has been saved.<br/>
  Do you want to run the automation rules now to update the counterparty information for transactions?`,
    switchToAutoCalculateAmountFC: 'Switch to auto-calculate functional currency amount',
    sorted: 'Sorted',
    createProjectSuccessTipInfo:
      'You’ve successfully created a new project. To activate your project, please check your email and click on the activation link.',
    receiveEmailTipInfo: 'Didn’t receive the email?',
    resendEmailTipInfo: 'Make sure to check your spam or junk folder. You can also resend the activation email',
    resendEmailLinkInfo: 'resend email',
    freePlanReachedTipInfo:
      'You have reached the maximum limit for free projects.\nTo create a premium project, please contact our sales team.',
    agreeServicesAgreement: "I agree to Elven's {agreement} .",
    createProjectTermsOfService:
      'By using our Service, you agree that your data may be stored securely on our web service provider (AWS), used in accordance with our {policy}, and may be utilized for marketing purposes.',
    createFreeProjectTipInfo:
      'Fill out the form to create your project. Once approved, it will be available for free use.',
    enterWebsiteUrlWarningInfo: 'Please enter a URL starting with http:// or https://',
    freeProjectReviewFailedTipInfo:
      '{reminder}: Please update your project information by {time} to avoid temporary suspension. {updateNow}',
    freeProjectReviewSuccessTipInfo:
      'Your project information has been updated and is now under review. You will receive the results via email.',
    emailSendSuccessTipInfo: 'Email sent successfully',
    xeroTemplateCSVInfo: 'Please export using the default Xero template and save as CSV.',
    monthlyPayment: 'Monthly payment',
    customizedPlanMsg: 'Maximize performance and productivity at every level of your enterprise',
    chooseYourSubscriptionPlan: 'Choose your subscription plan',
    welcomeOfferForBeginners: 'Welcome offer for beginners',
    addPaymentMethodTip: 'To complete the process and start enjoying our services, please add a payment method.',
    wantToCancelProject: 'Are you sure you want to cancel this project? ',
    exceededUsageLimitTip: 'Your project has exceeded the data usage limit',
    exceededTransactionLimitTip:
      'When you exceed your transactions limit, you incur additional charges of $50/mo per additional 10,000 transactions',
    billingHistoryMonths: 'Showing invoices within the past 12 months',
    projectUnSubscribedBanner: 'Your subscription was canceled, it will be deactivated after {time}.   {handler}',
    projectPaymentFailedBanner:
      'Your auto-payment failed. Please complete the payment by {time} to avoid your project being suspended.  {handler}',
    projectUpgradePlanBanner:
      'Your project has exceeded the data usage limit. Some features will be restricted.  {handler}',
    historicalTransactionsTip:
      'Historical transaction count includes two parts:\n1. Transactions imported and parsed from accounts (on-chain wallets, exchanges, banks, custodians, payment platforms), standardized by elven, may differ from the original transaction count \n2. Business data imported through system integration',
    upgradeStandardPlan:
      'Are you sure you want to upgrade to the Standard Plan?\nBy confirming, your next billing cycle will be charged at the Standard Plan rate.',
    unSubscriptionTip:
      'Are you sure you want to cancel your subscription? Your project subscription will remain active until {time}. \nAfter this date, your project will be put on hold.',
    reSubscriptionTip:
      'Would you like to resubscribe your project? Your current plan will be extended, and you’ll receive a monthly bill with the corresponding charges.',
    reSubscriptionPaymentTip:
      'Would you like to resubscribe your project? Upon resubscribing, you’ll immediately pay the current bill, and future bills will be generated and charged on a monthly basis.',
    limitReachedTip:
      'You have reached your transaction limit and can no longer upload new data. \n To continue, please upgrade to a higher version.',
    limitReachedAccountTip:
      'Your transaction limit has been reached. You cannot add new accounts at this time. \n To continue, please upgrade to a higher version.',
    limitReachedCategorizeTip:
      'You have reached your transaction limit and cannot categorize \n transactions at this time.\n To continue, please upgrade to a higher version.',
    subscriptionSuccessTip: 'Project subscription successful',
    applyFreeProjectTipInfo:
      'Please complete the following form in full. Once we receive your application, we will review it as soon as possible and grant you free access.',
    submissionSuccessful: 'Submission successful',
    submissionSuccessfulInfo:
      'Thank you for your application. we will review it shortly and notify you of the results via email.',
    activationSuccessfully: 'Activation successfully',
    editTaskSourceDataTipInfo:
      'If changing the conditions results in a reduction of some data, the match records and explain records related to this deleted  data will be deleted.',
    noRawDataValidatorEndingInfo: 'No records of Balance that can be considered as ending record in this period',
    noRawDataValidatorWithdrawInfo: 'No withdraw record in this period',
    noRawDataValidatorDepositInfo: 'No deposit record in this period',
    viewDetailedRecords: 'View detailed records',
    changeCheckPointTipTitleMessage:
      'You are about to switch the checkpoint. This action will result in the following changes',
    changeCheckPointTipMessageOne:
      'All  adjustment data in the valuation module will be recalculated based on the new checkpoint.',
    changeCheckPointTipMessageTwo: 'Once recalculated, the system will automatically post to the ledger.',
    changeCheckPointTipMessageThree: 'After posting, the reports will be regenerated.',
    changeCheckPointHelpMessage:
      'You can switch the checkpoint in the reports module. All adjustment data in the valuation module,\n as well as reports, will be generated based on the selected checkpoint.',
    changeCheckPointSelectMessage:
      'Adjustments are unavailable if any task is in progress: \nrecalculating valuation, applying valuation rules, updating \nledger, or refreshing reports.'
  },
  input: {
    nameLabel: 'Your Name',
    passwordsLabel: 'Password (12-20 characters)',
    setPasswordsLabel: 'Set a password (12-20 characters)',
    enterAuthenticatorCode: 'Enter the 6-digit verification code generated by your authenticator app.'
  },
  label: {
    addressOrXpubRrecommend: 'Address / Xpub (Recommend)',
    addresses: 'Addresses',
    inputs: 'Inputs'
  },
  placeholder: {
    enterEmail: 'Enter your email address',
    enterVerificationCode: 'Enter your verification code',
    enterNumber: 'Enter your number',
    enterPassword: 'Enter your password',
    enterName: 'Enter your name',
    passwordCharacters: '8-16 characters',
    min: 'min',
    max: 'max',
    descriptionAccount: 'Description for this account',
    inputTag: 'Input Tags ...',
    inputCurrencyCode: 'Input currency code',
    txHashOrOrderId: 'TxHash, Order ID ...',
    selectAccount: 'Select account ...',
    pleaseDataName: 'Please enter the data name',
    dataNameEmptyInfo: 'Please enter the data name',
    enterNameForSearch: 'Enter name for search ...',
    search: 'Search...',
    inputAssetName: 'Input asset name',
    inputSymbolCode: 'Input symbol or code',
    inputContractAddress: 'Input contract address',
    inputChartName: 'Input chart name',
    legalNameOfCompany: 'legal name of your company',
    beginWithWebsites: 'Begin with http:// or https://',
    firstName: 'First name',
    lastName: 'Last name',
    jobTitle: 'Job title',
    workEmailTip: 'Please enter a business email address',
    useWorkEmailApply: 'Please use your work email to apply',
    referralTip: 'how did you hear about Elven?'
  },
  automation: {
    forNewTransfers: 'For new transfers:',
    forNewTransfersInfo: 'Only the highest priority matched rule will be executed for each transfer.',
    forExistingTransfers: 'For existing transfers:',
    forEewTradeRecords: 'For new trade records:',
    forExistingTradeRecords: 'For existing trade records:',
    forNewGainLossRecords: 'For new Gain/Loss records:',
    forExistingGainLossRecords: 'For existing Gain/Loss records:',
    forNewBusinessData: 'For new business data:',
    forNewBusinessDataInfo: 'This rule will be executed for matched records.',
    forExistingBusinessData: 'For existing business data:',
    forAllTransfers: 'For all transfers:',
    forNewRecords: 'For new records:',
    forExistingRecords: 'For existing records:',
    forEewRecordsInfo: 'All matched rules will be executed for each record.',
    existingRecordsInfoForAdjustments:
      'If the originally matched rule has no adjustments, the previously created journal remains unchanged.',
    existingRecordsInfoForAction:
      'If the originally matched rule changes the action, the previously created journal will be updated.',
    existingRecordsInfoNotLongerMatched:
      'If the originally matched rule is no longer matched, the previously created journal remains unchanged.',
    existingRecordsInfoIsMatched:
      'If a new rule is matched, a new journal will be created and the previously created journal remains unchanged.',
    existingRecordsInfoAreMatched: 'If new rules are matched, new journals will be created.',
    ruleHasNoAdjustmentsInfo: 'If this rule has no adjustments, the previously created journal remains unchanged.',
    ruleHasNoAdjustmentsValuationInfo:
      'If this rule has no adjustments, the previously created valuation data remains unchanged.',
    ruleChangesTheActionInfo: 'If this rule changes the action, the previously created journal will be updated.',
    ruleChangesTheActionValuationInfo:
      'If this rule  changes the action, the previously created valuation data will be updated.',
    ruleChangesTheConditionInfo: 'If this rule changes the condition:',
    previouslyMatchedRecords:
      'Previously matched records that no longer match will leave the existing journal unchanged.',
    previouslyMatchedValuationRecords:
      'Previously matched records that no longer match will leave the existing valuation data unchanged.',
    newlyMatchedRecordsInfo: 'Newly matched records will result in new journals being created.',
    newlyMatchedRecordsValuationInfo: 'Newly matched records will result in new valuation data being created.',
    existingRecordsInfoForValuationAdjustments:
      'If the originally matched rule has no adjustments, the previously created valuation data remains unchanged.',
    existingRecordsInfoForValuationAction:
      'If the originally matched rule changes the action, the previously created valuation data will be updated.',
    existingRecordsInfoNotLongerMatchedValuation:
      'If the originally matched rule is no longer matched, the previously created valuation data remains unchanged.',
    existingRecordsInfoAreMatchedValuation: 'If new rules are matched, new valuation data  will be created.',
    manuallySetCounterpartiesInfo: 'Manually set counterparties for transfers will not be modified by rules.',
    priorityMatchedRuleForcounterpartyInfo: 'Only the highest priority matched rule will be executed for each transfer.'
  },
  country: {
    HongKong: 'Hong Kong',
    Japan: 'Japan',
    Philippines: 'Philippines',
    Singapore: 'Singapore',
    SouthKorea: 'South Korea',
    UnitedKingdom: 'United Kingdom',
    UnitedStates: 'United States'
  },
  bank: {
    星展银行: 'DBS Bank',
    汇丰银行: 'HSBC',
    中银香港: 'BOA HK',
    花旗银行: 'Citibank',
    东亚银行: 'Bank of East Asia',
    华侨银行: 'OCBC Bank',
    工银亚洲: 'ICBC (Asia)',
    德意志银行: 'Deutsche Bank',
    渣打银行: 'Standard Chartered',
    恒生银行: 'Hang Seng Bank',
    富国银行: 'Wells Fargo',
    美国银行: 'Bank of America',
    大华银行: 'UOB Bank',
    瑞士银行: 'UBS',
    巴克莱银行: 'Barclays Bank',
    摩根大通: 'J.P.Morgan',
    招商银行: 'CMB',
    交通银行: 'BOCOM',
    中国银行: 'BOC',
    农业银行: 'ABC',
    建设银行: 'CCB',
    邮政储蓄银行: 'PSBC',
    工商银行: 'ICBC',
    MCB: 'MCB',
    Signet: 'Signet',
    'BCB Blinc': 'BCB Blinc',
    BCB: 'BCB',
    'Customer Bank': 'Customer Bank',
    Signature: 'Signature',
    其他银行: 'Other Bank'
  },
  project: {
    nav: {
      financials: 'Financials',
      fundReports: 'Fund Report',
      metrics: 'Metrics',
      charts: 'Charts',
      chartOfCount: 'Chart Of Account',
      balanceSheet: 'Balance Sheet',
      incomeStatement: 'Income Statement',
      cashFlowStatement: 'Cash Flow Statement',
      generalLedger: 'General Ledger',
      trialBalance: 'Trial Balance',
      transactions: 'Transactions',
      wallets: 'Wallets',
      portfolioStatement: 'Portfolio Statement',
      totalReturn: 'Total Return',
      changeInNetAssets: 'Change in Net Assets',
      tokenFlowStatement: 'Token Flow Statement',
      sources: 'Sources',
      treasury: 'Treasury',
      accounts: 'Accounts',
      contacts: 'Contacts',
      rules: 'Rules',
      disclosure: 'Disclosure',
      SignificantHoldings: 'Significant Holdings',
      RestrictionsCryptoAssets: 'Restrictions of Crypto Assets',
      Restrictions: 'Restrictions',
      rollForward: 'Crypto Assets Roll Forward',
      RollForward: 'Roll Forward',
      forReview: 'For Review',
      duplicates: 'Duplicates',
      reviewed: 'Reviewed',
      categorized: 'Categorized',
      changeInBalance: 'Change in Balance',
      defiPosition: 'Defi Position',
      totalPosition: 'Total Position'
    },
    transaction: {
      allTransactions: 'All Transactions',
      onChainTransactions: 'On-Chain Transactions',
      bankTransactions: 'Bank Transactions'
    },
    costBasis: {
      inventoryLots: 'Inventory Lots',
      disposals: 'Disposals',
      method: 'Cost Basis Method',
      costMethod: 'Cost Method',
      token: 'Token',
      remain: 'Remain',
      lotNo: 'Lot No.',
      journalNo: 'Journal No.',
      costbasis: 'Costbasis',
      remainQuantity: 'Remain Quantity',
      soldPrice: 'Sold Price',
      gainOrLoss: 'Gain/Loss',
      dateNo: 'Date & No.',
      lots: 'Lots',
      costRule: 'Cost Rule',
      acquisitionPrice: 'Acquisition Price',
      lotDetail: 'Lot Detail',
      lotRemainingQuantity: 'Lot Remaining Quantity',
      long: 'Long',
      short: 'Short'
    },
    dashboard: {
      totalBalance: 'Total Balance',
      fiatBalance: 'Fiat Balance',
      cryptoBalance: 'Crypto Balance',
      totalAssets: 'Total Assets',
      totalLiabilities: 'Total Liabilities',
      totalEquity: 'Total Equity',
      revenue: 'Revenue',
      expense: 'Expense',
      profit: 'Profit',
      last30Days: 'Last 30 days',
      thisMonth: 'This month',
      thisQuarter: 'This quarter',
      thisYear: 'This year',
      metric: 'Metric',
      trends: 'Trends',
      distribution: 'Distribution',
      viewByDay: 'View by Day',
      viewByMonth: 'View by Month',
      viewByQuarter: 'View by Quarter',
      ledgerDistribution: 'Ledger Distribution',
      positiveValues: 'Positive Values',
      negativeValues: 'Negative Values'
    },
    categorize: 'Categorize',
    utcDatetime: 'UTC Datetime',
    reportingDatetime: 'Reporting Datetime',
    originalDatetime: 'Original Datetime',
    standardRatedSupplies: 'Standard-rated supplies',
    zeroRatedSupplies: 'Zero-rated supplies',
    exemptSupplies: 'Exempt supplies',
    taxablePurchases: 'Taxable purchases',
    none: 'None',
    purchase: 'Purchase',
    sale: 'Sale',
    fifo: 'First in, first out (FIFO)',
    costAverage: 'Cost average',
    specificId: 'Specific ID',
    manualAssignedBy: 'Manual Assigned by {name}',
    basicEdition: 'Basic',
    standardEdition: 'Standard',
    customizedEdition: 'Customized Version',
    transactions: 'Transactions',
    view: 'View',
    limitReached: 'Limit Reached',
    upgradeToStandard: 'Upgrade to Standard',
    canceledOn: 'Canceled on {time}',
    dataType: 'Data type',
    editData: 'Edit Data',
    addData: 'Add Data',
    dataName: 'Data Name',
    accountIs: 'Account is',
    typeIs: 'Type is',
    changeInBalance: 'Change in balance'
  },
  desc: {
    configureYourTwoFactorClientInfo:
      'Please scan the QR code above using an OTP compatible app  (such as Google Authenticator or 1Password)',
    configureYourTwoFactorClientManuallyInfo: 'Alternatively, enter the following OTP secret manually:',
    verifyTheConfigurationInfo: 'Enter the 6-digit verification code generated by your authenticator app.',
    createNewBackupCodesInfo:
      'Please note that by continuing, all existing backup codes will become invalid and can no longer be used to access your account. This action cannot be undone.',
    securelyStoreBackupCodesInfo:
      'We will ask you for a backup code in case you lose access to your authenticator app.',
    projectLockedInfo: 'To continue enjoying all the features and benefits, please consider purchasing a subscription.',
    projectDeactivatedInfo: 'If you would like to continue using this project, please contact our sales team.',
    changeBalanceDifference: 'Calculate change in balance and compare differences',
    entityTimeZoneTipInfo:
      'The timestamps of raw data retrieved from third parties will be converted to the chosen timezone for display. accounting entries and report generation will be processed based on your selected timezone.',
    entityGSTSettingTipInfo:
      "If your business is required to comply with Singapore's GST regulations, please enable this feature. ",
    entityAccountingStartDateTipInfo:
      "This is the date you want to start entering transactions from. Once set, you can't add transactions, journals  before this date. if you have transactions before this date, it's necessary to manually input the opening balance.{handler}",
    entityFunctionalCurrencyTipInfo:
      'The functional currency specifies the currency of your primary economic environment.',
    preferredCurrencyUnitTipInfo:
      "Set default currency display format. choose different default units based on your entity's data volume.",
    learnChooseAccountingDate: 'learn more about choose accounting start date'
  },
  bubbleGuide: {
    forReview: {
      step1:
        'For Review: you will first need to review your transactions here. <br/> Duplicates: you need to check for duplicates here. <br/>Categorized: reviewed transactions.',
      step2: 'Complete review of a single transaction by clicking the checkmark button',
      step3: 'Batch review button, you can review the filtered transactions together'
    },
    duplicates: {
      step1:
        'If you confirm that a transaction is a duplicate, you can set the event for this transaction to ignore by clicking this button',
      step2:
        'If you confirm that none of these transactions are duplicates, you can remove them from the duplicate list by clicking this button'
    },
    balanceSheet: {
      step1: 'Regenerate financial reports by clicking this button'
    },
    journalToLedger: {
      step1: 'journals list has been moved to the ledger module'
    }
  },
  log: {
    addSource: 'Add source "{sourceName}"',
    editSource: 'Edit source "{sourceName}"',
    deleteSource: 'Delete source "{sourceName}"',
    addBusinessData: 'Add business data: "{name}"',
    addBusinessDataSource: 'Add business data source "{name}"',
    deleteBusinessDataSource: 'Delete business data source "{name}"',
    addJournal: 'Create journal: {journalTypeName}({journalNo})',
    editJournal: 'Edit journal detail: {journalTypeName}({journalNo})',
    deleteJournal: 'Delete journal: {journalTypeName}({journalNo})',
    recalculateCostBasis: 'Recalculate cost basis',
    uploadCsv: "Upload CSV '{fileName}'",
    categorizeTransaction: 'Categorize transaction "{txId}" as {category}',
    batchCategorizeTransaction: 'Categorize {total} transactions "{txIds}" as {category}',
    exportTransaction: 'Export {total} transactions',
    regenerateReports: 'Regenerate reports',
    addContact: "Add counterparty '{contactName}'",
    batchAddContact: "Add {total} counterparties '{contactNames}'",
    editContact: "Edit counterparty '{contactName}'",
    deleteContact: "Delete counterparty '{contactName}'",
    batchDeleteContact: "Delete {total} counterparties '{contactNames}'",
    addRule: "Add rule '{ruleName}'",
    editRule: "Edit rule '{ruleName}'",
    deleteRule: "Delete rule '{ruleName}'",
    manuallyExecuteRules: 'Manually execute all "{action}" rules',
    manuallyExecuteBusinessDataRule: 'Manually execute business data rules "{ruleName}"',
    addAccount: 'Add account "{accountName}"',
    editAccount: 'Edit account "{accountName}"',
    deleteAccount: 'Delete account "{accountName}"',
    batchDeleteAccount: 'Delete {total} accounts "{accountNames}"',
    addTreasuryBalanceCheckpoint: 'Add balance for account "{accountName}": {balance} {currency}, {datetime}',
    deleteTreasuryBalanceCheckpoint: 'Delete the balance record of the account "{accountName}" at {datetime}',
    verifyTreasuryBalance: 'Performed balance verify',
    exportTreasuryBalance: 'Export treasury report',
    addChartOfAccount: 'Add account "{name}"',
    editChartOfAccount: 'Edit account "{name}"',
    deleteChartOfAccount: 'Delete account "{name}"',
    addJournalType: 'Add journal type "{name}"',
    editJournalType: 'Edit journal type "{name}"',
    deleteJournalType: 'Delete journal type "{name}"',
    addEntity: 'Create entity "{name}"',
    editEntity: 'Edit entity "{name}"',
    deleteEntity: 'Delete entity "{name}"',
    inviteMember: 'Invite member "{name}"  to join the project',
    editMemberPermission: 'Edit the member permissions of "{name}"',
    removeMember: 'Remove member "{name}"',
    addChartOfCount: 'Add account "{name}"',
    editChartOfCount: 'Edit account "{name}"',
    deleteChartOfCount: 'Delete account "{name}"',
    addNewBusinessEvent: 'Add new business event "{name}"',
    editBusinessEvent: 'Edit business event "{name}"',
    deleteBusinessEvent: 'Delete business event "{name}"',
    regeneratedBusinessEventsList: 'Regenerated business events list',
    addAuxiliaryType: 'Add auxiliary code "{name}"',
    editAuxiliaryType: 'Edit auxiliary code "{name}"',
    deleteAuxiliaryType: 'Delete auxiliary code "{name}"',
    addReconciliationMatchSet: 'Created a match set "{name}"',
    deleteReconciliationMatchSet: 'Deleted match set "{name}"',
    editReconciliationMatchSetDetail: 'Modified details for match set "{name}"',
    editReconciliationMatchSetFilter: 'Modified filters for match set "{name}"',
    addReconciliationMatchSetRule: 'Added a rule "{ruleName}" to the match set "{setNo}"',
    editReconciliationMatchSetRule: 'Modified the rule "{ruleName}" in the match set "{setNo}"',
    executeReconciliationMatchSet: 'Performed automatic reconciliation in the match set "{name}"',
    deleteReconciliationMatchRecord: 'Deleted a match record "{name}"',
    addReconciliationMatchRecord: 'Manually added a match record "{name}"'
  },
  menus: {
    'My Projects': 'My Projects',
    sources: 'Sources',
    Reports: 'Reports',
    reports: 'Reports',
    transactions: 'Transactions',
    ledger: 'Ledger',
    contacts: 'Contacts',
    automation: 'Automation',
    settings: 'Settings',
    Fundamentals: 'Fundamentals',
    'Money Flows': 'Money Flows',
    Social: 'Social',
    alert: 'Alert',
    rules: 'Rules',
    history: 'History',
    market: 'Market',
    marketMap: 'Market Map',
    emergingSpaces: 'Emerging Spaces',
    heatmap: 'Heatmap',
    screeners: 'My Screener',
    valuation: 'Valuation',
    dashboard: 'Dashboard'
  },
  progressStatus: {
    syncingSources: 'Syncing Sources',
    deletingSources: 'Deleting Sources',
    executingRules: 'Applying Rules',
    recalculatingCostBasis: 'Conducting Adjustment Calculations',
    regeneratingReports: 'Refreshing Reports',
    regeneratingBusinessEvents: 'Updating Business Events',
    performingAutomaticReconciliation: 'Auto-reconciling',
    postingToLedger: 'Updating Ledger',
    refreshBalance: 'Updating Balance',
    deletingAccount: 'Deleting Accounts'
  },
  progressStatusToast: {
    syncingSourcesSuccess: 'Sources have been synced successfully',
    syncingSourcesError: 'Sources have been synced with errors',
    deletingSourcesSuccess: 'Sources have been deleted successfully',
    deletingSourcesError: 'Sources have been deleted with errors',
    importingAccountSuccess: 'Account has been imported successfully',
    importingAccountError: 'Account has been imported with errors',
    transferAutomatingSuccess: 'Transfer automation has been executed successfully',
    transferAutomatingError: 'Transaction automation has been executed with errors',
    tradeAutomatingSuccess: 'Trade automating has been executed successfully',
    tradeAutomatingError: 'Trade automating has been executed with errors',
    gainLossAutomatingSuccess: 'Gain/Loss automating has been executed successfully',
    gainLossAutomatingError: 'Gain/Loss automating has been executed with errors',
    businessDataAutomatingSuccess: 'Business data automating has been executed successfully',
    businessDataAutomatingError: 'Business data automating has been executed with errors',
    derivativeAdjustmentAutomatingSuccess: 'Adjustment automating has been executed successfully',
    derivativeAdjustmentAutomatingError: 'Adjustment automating has been executed with errors',
    counterpartyAutomatingSuccess: 'Counterparty automating has been executed successfully',
    counterpartyAutomatingError: 'Counterparty automating has been executed with errors',
    postingSuccess: 'Posting has been executed successfully',
    postingError: 'Posting has been executed with errors',
    reportingSuccess: 'Reporting has been executed successfully',
    reportingError: 'Reporting has been executed with errors',
    journalGroupConfigExecuteSuccess: 'Business events have been regenerated',
    journalGroupConfigExecuteError: 'Business events have been regenerated with errors',
    performingAutomaticReconciliationSuccess: 'Automatic reconciliation has been executed successfully',
    performingAutomaticReconciliationError: 'Automatic reconciliation has been executed with errors',
    recalculateCostBasisSuccess: 'Cost basis has been recalculated successfully',
    recalculateCostBasisError: 'Cost basis has been recalculated with errors',
    refreshBalance: 'Refreshing balance',
    refreshBalanceSuccess: 'Balance refreshed successfully',
    refreshBalanceError: 'Balance refresh failed',
    refreshBalanceTimeout: 'Balance refresh failed: Refresh timeout',
    accountDeletingSuccess: 'Account has been deleted successfully',
    accountDeletingError: 'Account deletion failed',
    reconciliationTaskExecutedSuccess: '"{name}" reconciliation rules executed successfully',
    reconciliationTaskExecutedError: '"{name}" reconciliation rule execution failed',
    reconciliationTaskRefreshedSuccess: '"{name}" data refreshed successfully',
    reconciliationTaskRefreshedError: '"{name}" data refresh failed',
    reconciliationTaskDeletedSuccess: 'Reconciliation task has been deleted',
    reconciliationTaskDeletedError: '"{name}" deletion failed',
    exportDataFailed: 'Export Failed',
    exportDataSuccess: 'Export Successful'
  },
  totalPosition: {
    provider: 'Provider',
    category: 'Category',
    asset: 'Asset',
    syncedAmount: 'Synced Amount',
    inUSDT: 'In USDT'
  },
  valuation: {
    selectMethod: 'Select the method for calculating your Cost Basis.',
    fifo: 'First in, first out (FIFO)',
    fifoCarryover: 'First-In, First-Out Carryover',
    average: 'Cost average',
    specificID: 'Specific ID',
    tolerance: 'Tolerance',
    fifoCondition: 'Transfer costs using the FIFO rule.',
    manualAssignment: 'Manual Assignment',
    automateAssignment: 'Automate Assignment',
    summary: 'Summary',
    validatorStaking: 'Validator Staking',
    calculationBeginningTip: 'Use the previous period’s ending balance. If first period, begin at 0.',
    crypto: {
      records: 'Records',
      beginFairValue: 'Fair Value (Beginning)',
      endFairValue: 'Fair Value (Ending)',
      costBasis: 'Cost Basis',
      unrealizedGainLoss: 'Unrealized Gain(Loss)',
      shortTermRealizedGainLoss: 'Short Term Realized Gain(Loss)',
      longTermRealizedGainLoss: 'Long Term Realized Gain(Loss)',
      liabilities: 'Liabilities',
      acquisition: 'Acquisition',
      acquisitionMemo: 'Recording the acquisition quantity and price of crypto assets and liabilities',
      lots: 'Lots',
      lotsMemo: 'Recording the quantity, cost, and usage of lots',

      disposal: 'Disposal',
      disposalMemo: 'The disposal quantity, price of crypto assets and liabilities, and realized gains and losses',

      adjusting: 'Adjusting',
      adjustingMemo: 'unrealized gains and losses due to changes in crypto currency prices',

      fiatAdjustingMemo: 'Fiat exhcange gains and losses due to changes in foreign currency exchange rates',
      purchaseMemo: 'Journals related to the purchase of crypto, including purchase quantity and price.',
      inventory: 'Inventory',
      inventoryMemo: 'Journals related to the sales of crypto, including sales quantity and related inventory.',
      sales: 'Sales',
      salesMemo: 'Get the data you need for your tax report.',
      acquisitionNo: 'Acquisition No.',
      referenceNo: 'Reference No.',
      quantity: 'Quantity',
      acquisitionPrice: 'Acquisition Price',
      lot: 'Lot',
      lotNo: 'Lot No.',
      remainQuantity: 'Remain Quantity',
      averageCostPrice: 'Average Cost Price',
      disposalNo: 'Disposal No.',
      disposalPrice: 'Disposal Price',
      disposalAverageCostPrice: 'Average Cost Price',
      STRGL: 'STRG(L)',
      LTRGL: 'LTRG(L)',
      adjustingId: 'Adjusting Id',
      chartOfAccount: 'Chart Of Account',
      opening: 'Opening',
      closing: 'Closing',
      closingPrice: 'Closing Price',
      journalNo: 'Journal No.',
      fiatExchangeGainLoss: 'Fiat exchange gain(loss)',
      exchangeRateFluctuations: 'Exchange rate fluctuations',
      purchaseNo: 'Purchase No.',
      purchasePrice: 'Purchase Price',
      inventoryNo: 'Inventory No.',
      salesNo: 'Sales No.',
      matchedBy: 'Matched by',
      soldPrice: 'Sold Price',
      grossProfit: 'Gross profit',
      currencyPair: 'Currency Pair'
    },
    fiat: {
      adjusting: 'Adjusting'
    },
    inventory: {
      inventory: 'Inventory',
      sales: 'Sales',
      assetSelection: 'Asset Selection'
    },
    assets: {
      navAssets: 'Assets',
      quotaAsset: 'Quota Asset',
      derivative: 'Derivative',
      cryptocurrency: 'Cryptocurrency',
      stablecoin: 'Stablecoin',
      marginAsset: 'Margin Asset',
      priceProviderSource: 'Price Provider source:',
      nonePriceProvider: 'None of the price providers include this asset',
      assetAlreadyInList: 'The asset {address} is already in your asset list',
      contractAddressAlreadyIn: 'This contract address for asset {address} is already in the asset list',
      contractAddressBelongsIn: 'This contract address belongs to {address}. do you want to add {addresslink}?',
      notIncludeAssetsPrice: 'The selected price provider does not include this assets price'
    },
    price: {
      navPrice: 'Price',
      quote: 'Quote',
      providerName: 'Provider name',
      startDataTime: 'Start data time',
      latestDataTime: 'Latest data time',
      totalPriceProvider: 'Total price provider',
      priceRetrievalMethod: 'Price retrieval method',
      dataSources: 'Data Sources',
      coingeckoAPI: 'Coingecko API',
      exchangeRateAPI: 'Exchange Rate API',
      addWithOutPrice: 'Add without price',
      notCovered: 'Not covered'
    },
    future: {
      unrealizedAndAdjustment: 'Unrealized P & L Adjustment',
      calculation: 'Calculation',
      rawData: 'Raw Data',
      origin: 'Origin',
      symbol: 'Symbol',
      unrealizedAndChange: 'Unrealized P & L Change',
      ending: 'Ending',
      period: 'Period',
      beginning: 'Beginning',
      realizedPAndL: 'Realized P & L',
      journalNo: 'Journal No.',
      PAndL: 'P&L',
      adjustingDate: 'Adjusting Date'
    },
    options: {
      options: 'Options',
      quoteAsset: 'Quote Asset'
    },
    validator: {
      stakingRewards: 'Staking Rewards',
      rewardsChange: 'Rewards Change'
    }
  },
  reconciliation: {
    dataSets: 'Data sets',
    detailsReconciliation: 'Details reconciliation',
    automation: 'Automation',
    matches: 'Matches',
    report: 'Report',
    history: 'History',
    deleteData: 'Delete Data',
    refreshData: 'Refresh Data',
    dataRange: 'Data range',
    editTask: 'Edit task',
    backToTaskList: 'Back to task  list',
    deleteReconciliationTaskDataMsg:
      'You are about to remove this data from the reconciliation task. match records and explain records related to this data will be deleted.',
    removeReconciliationJournalsDataTip: 'Remove Reconciliation Adjustment Journals related to this data',
    pending: 'Pending',
    processed: 'Processed',
    matchesInThisReconciliationTask: 'Matches in this reconciliation task',
    automationTitleInfo: 'Custom rules for automated reconciliation',
    startAutoMatching: 'Start auto-matching',
    treasuryAccountBalance: 'Treasury account Balance',
    deleteReconciliationTask: 'Delete reconciliation task',
    explained: 'Explained',
    explain: 'Explain',
    explainCreateJournal: 'Explain/Create Journal',
    clearExplanation: 'Clear explanation',
    dataNotAdded: 'Data not added',
    transactionId: 'Transaction ID',
    reason: 'Reason',
    differenceReason: 'Difference Reason',
    cannotBeRemoved: 'Cannot be removed',
    manualMatch: 'Manual Match',
    matchedByRule: 'Matched by rule:{ruleName}',
    refreshDataWaringInfo:
      'Refreshing data will cause some data to be deleted.match records and explain records related to this data will be deleted.',
    createReconciliationAdjustmentJournal: 'Create Reconciliation Adjustment Journal',
    reconciliationAdjustmentJournal: 'Reconciliation Adjustment Journal',
    automaticallyCreateAdjustingEntries: 'Automatically create adjusting entries',
    toleranceExplain: 'Tolerance Explain',
    taskDataSetChangeTipInfo: 'The dataset conditions have changed, please click the button to update the data.',
    coa: 'COA',
    reportForThisReconciliationTask: 'Reconciliation report for this reconciliation task',
    childInParent: '{child}  in {parent}',
    perTransaction: 'Per Transaction',
    perLedger: 'Per Ledger',
    createReconciliationTaskTip: 'Created a reconciliation task "{name}"',
    modifiedReconciliationTaskTip: 'Modified details for reconciliation task "{name}"',
    modifiedFiltersReconciliationTaskTip: 'Modified filters for reconciliation task "{name}"',
    deletedReconciliationTaskTip: 'Deleted reconciliation task "{name}"',
    addRuleForReconciliationTaskTip: 'Added a rule "{ruleName}" to the reconciliation task "{name}"',
    modifiedRuleForReconciliationTaskTip: 'Modified the rule "{ruleName}" in the reconciliation task "{name}"',
    automaticRuleExecutionTip: 'Performed automatic reconciliation in the reconciliation task "{name}"',
    deleteMatchRecordTip: 'Deleted a match record "{name}"',
    manuallyAddedMatchRecord: 'Manually added a match record "{name}"',
    addDateSetForReconciliationTaskTip: 'Added the data group "{dataName}" in the reconciliation task "{name}"',
    modifiedDateSetForReconciliationTaskTip: 'Modified the data set "{dataName}" in the reconciliation task "{name}"',
    deleteDateSetForReconciliationTaskTip: 'Deleted the data set "{dataName}" in the reconciliation task "{name}"',
    explainedDateSetForReconciliationTaskTip: 'Explained the data "{dataName}" in the reconciliation task "{name}"',
    clearedExplanationForReconciliationTaskTip:
      'Cleared the explanation of data "{dataName}". in the reconciliation task "{name}"',
    createJournalForReconciliationTaskTip:
      'Created the reconciliation adjusting journal "{journalName}" in the reconciliation task "{name}"',
    deleteJournalForReconciliationTaskTip:
      'Deleted the reconciliation adjusting journal "{journalName}" in the reconciliation task "{name}"',
    differenceExplanation: 'Difference Explanation',
    differenceExplanationInfo:
      'The selected records have a difference of {amount}. Please fill out the form below to provide an explanation for the difference and proceed with the matching.',
    matching: 'Matching',
    loading: 'Loading',
    reconciliationReports: 'Reconciliation reports'
  }
}
