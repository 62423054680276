/* eslint-disable no-unused-vars */
/* eslint-disable no-lonely-if */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
import dayjs from 'dayjs'
import Big from 'big.js'
import millify from 'millify'
import Decimal from 'decimal.js'
import utc from 'dayjs/plugin/utc'
import languages from '@/config/languages'
import timezone from 'dayjs/plugin/timezone'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { sum, isNaN, isEmpty, result } from 'lodash-es'

dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * 格式化时间,传入不同的type，返回不同的类型
 * @param  {value:time,type: 'full', 'base','date','word','wordtwo', 'time','longAgo', 'briefLongAgo','briefTime', interval:'/','-'}
 * @return
 *  full: '2019/01/01 12:12:12'
 *  base: '2019/01/01 12:12'
 *  date: '2019/01/01'
 *  number: '20190101'
 *  time: '12:12'
 *  word: '2019年01月01日 12：12'
 *  wordDate: '2019年01月01日'
 *  wordtwo: '01月01日 12：12'
 *  wordtwoTo: '01-01 12：12'
 *  longAgo: '几分钟前 2019/01/01 12:12'
 *  briefLongAgo: '几分钟前 01-01 12:12'
 *  briefTime:"12:12  01/01 12:12  2019/01/01"
 *  MM-DD: '12-11'
 */
export const dateFormatting = (value: any, type = 'base', interval = '/') => {
  const dateValue = new Date(value)
  const now = new Date().getTime()
  let oldTime
  let data
  const minute = 1000 * 60
  const hour = minute * 60
  const day = hour * 24
  const year = day * 365

  if (typeof value === 'string' && value.indexOf('-') > 0) {
    value = value.replace(/-/g, '/')
    oldTime = new Date(dateValue).getTime()
  } else {
    oldTime = value * 1000
  }

  // 获取详细时间
  const myDate = new Date(oldTime)
  const Y = myDate.getFullYear()
  const M = myDate.getMonth() + 1 < 10 ? `0${myDate.getMonth() + 1}` : myDate.getMonth() + 1
  const D = myDate.getDate() < 10 ? `0${myDate.getDate()}` : myDate.getDate()
  const h = myDate.getHours() < 10 ? `0${myDate.getHours()}` : myDate.getHours()
  const m = myDate.getMinutes() < 10 ? `0${myDate.getMinutes()}` : myDate.getMinutes()
  const s = myDate.getSeconds() < 10 ? `0${myDate.getSeconds()}` : myDate.getSeconds()
  const fullDate = `${Y + interval + M + interval + D} ${h}:${m}:${s}`
  const baseDate = `${M + interval + D} ${h}:${m}`
  const detailDate = `${Y + interval + M + interval + D} ${h}:${m}`
  const numberDate = `${`${Y}${M}${D}`}`
  const briefDetailDate = `${M + interval + D} ${h}:${m}`
  const wordDetailDate = `${Y}年${M}月${D}日 ${h}:${m}`
  const wordDate = `${Y}年${M}月${D}日 `
  const wordDetailDateTwo = `${M}月${D}日 ${h}:${m}`
  const wordtwoToDetailDateTwo = `${M}-${D} ${h}:${m}`
  // 时间差
  // eslint-disable-next-line prefer-const
  const difference = now - oldTime
  const _year = difference / year
  const _day = difference / day
  const _hour = difference / hour
  const _min = difference / minute

  switch (type) {
    case 'full':
      data = fullDate
      break
    case 'baseDate':
      data = baseDate
      break
    case 'base':
      data = detailDate
      break
    case 'number':
      data = numberDate
      break
    case 'word':
      data = wordDetailDate
      break
    case 'wordDate':
      data = wordDate
      break
    case 'wordtwo':
      data = wordDetailDateTwo
      break
    case 'wordtwoTo':
      data = wordtwoToDetailDateTwo
      break

    case 'date':
      data = Y + interval + M + interval + D
      break
    case 'time':
      data = `${h}:${m}`
      break
    case 'longAgo':
      // 超过24小时        显示  年/月/日 时:分
      // 24小时之内        显示  XX小时前
      // 60分钟之内        显示  XX分钟前
      if (_day >= 1) {
        data = detailDate
      } else if (_hour >= 1) {
        data = `${~~_hour} 小时前`
      } else if (_min >= 1) {
        data = `${~~_min} 分钟前`
      } else {
        data = '刚刚'
      }
      break
    case 'briefLongAgo':
      // 超过24小时        显示  月-日 时:分
      // 24小时之内        显示  XX小时前
      // 60分钟之内        显示  XX分钟前
      if (_day >= 1) {
        data = briefDetailDate
      } else if (_hour >= 1) {
        data = `${~~_hour} 小时前`
      } else if (_min >= 1) {
        data = `${~~_min} 分钟前`
      } else {
        data = '刚刚'
      }
      break
    case 'briefTime':
      // 超过一年           显示 年-月-日
      // 超过24小时        显示  月-日 时:分
      // 24小时之内        显示  时:分

      if (_year >= 1) {
        data = Y + interval + M + interval + D
      } else if (_day >= 1) {
        data = briefDetailDate
      } else {
        data = `${h}:${m}`
      }
      break
    case 'MM-DD':
      data = M + interval + D
      break
    case 'MM--DD':
      data = `${M}-${D}`
      break
    case 'DD':
      data = D
      break
    default:
      data = detailDate
      break
  }
  return data
}

export const isScientificNotation = (str: string) => {
  // 使用正则表达式检查字符串是否匹配科学计数法的格式
  return /^[-+]?[0-9]+(\.[0-9]+)?[eE][-+]?[0-9]+$/.test(str.toString())
}

/**
 * 中间用...展示
 * @param val: 需要处理的值
 * @param start: 保留的前面字数(默认：4)
 * @param end: 保留的后面是字数(默认：4)
 * @param sign: 中间替换成的内容(默认：...)
 */
export const middleEllipsis = (val: string, start = 4, end = -4, sign = '...') => {
  return val.slice(0, start) + sign + val.slice(end)
}

/**
 * 数字千分位展示
 * @param num 展示的数
 * @param precision 保留小数点位数
 * @param separator 标志
 * @returns {*}
 *=======================================================
 *     formatNumber(10000)="10,000"
 *     formatNumber(10000, 2)="10,000.00"
 *     formatNumber(10000.123456, 2)="10,000.12"
 *     formatNumber(10000.123456, 2, ' ')="10 000.12"
 *     formatNumber(.123456, 2, ' ')="0.12"
 *     formatNumber(56., 2, ' ')="56.00"
 *     formatNumber(56., 0, ' ')="56"
 *     formatNumber('56.')="56"
 *     formatNumber('56.a')=NaN
 *=======================================================
 */
export const formatNumber = (num: any, precision = 0, separator = ',') => {
  let parts
  if (num === null || num === undefined || num === '') return ''
  num = Number(num)
  // 判断是否为数字
  if (!Number.isNaN(parseFloat(num)) && Number.isFinite(num)) {
    // 把类似 .5, 5. 之类的数据转化成0.5, 5, 为数据精度处理做准, 至于为什么
    // 不在判断中直接写 if (!Number.isNaN(num = parseFloat(num)) && Number.isFinite(num))
    // 是因为parseFloat有一个奇怪的精度问题, 比如 parseFloat(12312312.1234567119)
    // 的值变成了 12312312.123456713
    const precisionLength = new Decimal(num).toFixed().split('.')[1]?.length
    // 处理小数点位数 判断num小数点位数是否大于0并且是否大于要保留的小数点位数，如果是则处理，否则不处理
    if (typeof precision !== 'undefined' && precision !== 20 && precisionLength > precision) {
      num = new Decimal(num).toFixed(precision, Decimal.ROUND_HALF_UP)
      num = num.includes('.') ? num.replace(/\.?0+$/, '') : num
      num = num === '-0' ? '0' : num
    } else {
      num = new Decimal(num).toFixed()
    }
    num = num.toString()
    // 分离数字的小数部分和整数部分
    parts = num.split('.')
    // 整数部分加[separator]分隔, 借用一个著名的正则表达式
    parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator || ','}`)
    return parts.join('.')
  }
  // return NaN;
  return num
}

/**
 * @description: 格式化数字为百分比
 * @param {any} num
 * @param {number} decimal
 * @return {*}
 */
export const formatDecimal = (num: any, decimal: number) => {
  num = num.toString()
  const index = num.indexOf('.')
  if (index !== -1) {
    num = num.substring(0, decimal + index + 1)
  } else {
    num = num.substring(0)
  }
  return `${parseFloat(num)}%`
}

/**
 * 数字百分号展示
 * @param num 展示的数
 * @param precision 展示%前面保留小数点位数
 * @returns {*}
 *=======================================================
 *     formatNumber(912.3456, 2)="9,1234.56%"
 *     formatNumber(0.123456)="12%"
 *     formatNumber(0.123456,2)="12.35%"
 *     formatNumber(0)="0"
 *=======================================================
 */
export const toPercent = (num: any, precision = 0) => {
  let parts
  // 判断是否为数字
  if (!Number.isNaN(parseFloat(num)) && Number.isFinite(num)) {
    if (num === 0) {
      return 0
    }
    num = Number(num) * 100
    // 处理小数点位数
    num = (typeof precision !== 'undefined' ? num.toFixed(precision) : num).toString()
    // 分离数字的小数部分和整数部分
    parts = num.split('.')
    // 整数部分加[separator]分隔, 借用一个著名的正则表达式
    // eslint-disable-next-line no-useless-concat
    parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + ',')
    // 拼接百分号
    return `${parts.join('.')}%`
  }
  return num
}

export const formatBigNumber = (number: any) => {
  return millify(Number(number), {
    precision: 1,
    lowercase: false
  })
}

/**
 * 基础数字千分位展示
 * @param num 展示的数
 * @returns {string|number}
 */
export const formatNumberWithCommas = (num: number | string): string | number => {
  if (num === null || num === undefined) return ''

  // 如果value是数字，先转换成字符串
  const stringValue = typeof num === 'number' ? num.toString() : num

  // 检查小数部分是否以0开始
  const decimalPartMatches = stringValue.match(/\.\d+/)
  const decimalPartStartsWithZero = decimalPartMatches && decimalPartMatches[0].startsWith('.0')

  // 处理输入过程中包含小数点的情况
  const hasTrailingDot = stringValue.endsWith('.')
  try {
    // 使用 Big.js 进行数字有效性检查和处理
    const bigNum = new Big(num)

    // 将数字转换为字符串并分割成整数部分和小数部分
    const [integerPart, decimalPart] = bigNum.toString().split('.')
    // 格式化整数部分
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    // 如果小数部分以0开始，仅对整数部分应用千分位格式化，保留小数部分的原始格式
    if (decimalPartStartsWithZero) {
      return `${formattedIntegerPart}${decimalPartMatches[0]}`
    }

    // 如果存在小数部分，则将整数部分和小数部分重新组合
    let value = decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart

    // 如果输入过程中有尾随小数点，则保留它
    if (hasTrailingDot) {
      value += '.'
    }
    return value
  } catch (e) {
    // 如果参数不是有效数字，直接返回原始值
    return num
  }
}
export const getMaximumFractionDigits = (num: number, digits: number = 2): number => {
  const strNum: string = String(num)
  const decimalIndex: number = strNum.indexOf('.')

  // 如果小数位数为零，则返回最小小数位数为零
  if (decimalIndex === -1) {
    return 0
  }

  const decimalPart: string = strNum.slice(decimalIndex + 1)
  // 如果小数部分小于 digits 但大于 1，则返回 1
  if (Number(decimalPart) < digits && decimalPart.length < digits && Number(decimalPart) >= 1) {
    return 1
  }

  // 如果小数部分不全是零，则找到第一个非零数字的索引，并返回其索引加1作为最小小数位数
  for (let i = 0; i < decimalPart.length; i += 1) {
    if (decimalPart[i] !== '0') {
      return i === 0 ? digits : i + 1
    }
  }
  return 0 // 如果所有数字都是零，则最小小数位数为零
}

/**
 * 格式化保留有效数字，千分位展示，可带有单位
 * @param num 展示的数
 * @param digits 保留的位数
 * @param symbol  单位
 * @param showSymbol 是否展示单位
 * @returns {*}
 */
export const formatNumberToSignificantDigits = (
  num: number | string,
  digits: number,
  symbol: string = '$',
  showSymbol: boolean = true
): string => {
  if (num === null || num === undefined || num === '') return ''
  try {
    const roundedNum: number = Number(new Decimal(num).toFixed())
    // 检查参数是否是一个有效的数字
    if (!Number.isNaN(parseFloat(roundedNum)) && Number.isFinite(roundedNum)) {
      let formattedNumber: string = roundedNum.toLocaleString('en-US', {
        minimumFractionDigits: getMaximumFractionDigits(roundedNum, digits),
        maximumFractionDigits: getMaximumFractionDigits(roundedNum, digits)
      }) // 添加千分位分隔符并保留两位小数
      if (!showSymbol) return formattedNumber

      // 如果数字是负数，去除负号，并在前面添加符号
      if (roundedNum < 0) {
        formattedNumber = formattedNumber.replace('-', `-${symbol}`) // 在负数前面添加符号
      } else {
        formattedNumber = symbol + formattedNumber // 在正数前面添加符号
      }

      return formattedNumber
    }
  } catch (error) {
    console.log(error)
    return num.toString()
  }

  // 如果参数不是有效数字，直接返回原始值
  return num.toString()
}

/**
 * 格式化保留有效位数，默认保留两位小数
 * @param number 展示的数
 */
export const roundToTwoDecimalPlaces = (number: number, precision: number = 2) => {
  // 创建 Decimal 对象
  const decimalNumber = new Decimal(number)

  // 获取小数部分
  const decimalPart = decimalNumber.mod(1)

  // 如果小数部分的精度大于两位，则进行四舍五入
  if (decimalPart.dp() > precision) {
    // 四舍五入到两位小数
    let roundedDecimal = decimalNumber.toFixed(2, Decimal.ROUND_HALF_UP)

    // 如果四舍五入后的结果两位小数都是0，则去除小数点
    roundedDecimal = roundedDecimal.includes('.') ? roundedDecimal.replace(/\.?0+$/, '') : roundedDecimal
    if (roundedDecimal === '-0') {
      roundedDecimal = '0'
    }
    roundedDecimal = roundedDecimal === '-0' ? '0' : roundedDecimal
    // 返回四舍五入后的结果
    return new Decimal(roundedDecimal).toNumber()
  }

  // 如果小数部分精度为两位，则直接返回原数字
  return number
}

/**
 * 格式化占比
 * @param list 数据列表
 * @param transactionNum 交易数量
 */
export const formatProportion = (list: Array<any>, transactionNum: number) => {
  const transactionCounts = list.map((item: any) => Number(item.transactionCount))
  const transactionSum = sum(transactionCounts)
  return formatDecimal((transactionNum / transactionSum) * 100, 1)
}

/**
 * 格式化输入地址
 * @param address 地址
 * @param prefixLength 前缀长度
 */
export const formatInputAddress = (address: string, prefixLength: number = 3, suffixLength: number = -4) => {
  if (isEmpty(address)) {
    return ''
  }

  return `${address.substr(0, prefixLength)}...${address.substr(suffixLength)}`
}

/**
 * 字符串超出一定位数,用...代替
 * @param str 具体的某个字符串
 * @param num 要截取多少位
 */
export const formatString = (str: string, num: number) => {
  if (str.length > num) {
    // 如果字符长度超过10，后面的字符就变成...可自行调整长度和代替字符
    str = `${str.substr(0, num)}...` // 截取从第一个字符开始，往后取10个字符，剩余的用...代替
    return str
  }
  return str
}
/**
 * 保留n位小数同时增加单位k m b...
 * @param number 数字
 * @param precision 保留小数位数
 */
export const formatThousandthAndUnit = (number: any, precision: number) => {
  const formatNumberVal = Number(number)
  if (isNaN(formatNumberVal)) {
    return ''
  }

  return millify(formatNumberVal, {
    precision,
    lowercase: true
  })
}
/**
 * 保留n位小数同时增加单位k m b... 如果是k保留1位
 * @param number 数字
 * @param precision 保留小数位数
 */
export const formatThousandthAndUnitWithOnlyK = (number: any, precision: number) => {
  if (number >= 1000 && number <= 9999) {
    return millify(Number(number), {
      precision: 1,
      lowercase: true
    })
  }
  return millify(Number(number), {
    precision,
    lowercase: true
  })
}

/**
 * 表格格式化方法
 * @params data 数据值
 * @params config{''|dollar|price|percent}
 * @params type 类型  number
 * 根据type类型 number
 * 根据config
 * percent :单位为【%】保留小数点之后2位，不进行四舍五入，小数点前每3位一个【,】分隔
 * 为空时:保留小数点之后1位，小数点前每3位一个【,】分隔
 * symbol: $ 货币符号
 * dollar:true  单位为【symbol】,保留小数点之后1位，小数点前每3位一个【,】分隔
 * price:true  单位为【symbol】，小数点前每3位一个【,】分隔。不增加K等度量单位，展示实际的数字}
 * keepPoint: true 保留全部小数点
 */
export const fieldValueFormat = (data: number | null, config: any, type: string) => {
  if ((typeof data === 'string' && data === '') || data == null) {
    return '-'
  }
  const symbol = config?.symbol || '$'
  if (type === 'NUMBER') {
    if (config.percent) {
      return toPercent(data, 2)
    }
    if (config.dollar) {
      const formatDollarNumber = formatThousandthAndUnit(Number(data) < 0 ? Math.abs(Number(data)) : data, 2)
      return Number(data) < 0 ? `-${symbol}${formatDollarNumber}` : `${symbol}${formatDollarNumber}`
    }
    if (config.price) {
      data = Number(new Decimal(data).toFixed())
      // 0
      if (data === 0) {
        return `${symbol}${data}`
      }
      // 0 -> 1
      if (data > 0 && data < 1) {
        return config?.keepPoint
          ? `${symbol}${formatNumber(Number(data), 20)}`
          : `${symbol}${roundToTwoDecimalPlaces(data, config?.precision ?? 4)}`
      }
      //  < 0
      if (data < 0) {
        return config?.keepPoint
          ? `-${symbol}${formatNumber(Math.abs(Number(data)), 20)}`
          : `-${symbol}${roundToTwoDecimalPlaces(Math.abs(data), config?.precision ?? 4)}`
      }
      //  > 1
      return `${symbol}${formatNumber(data, config?.keepPoint ? 20 : 2)}`
    }
    if (isEmpty(config)) {
      return formatNumber(data, 1)
    }
  }

  return formatThousandthAndUnit(data, 1)
}

/**
 * 字符串长度验证, 中文算一个，英文算半个
 * @param str 字符串
 */
export const validateStrLength = (str: any) => {
  let bytesCount = 0
  for (let i = 0, n = str.length; i < n; i += 1) {
    const c = str.charCodeAt(i)
    if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
      bytesCount += 1
    } else {
      bytesCount += 2
    }
  }
  const length = (bytesCount / 2).toFixed(0)
  return Number(length)
}

/**
 * 负数添加（,）显示，例如：-300=>(300）,-3000=>(3,000)
 * @param unit 单位 thousands，millions，billions, individuals
 * @param num 数字
 * @param balanceType 余额类型
 * @param separator 为不是数字时的替换符号
 * @param isSeparatorZero 当值是0时，需要用替换符号代替吗？-默认不代替
 * @param isUnit 是否需要单位
 */
export const numDrCrHandle = (
  unit: any,
  num: any,
  balanceType: string = 'Dr',
  separator: any = '-',
  isSeparatorZero: boolean = false,
  isUnit: boolean = true
) => {
  let finVal
  let absVal
  // eslint-disable-next-line no-shadow
  enum UnitEnum {
    individuals = 1,
    thousands = 1000,
    millions = 1000000,
    billions = 1000000000
  }
  const unitNumber = Number(UnitEnum[unit])
  // 是数字时
  if (typeof num === 'number') {
    absVal = isUnit ? Math.abs(num) / unitNumber : Math.abs(num)
    if (num > 0) {
      finVal = formatNumber(absVal, 0)
    } else if (num < 0) {
      finVal = `( ${formatNumber(absVal, 0)} )`
    } else if (num === 0) {
      if (isSeparatorZero) {
        finVal = separator
      } else {
        if (balanceType === 'Cr') {
          finVal = '( 0 )'
        } else if (balanceType === 'Dr') {
          finVal = 0
        } else {
          finVal = 0
        }
      }
    }
    // 不是是数字时，用替换符号代替
  } else {
    finVal = separator
  }
  return finVal
}

/**
 * 添加Meta标签
 * @param {string} name
 * @param {string} content
 * @param {string} property
 * @returns
 */
export const addMeta = (content: string, name: string = '', property: string = '') => {
  const meta: any = document.createElement('meta')
  if (name !== '') {
    meta.name = name
  }
  if (property !== '') {
    meta.id = 'Financials-Share'
    meta.setAttribute('property', property)
  }
  meta.content = content
  document.getElementsByTagName('head')[0].appendChild(meta)
}

/**
 * 判断是否是为移动端
 */
export const isMobile = () => {
  return /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(
    navigator.userAgent
  )
}
/**
 * 判断是否是为ios环境
 */
export const isIOS = () => {
  const u: string = navigator.userAgent
  return u.toLowerCase().indexOf('iphone') >= 0 || u.toLowerCase().indexOf('ipad') >= 0 // ios终端
}
/**
 * 文本国际化
 * @param {string} text
 */
export const formatLanguageContent = (text: string) => {
  const language = localStorage.getItem('language') ?? 'en'
  if (language === 'en') return text
  const languageData = result(languages, language)
  if (isEmpty(languageData)) return text
  const languageText = result(languageData, String(text).trim())
  if (!languageText) return text
  return languageText
}
export const objToQueryStr = (params: Object) => {
  return Object.entries(params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')
}

/**
 * 联系销售（根据语言不通，跳转页面不同）
 * @param {string} name: 用户姓名
 * @param {string} email: 用户邮箱
 */
export const judgeTalkToSalesLink = (name: string, email?: string) => {
  const language = localStorage.getItem('language') ?? 'en'
  if (language === 'en') {
    window.open(
      `https://elvencom.feishu.cn/share/base/form/shrcnMAmAp3P6Hn4hwwocFG6uLc?prefill_Your+name=${name}&prefill_Your+work+email=${email}`,
      '_blank'
    )
    return
  }
  window.open(
    `https://elvencom.feishu.cn/share/base/form/shrcn3U8ttS0RpTli8pANiqVH1b?prefill_%E6%82%A8%E7%9A%84%E5%A7%93%E5%90%8D=${name}&prefill_%E6%82%A8%E7%9A%84%E9%82%AE%E7%AE%B1=${email}`,
    '_blank'
  )
}

export const getUuid = async () => {
  // Initialize an agent at application startup.
  const fpPromise = FingerprintJS.load()

  // Get the visitor identifier when you need it.
  const fp = await fpPromise
  const response = await fp.get()

  return response.visitorId
}

export const queryStrToObj = (queryStr: string) => {
  const queryObj: any = {}
  // queryStr截取?后的参数转为对象
  if (queryStr.indexOf('?') !== -1) {
    const str = queryStr.substr(1)
    const strs = str.split('&')
    for (let i = 0; i < strs.length; i += 1) {
      queryObj[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1])
    }
  } else if (queryStr) {
    const queryArr = queryStr.split('&')
    queryArr.forEach((item) => {
      const tempArr = item.split('=')
      // eslint-disable-next-line prefer-destructuring
      queryObj[tempArr[0]] = tempArr[1]
    })
  }
  return queryObj
}
/**
 * 计算时差
 * @param originalTime 原始时间
 * @param sourceTimezone 来源时区
 * @param businessDate 是否是业务数据
 */
export const calculateUtcOffset = (originalTime: string, sourceTimezone: string, businessDate: boolean = false) => {
  if (businessDate) return `(UTC-0)`
  // 将原始时间和UTC时间转换为dayjs对象
  const originalDateTime = dayjs.tz(originalTime, sourceTimezone)
  const utcDateTime = dayjs(originalTime).utc()

  // 计算时差，将分钟数转换为小时数
  const hoursDifference = (originalDateTime.utcOffset() - utcDateTime.utcOffset()) / 60
  // 返回带有符号的时差字符串
  return `(UTC${hoursDifference >= 0 ? '+' : ''}${hoursDifference.toFixed(0)})`
}

/**
 * 格式化GST公式
 * @param gstType GST类型
 * @param hasAccountIndex GST账户索引
 * @param percent 百分比
 */
export const gstFormulaFormat = (
  gstType: string,
  hasAccountIndex: number,
  isGstAccount: boolean,
  percent: number = 9,
  reportingCurrency: string = 'USD'
) => {
  let gstFormula = ''
  switch (gstType) {
    case 'STANDARD':
    case 'TAXABLE':
      if (isGstAccount) {
        gstFormula = `1-(1/(1+${percent / 100})) * {#${hasAccountIndex + 1} Amount(${reportingCurrency})}`
      } else {
        gstFormula = `1/(1+${percent / 100})) * {#${hasAccountIndex + 1} Amount(${reportingCurrency})}`
      }
      break
    case 'ZERO':
      if (isGstAccount) {
        gstFormula = '0'
      } else {
        gstFormula = `#${hasAccountIndex + 1} Amount(${reportingCurrency})`
      }
      break
    case 'EXEMPT':
      if (isGstAccount) {
        gstFormula = '-'
      } else {
        gstFormula = `#${hasAccountIndex + 1} Amount(${reportingCurrency})`
      }
      break
    default:
      break
  }
  return gstFormula
}

function lightenColor(color: string, percent: number): string {
  const num = parseInt(color.slice(1), 16)
  const amt = Math.round(2.55 * percent)
  let R = (num >> 16) + amt
  let G = ((num >> 8) & 0x00ff) + amt
  let B = (num & 0x0000ff) + amt

  // 确保颜色不会变成白色
  R = R > 240 ? 240 : R
  G = G > 240 ? 240 : G
  B = B > 240 ? 240 : B

  return `#${(
    0x1000000 +
    // eslint-disable-next-line no-nested-ternary
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    // eslint-disable-next-line no-nested-ternary
    (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
    // eslint-disable-next-line no-nested-ternary
    (B < 255 ? (B < 1 ? 0 : B) : 255)
  )
    .toString(16)
    .slice(1)
    .toUpperCase()}`
}

/**
 * 将十六进制颜色转换为 RGB 格式
 * @param hex 十六进制颜色代码
 * @returns RGB 格式的颜色字符串
 */
function hexToRgb(hex: string): string {
  const bigint = parseInt(hex.slice(1), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  return `${r}, ${g}, ${b}`
}

/**
 * 生成同色系指定数量渐变色
 * @param baseColor  基准主题色值
 * @param numberOfShades 需要生成的渐变色数量
 * @returns 渐变色数组（包含原始基准色）
 */
export function generateColorShades(baseColor: string, numberOfShades: number): string[] {
  const shades: string[] = []
  let color = baseColor

  for (let i = 0; i < numberOfShades; i += 1) {
    shades.push(color)
    color = lightenColor(color, 5) // 每次变浅 10%
  }
  return shades
}

export default {
  dateFormatting,
  middleEllipsis,
  formatNumber,
  toPercent,
  formatBigNumber,
  formatNumberWithCommas,
  formatDecimal,
  formatThousandthAndUnit,
  formatThousandthAndUnitWithOnlyK,
  formatNumberToSignificantDigits,
  roundToTwoDecimalPlaces,
  formatProportion,
  formatInputAddress,
  formatString,
  fieldValueFormat,
  validateStrLength,
  numDrCrHandle,
  addMeta,
  isMobile,
  isIOS,
  gstFormulaFormat,
  formatLanguageContent,
  objToQueryStr,
  getUuid,
  queryStrToObj,
  calculateUtcOffset,
  generateColorShades
}
