import globalGuard from './globalGuard'
import { versionCheckUpdate } from '@/lib/updateVersion'
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    //   登录页面
    {
      name: 'login',
      path: '/login',
      component: () => import('@/pages/User/LoginPage.vue'),
      meta: { requiresAuth: false }
    },
    // 注册页面
    {
      name: 'signup',
      path: '/signup',
      component: () => import('@/pages/User/SignupPage.vue'),
      meta: { requiresAuth: false }
    },
    //   主体业务
    {
      name: 'defaultLayout',
      path: '/', // 这个并不是通配符，而是严格匹配
      component: () => import('@/layout/Index.vue'),
      children: [
        {
          name: 'entity',
          path: 'entity/:entityId',
          redirect: () => ({ name: 'entity-dashboard' }),
          meta: { requiresAuth: true },
          component: () => import('@/pages/Financials/DetailPage.vue'),
          children: [
            {
              name: 'entity-dashboard',
              path: 'dashboard',
              meta: { slug: 'Dashboard' },
              component: () => import('@/pages/Financials/Project/Dashboard/IndexPage.vue')
            },
            {
              name: 'entity-accounts',
              path: 'accounts',
              meta: { slug: 'Accounts' },
              component: () => import('@/pages/Financials/Project/Accounts/IndexPage.vue'),
              redirect: () => ({ name: 'entity-accounts-total-position' }),
              children: [
                {
                  name: 'entity-accounts-total-position',
                  path: 'total-position',
                  component: () => import('@/pages/Financials/Project/Accounts/TotalPosition/DetailPage.vue')
                },
                {
                  name: 'entity-accounts-treasury',
                  path: 'treasury',
                  component: () => import('@/pages/Financials/Project/Accounts/AccountList/DetailPage.vue')
                },
                {
                  name: 'entity-accounts-derivative',
                  path: 'derivative',
                  component: () => import('@/pages/Financials/Project/Accounts/Derivative/DetailPage.vue')
                },
                {
                  name: 'entity-accounts-defi-position',
                  path: 'defi',
                  component: () => import('@/pages/Financials/Project/Accounts/DefiPosition/DetailPage.vue')
                },
                {
                  name: 'entity-accounts-sources',
                  path: 'sources',
                  component: () => import('@/pages/Financials/Project/Accounts/Sources/DetailPage.vue')
                },
                {
                  name: 'entity-accounts-contacts',
                  path: 'contacts',
                  component: () => import('@/pages/Financials/Project/Accounts/Counterparty/DetailPage.vue'),
                  redirect: () => ({ name: 'entity-accounts-contacts-named' }),
                  children: [
                    {
                      name: 'entity-accounts-contacts-named',
                      path: 'named',
                      component: () => import('@/pages/Financials/Project/Accounts/Counterparty/NamedPage.vue')
                    },
                    {
                      name: 'entity-accounts-contacts-unnamed',
                      path: 'unnamed',
                      component: () => import('@/pages/Financials/Project/Accounts/Counterparty/UnnamedPage.vue')
                    }
                  ]
                }
              ]
            },
            {
              name: 'entity-valuation',
              path: 'valuation',
              meta: { slug: 'Valuation' },
              component: () => import('@/pages/Financials/Project/Valuation/IndexPage.vue'),
              redirect: () => ({ name: 'entity-valuation-crypto' }),
              children: [
                {
                  name: 'entity-valuation-crypto',
                  path: 'crypto',
                  component: () => import('@/pages/Financials/Project/Valuation/Crypto/DetailPage.vue')
                },
                {
                  name: 'entity-valuation-fiat',
                  path: 'fiat',
                  component: () => import('@/pages/Financials/Project/Valuation/Fiat/DetailPage.vue')
                },
                {
                  name: 'entity-valuation-future',
                  path: 'future',
                  component: () => import('@/pages/Financials/Project/Valuation/Future/DetailPage.vue')
                },
                {
                  name: 'entity-valuation-options',
                  path: 'options',
                  component: () => import('@/pages/Financials/Project/Valuation/Options/DetailPage.vue')
                },
                {
                  name: 'entity-valuation-validator',
                  path: 'validator',
                  component: () => import('@/pages/Financials/Project/Valuation/Validator/DetailPage.vue')
                },
                {
                  name: 'entity-valuation-inventory',
                  path: 'inventory',
                  component: () => import('@/pages/Financials/Project/Valuation/Inventory/DetailPage.vue')
                },
                {
                  name: 'entity-valuation-assets',
                  path: 'assets',
                  component: () => import('@/pages/Financials/Project/Valuation/Assets/DetailPage.vue')
                },
                {
                  name: 'entity-valuation-price',
                  path: 'price',
                  component: () => import('@/pages/Financials/Project/Valuation/Price/DetailPage.vue')
                },
                {
                  name: 'entity-valuation-automation',
                  path: 'automation',
                  component: () => import('@/pages/Financials/Project/Valuation/Automation/DetailPage.vue')
                }
              ]
            },
            {
              name: 'entity-transactions',
              path: 'transactions',
              meta: { slug: 'Transactions' },
              component: () => import('@/pages/Financials/Project/Transactions/IndexPage.vue'),
              redirect: () => ({ name: 'entity-transactions-list' }),
              children: [
                {
                  name: 'entity-transactions-list',
                  path: 'list',
                  component: () => import('@/pages/Financials/Project/Transactions/Transfer/DetailPage.vue')
                },
                {
                  name: 'entity-transactions-trade-list',
                  path: 'trade',
                  component: () => import('@/pages/Financials/Project/Transactions/Trade/DetailPage.vue')
                },
                {
                  name: 'entity-transactions-gain-or-loss',
                  path: 'gain-or-loss',
                  component: () => import('@/pages/Financials/Project/Transactions/GainLoss/DetailPage.vue')
                },
                {
                  name: 'entity-transactions-automation',
                  path: 'automation',
                  component: () => import('@/pages/Financials/Project/Transactions/Automation/DetailPage.vue')
                }
              ]
            },
            {
              name: 'reconciliation',
              path: 'reconciliation',
              meta: { slug: 'Reconciliation' },
              component: () => import('@/pages/Financials/Project/Reconciliation/IndexPage.vue'),
              redirect: () => ({ name: 'entity-reconciliation-tasks' }),
              children: [
                {
                  name: 'entity-reconciliation-tasks',
                  path: 'tasks',
                  component: () => import('@/pages/Financials/Project/Reconciliation/Tasks/DetailPage.vue')
                },
                {
                  name: 'entity-reconciliation-task-detail',
                  path: ':reconciliationTaskId',
                  component: () => import('@/pages/Financials/Project/Reconciliation/TaskDetailPage.vue'),
                  redirect: () => ({ name: 'entity-reconciliation-data-sets' }),
                  children: [
                    {
                      name: 'entity-reconciliation-data-sets',
                      path: 'data-sets',
                      component: () => import('@/pages/Financials/Project/Reconciliation/DataSets/DetailPage.vue')
                    },
                    {
                      name: 'entity-reconciliation-details',
                      path: 'details',
                      component: () => import('@/pages/Financials/Project/Reconciliation/Details/DetailPage.vue')
                    },
                    {
                      name: 'entity-reconciliation-automation',
                      path: 'automation',
                      component: () => import('@/pages/Financials/Project/Reconciliation/Automation/DetailPage.vue')
                    },
                    {
                      name: 'entity-reconciliation-matches',
                      path: 'matches',
                      component: () => import('@/pages/Financials/Project/Reconciliation/Matches/DetailPage.vue')
                    },
                    {
                      name: 'entity-reconciliation-report',
                      path: 'report',
                      component: () => import('@/pages/Financials/Project/Reconciliation/Report/DetailPage.vue')
                    },
                    {
                      name: 'entity-reconciliation-history',
                      path: 'history',
                      component: () => import('@/pages/Financials/Project/Reconciliation/HistoryPage.vue')
                    }
                  ]
                }
              ]
            },
            {
              name: 'entity-ledger',
              path: 'ledger',
              meta: { slug: 'Ledger' },
              component: () => import('@/pages/Financials/Project/Ledger/IndexPage.vue'),
              redirect: () => ({ name: 'entity-ledger-journals' }),
              children: [
                {
                  name: 'entity-ledger-journals',
                  path: 'journals',
                  component: () => import('@/pages/Financials/Project/Ledger/Journals/DetailPage.vue')
                },
                {
                  name: 'reports-general-ledger',
                  path: 'detail',
                  component: () => import('@/pages/Financials/Project/Ledger/TrailBalance/DetailPage.vue')
                },
                {
                  name: 'entity-ledger-account-balance',
                  path: 'account-balance',
                  component: () => import('@/pages/Financials/Project/Ledger/AccountBalance/DetailPage.vue')
                },
                {
                  name: 'entity-ledger-chart-of-account',
                  path: 'chart-of-account',
                  component: () => import('@/pages/Financials/Project/Ledger/ChartOrAccount/DetailPage.vue')
                },
                {
                  name: 'entity-ledger-auxiliary-code',
                  path: 'auxiliary-code',
                  component: () => import('@/pages/Financials/Project/Ledger/AuxiliaryCode/DetailPage.vue')
                },
                {
                  name: 'entity-ledger-journal-type',
                  path: 'journal-type',
                  component: () => import('@/pages/Financials/Project/Ledger/JournalType/DetailPage.vue')
                }
              ]
            },
            {
              name: 'reports',
              path: 'reports',
              meta: { slug: 'Reports' },
              component: () => import('@/pages/Financials/Project/Reports/IndexPage.vue'),
              redirect: () => ({ name: 'reports-balance-sheet' }),
              children: [
                {
                  name: 'reports-balance-sheet',
                  path: 'balance-sheet',
                  component: () => import('@/pages/Financials/Project/Reports/BalancePage.vue')
                },
                {
                  name: 'reports-income-statement',
                  path: 'income-statement',
                  component: () => import('@/pages/Financials/Project/Reports/IncomePage.vue')
                },
                {
                  name: 'reports-cash-flow-statement',
                  path: 'cash-flow-statement',
                  component: () => import('@/pages/Financials/Project/Reports/CashFlowPage.vue')
                },
                {
                  name: 'reports-significant-holdings',
                  path: 'significant-holdings',
                  component: () => import('@/pages/Financials/Project/Reports/HoldingsPage.vue')
                },
                {
                  name: 'reports-restrictions',
                  path: 'restrictions-crypto-assets',
                  component: () => import('@/pages/Financials/Project/Reports/RestrictionsPage.vue')
                },
                {
                  name: 'reports-roll-forward',
                  path: 'crypto-assets-roll-forward',
                  component: () => import('@/pages/Financials/Project/Reports/RollForwardPage.vue')
                }
              ]
            }
          ]
        },
        {
          name: 'project-settings',
          path: '/project/:projectId/settings',
          meta: { requiresAuth: true, slug: 'Settings' },
          component: () => import('@/pages/Financials/ProjectSetting/DetailPage.vue')
        },
        {
          name: 'entity-settings',
          path: '/entity/:projectId/settings',
          meta: { requiresAuth: true },
          component: () => import('@/pages/Financials/EntitySetting/DetailPage.vue')
        },
        {
          name: 'landingPage',
          path: '/landing',
          redirect: () => ({ name: 'landing-plan' }),
          component: () => import('@/pages/Landing/DetailPage.vue'),
          meta: { requiresAuth: true, slug: 'Landing' },
          children: [
            {
              name: 'landing-plan',
              path: 'plan',
              component: () => import('@/pages/Landing/ProjectPlanPage.vue')
            },
            {
              name: 'landing-subscription',
              path: 'subscription',
              component: () => import('@/pages/Landing/SubscriptionPage.vue')
            },
            {
              name: 'landing-activate',
              path: 'activate',
              component: () => import('@/pages/Landing/FreeProject/ActivationPage.vue')
            },
            {
              name: 'landing-apply',
              path: 'apply',
              component: () => import('@/pages/Landing/FreeProject/ApplyPage.vue')
            }
          ]
        },
        // 隐私协议页面
        {
          name: 'privacyAgreement',
          path: '/pact/privacyAgreement',
          component: () => import('@/pages/Pact/PrivacyAgreement.vue'),
          meta: { requiresAuth: false, hasSider: false, slug: 'PrivacyAgreement' }
        }
      ]
    },
    // 申请免费项目
    {
      name: 'basic-free-project',
      path: '/basic',
      component: () => import('@/pages/Landing/FreeProject/ApplyFreeAccessPage.vue'),
      meta: { requiresAuth: false }
    },
    {
      name: 'oauth-xero',
      path: '/oauth/xero',
      meta: { requiresAuth: true, slug: 'Xero' },
      component: () => import('@/pages/Oauth/ExroPage.vue')
    },
    {
      name: 'oauth-google',
      path: '/oauth/google',
      meta: { requiresAuth: true, slug: 'Google' },
      component: () => import('@/pages/Oauth/GooglePage.vue')
    },
    // 404 页面
    {
      name: 'notFound',
      path: '/404',
      component: () => import('@/pages/NotFoundPage.vue'),
      meta: { requiresAuth: false, slug: '404' }
    },

    // 所有未被匹配的路由都漏到这里，然后重定向到home
    {
      path: '/:pathMatch(.*)',
      name: 'not-found',
      redirect: () => ({ name: 'notFound' })
    }
  ]
})

router.beforeEach(globalGuard.init)
router.beforeEach(globalGuard.authVerify)
router.afterEach(versionCheckUpdate)

router.onError((error, to: any) => {
  if (
    error?.message?.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload()
    } else {
      window.location = to.fullPath
    }
  }
})

export default router
