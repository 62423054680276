<template>
  <router-view />
  <img
    v-if="loaded && route?.name !== 'notFound'"
    class="elv-content-skeleton-logo"
    src="@/assets/img/left-meun-whole-logo.png"
    alt="elven-logo"
  />
</template>
<script setup lang="ts">
import '@/assets/fonts/font.css'
import '@/assets/style/common.scss'
import '@/assets/style/element-reset.scss'
import { useGlobalStore } from '@/stores/modules/global/index'

const route = useRoute()
const globalStore = useGlobalStore()
const { loaded } = storeToRefs(globalStore)
</script>
<style scoped>
.elv-content-skeleton-logo {
  width: 200px;
  height: 64px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
