import { http } from '@/lib/http'
import { CurrencyItemType } from '#/ReportsTypes'
import { AutomationRuleListType, SystemTrxAutomationRuleItemType } from '#/EntityTypes'
import {
  TradeDetailType,
  GainLossDetailType,
  JournalTypeItemType,
  TransactionListType,
  TransactionDetailType,
  JournalGroupBusinessEventType,
  JournalGroupBusinessEventDetailType
} from '#/TransactionsTypes'

export default {
  /**
   * 修改交易详情
   * @param {string} entityId 主体id
   * @param {string} transactionId
   * @param {object} data
   * @property {string} [data.memo]  备注
   * @property {string[]} [data.transactionAttachmentIds] 附件ids
   */
  editTransactionDetail: (entityId: string, transactionId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/transaction/${transactionId}`, { data })
  },
  /**
   * 获取Business Event列表
   * @param {string} entityId 主体id
   */
  getJournalGroupBusinessEventList: (entityId: string) => {
    return http.request<ResponseData<JournalGroupBusinessEventType[]>>(
      'get',
      `/entity/${entityId}/journal/group/config`
    )
  },
  /**
   * 添加Business Event
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {string} data.name
   * @property {string[]} data.journalTypeIds
   */
  addJournalGroupBusinessEvent: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/journal/group/config`, { data })
  },
  /**
   * 编辑Business Event
   * @param {string} entityId 主体id
   * @param {string} journalGroupConfigId
   * @param {object} data
   * @property {string} data.name
   * @property {string[]} data.journalTypeIds
   */
  editJournalGroupBusinessEvent: (entityId: string, journalGroupConfigId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/journal/group/config/${journalGroupConfigId}`, { data })
  },
  /**
   * 获取Business Event详情
   * @param {string} entityId 主体id
   * @param {string} journalGroupConfigId
   */
  getJournalGroupBusinessEventDetail: (entityId: string, journalGroupConfigId: string) => {
    return http.request<ResponseData<JournalGroupBusinessEventDetailType>>(
      'get',
      `/entity/${entityId}/journal/group/config/${journalGroupConfigId}`
    )
  },
  /**
   * 删除Business Event
   * @param {string} entityId 主体id
   * @param {string} journalGroupConfigId
   */
  deleteJournalGroupBusinessEvent: (entityId: string, journalGroupConfigId: string) => {
    return http.request('delete', `/entity/${entityId}/journal/group/config/${journalGroupConfigId}`)
  },
  /**
   * 执行生成分组
   * @param {string} entityId 主体id
   */
  executeJournalGroup: (entityId: string) => {
    return http.request('post', `/entity/${entityId}/journal/group/execute`)
  },
  /**
   * 获取journalType列表
   * @param {string} entityId 主体id
   * @param {string} journalActivityId
   */
  getJournalTypeList: (entityId: string) => {
    return http.request<ResponseData<JournalTypeItemType[]>>('get', `/entity/${entityId}/journalType`)
  },
  /**
   * 创建Journal推导treasury account
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {string} data.currency
   * @property {string} data.entityAccountId
   */
  previewJournalTreasuryAccount: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/journal/treasuryAccount/preview`, { data })
  },
  /**
   * 创建Journal的gst类型预览
   * @param {string} entityId 主体id
   * @param {object} data
   */
  journalGstPreview: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/journal/gst/preview`, { data })
  },
  /**
   * 创建Journal的基于transaction的preview接口
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {string} data.journalTypeId
   * @property {string[]} data.transactionIds
   */
  journalByTransactionPreview: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/journal/transaction/preview`, { data })
  },
  /**
   * 删除Journal
   * @param {string} entityId 主体id
   * @param {string} journalActivityId id
   */
  deleteJournal: (entityId: string, journalActivityId: string) => {
    return http.request('delete', `/entity/${entityId}/journal/${journalActivityId}`)
  },
  /**
   * 批量删除Journal
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {string[]} data.journalActivityIds
   */
  batchDeleteJournal: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/journal/bulkDelete`, { data })
  },
  /**
   * posting
   * @param {string} entityId 主体id
   */
  journalPosting: (entityId: string) => {
    return http.request('post', `/entity/${entityId}/posting`)
  },
  /**
   * 获取Transaction列表
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {number} data.page
   * @property {number} data.limit
   * @property {string} data.sortField
   * @property {string} data.sortDirection
   * @property {string[]} [data.type] 交易类型
   * @property {object} [data.datetime] 交易时间
   * @property {string[]} [data.currency] 币种
   * @property {string[]} [data.accountId] 账户id
   * @property {string} [data.accountType] 账户类型
   * @property {string} [data.verifyStatus]
   */
  getTransactionList: (entityId: string, data: object) => {
    return http.request<ResponseData<TransactionListType>>('post', `/entity/${entityId}/transaction`, { data })
  },
  /**
   * 获取Transaction详情
   * @param {string} entityId 主体id
   * @param {string} transactionId 交易id
   */
  getTransactionDetail: (entityId: string, transactionId: string) => {
    return http.request<ResponseData<TransactionDetailType>>('get', `/entity/${entityId}/transaction/${transactionId}`)
  },
  /**
   * 获取Transaction currency列表
   * @param {string} entityId 主体id
   */
  getTransactionCurrencyList: (entityId: string) => {
    return http.request<ResponseData<CurrencyItemType[]>>('get', `/entity/${entityId}/transaction/currency`)
  },
  /**
   * 获取Trx规则列表
   * @param {string} entityId 主体id
   * @param {object} params
   * @property {number} params.page
   * @property {number} params.limit
   */
  getTrxAutomationRuleList: (entityId: string, params: object) => {
    return http.request<ResponseData<AutomationRuleListType>>('get', `/entity/${entityId}/automationRuleList`, {
      params
    })
  },
  /**
   * 获取Trx系统规则列表
   * @param {string} entityId 主体id
   */
  getTrxSystemAutomationRuleList: (entityId: string) => {
    return http.request<ResponseData<SystemTrxAutomationRuleItemType[]>>(
      'get',
      `/entity/${entityId}/systemAutomationRule`
    )
  },
  /**
   * 系统规则启用
   * @param {string} entityId 主体id
   * @param {string} systemAutomationRuleId 规则id
   * @param {object} data
   * @property {boolean} data.enabled 是否启用
   */
  switchSystemAutomationRuleStatus: (entityId: string, systemAutomationRuleId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/systemAutomationRule/${systemAutomationRuleId}/status`, {
      data
    })
  },
  /**
   * 添加自动化规则
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {string} data.name 规则名称
   * @property {Array} data.conditions 规则
   * @property {string} data.journalTypeId journalType
   * @property {string} data.type
   */
  addAutomationRule: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/automationRule`, { data })
  },
  /**
   * 修改配置规则
   * @param {string} entityId 主体id
   * @param {string} automationRuleId 规则id
   * @param {object} data
   * @property {string} data.name 规则名称
   * @property {Array} data.conditions 规则
   * @property {string} data.journalTypeId journalType
   */
  editAutomationRule: (entityId: string, automationRuleId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/automationRule/${automationRuleId}`, { data })
  },
  /**
   * 删除Trx规则
   * @param {string} entityId 主体id
   * @param {string} automationRuleId 规则id
   */
  deleteAutomationRule: (entityId: string, automationRuleId: string) => {
    return http.request('delete', `/entity/${entityId}/automationRule/${automationRuleId}`)
  },
  /**
   * Trx规则启用
   * @param {string} entityId 主体id
   * @param {string} automationRuleId 规则id
   * @param {object} data
   * @property {boolean} data.enabled 是否启用
   */
  switchAutomationRuleStatus: (entityId: string, automationRuleId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/automationRule/${automationRuleId}/status`, { data })
  },
  /**
   * 修改Trx的rule优先级
   * @param {string} entityId 主体id
   * @param {Array} data [{automationRuleId: '3523'}, {automationRuleId: '3921'}]
   */
  editAutomationRulePriority: (entityId: string, data: any[]) => {
    return http.request('put', `/entity/${entityId}/automationRule/priority`, { data })
  },
  /**
   * 执行所有交易自动化规则
   * @param {string} entityId 主体id
   */
  executeAllTransactionAutomationRule: (entityId: string) => {
    return http.request('post', `/entity/${entityId}/generate/journal`)
  },
  /**
   * 根据journalType获取entryLine对应的COA的辅助核算字段列表
   * @param {string} entityId 主体id
   * @param {Array} data
   * @property {boolean} data.journalTypeId journalType
   */
  automationRuleAuxiliaryTypePreview: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/automationRule/auxiliaryType/preview`, { data })
  },
  /**
   * 获取Trade规则列表
   * @param {string} entityId 主体id
   * @param {object} params
   * @property {number} params.page
   * @property {number} params.limit
   */
  getTradeAutomationRuleList: (entityId: string, params: object) => {
    return http.request<ResponseData<AutomationRuleListType>>('get', `/entity/${entityId}/tradeAutomationRule`, {
      params
    })
  },
  /**
   * 添加Trade自动化规则
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {string} data.name 规则名称
   * @property {Array} data.conditions 规则
   * @property {string} data.journalTypeId journalType
   * @property {Array} data.entryActions entryActions
   */
  addTradeAutomationRule: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/tradeAutomationRule`, { data })
  },
  /**
   * 修改Trade配置规则
   * @param {string} entityId 主体id
   * @param {string} tradeAutomationRuleId 规则id
   * @param {object} data
   * @property {string} data.name 规则名称
   * @property {Array} data.conditions 规则
   * @property {string} data.journalTypeId journalType
   * @property {Array} data.entryActions entryActions
   */
  editTradeAutomationRule: (entityId: string, tradeAutomationRuleId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/tradeAutomationRule/${tradeAutomationRuleId}`, { data })
  },
  /**
   * 删除Trade规则
   * @param {string} entityId 主体id
   * @param {string} tradeAutomationRuleId 规则id
   */
  deleteTradeAutomationRule: (entityId: string, tradeAutomationRuleId: string) => {
    return http.request('delete', `/entity/${entityId}/tradeAutomationRule/${tradeAutomationRuleId}`)
  },
  /**
   * Trade规则启用
   * @param {string} entityId 主体id
   * @param {string} tradeAutomationRuleId 规则id
   * @param {object} data
   * @property {boolean} data.enabled 是否启用
   */
  switchTradeAutomationRuleStatus: (entityId: string, tradeAutomationRuleId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/tradeAutomationRule/${tradeAutomationRuleId}/status`, { data })
  },
  /**
   * 修改Trade的rule优先级
   * @param {string} entityId 主体id
   * @param {Array} data [{tradeAutomationRuleId: '3523'}, {tradeAutomationRuleId: '3921'}]
   */
  editTradeAutomationRuleRulePriority: (entityId: string, data: any[]) => {
    return http.request('put', `/entity/${entityId}/tradeAutomationRule/priority`, { data })
  },
  /**
   * 执行所有Trade自动化规则
   * @param {string} entityId 主体id
   */
  executeAllTradeAutomationRule: (entityId: string) => {
    return http.request('post', `/entity/${entityId}/tradeAutomationRule/execute`)
  },
  /**
   * 获取Gain/Loss规则列表
   * @param {string} entityId 主体id
   * @param {object} params
   * @property {number} params.page
   * @property {number} params.limit
   */
  getGainLossAutomationRuleList: (entityId: string, params: object) => {
    return http.request<ResponseData<AutomationRuleListType>>('get', `/entity/${entityId}/gainLossAutomationRule`, {
      params
    })
  },
  /**
   * 添加Gain/Loss自动化规则
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {string} data.name 规则名称
   * @property {Array} data.conditions 规则
   * @property {string} data.journalTypeId journalType
   * @property {Array} data.entryActions entryActions
   */
  addGainLossAutomationRule: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/gainLossAutomationRule`, { data })
  },
  /**
   * 修改Gain/Loss配置规则
   * @param {string} entityId 主体id
   * @param {string} gainLossAutomationRuleId 规则id
   * @param {object} data
   * @property {string} data.name 规则名称
   * @property {Array} data.conditions 规则
   * @property {string} data.journalTypeId journalType
   * @property {Array} data.entryActions entryActions
   */
  editGainLossAutomationRule: (entityId: string, gainLossAutomationRuleId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/gainLossAutomationRule/${gainLossAutomationRuleId}`, { data })
  },
  /**
   * 删除Gain/Loss规则
   * @param {string} entityId 主体id
   * @param {string} gainLossAutomationRuleId 规则id
   */
  deleteGainLossAutomationRule: (entityId: string, gainLossAutomationRuleId: string) => {
    return http.request('delete', `/entity/${entityId}/gainLossAutomationRule/${gainLossAutomationRuleId}`)
  },
  /**
   * Gain/Loss规则启用
   * @param {string} entityId 主体id
   * @param {string} gainLossAutomationRuleId 规则id
   * @param {object} data
   * @property {boolean} data.enabled 是否启用
   */
  switchGainLossAutomationRuleStatus: (entityId: string, gainLossAutomationRuleId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/gainLossAutomationRule/${gainLossAutomationRuleId}/status`, {
      data
    })
  },
  /**
   * 修改Gain/Loss的rule优先级
   * @param {string} entityId 主体id
   * @param {Array} data [{gainLossAutomationRuleId: '3523'}, {gainLossAutomationRuleId: '3921'}]
   */
  editGainLossAutomationRulePriority: (entityId: string, data: any[]) => {
    return http.request('put', `/entity/${entityId}/gainLossAutomationRule/priority`, { data })
  },
  /**
   * 执行所有Gain/Loss自动化规则
   * @param {string} entityId 主体id
   */
  executeAllGainLossAutomationRule: (entityId: string) => {
    return http.request('post', `/entity/${entityId}/gainLossAutomationRule/execute`)
  },
  /**
   * 获取businessData生成Valuation规则列表
   * @param {string} entityId 主体id
   * @param {object} params
   * @property {number} params.page
   * @property {number} params.limit
   */
  getDerivativeFactTransformerRuleList: (entityId: string, params: object) => {
    return http.request<ResponseData<AutomationRuleListType>>(
      'get',
      `/entity/${entityId}/valuation/derivative/fact/transformer`,
      {
        params
      }
    )
  },
  /**
   * 添加businessData生成Valuation自动化规则
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {string} data.name 规则名称
   * @property {string} data.businessDataTypeId
   * @property {Array} data.conditions 规则
   * @property {string} data.derivativeType derivativeType
   * @property {object} data.actions
   * @property {number} data.actions.datetime 时间
   * @property {string} data.actions.timezone 时区
   * @property {number} data.actions.referenceNo
   * @property {string} data.actions.type 'FUTURE_UNREALIZED'|FUTURE_REALIZED|OPTION_UNREALIZED|OPTION_REALIZED
   * @property {number} data.actions.assetName 产品文案是 Symbol
   * @property {number} [data.actions.positionSide] 仓位方向
   * @property {number} data.actions.currency 保证金币种/配额币种
   * @property {number} data.actions.amount
   */
  addDerivativeFactTransformerRule: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/valuation/derivative/fact/transformer`, { data })
  },
  /**
   * 修改businessData生成Valuation自动化规则
   * @param {string} entityId 主体id
   * @param {string} derivativeFactTransformerId 主体id
   * @param {object} data
   * @property {string} data.name 规则名称
   * @property {string} data.businessDataTypeId
   * @property {Array} data.conditions 规则
   * @property {string} data.derivativeType derivativeType
   * @property {object} data.actions
   * @property {number} data.actions.datetime 时间
   * @property {string} data.actions.timezone 时区
   * @property {number} data.actions.referenceNo
   * @property {string} data.actions.type 'FUTURE_UNREALIZED'|FUTURE_REALIZED|OPTION_UNREALIZED|OPTION_REALIZED
   * @property {number} data.actions.assetName 产品文案是 Symbol
   * @property {number} [data.actions.positionSide] 仓位方向
   * @property {number} data.actions.currency 保证金币种/配额币种
   * @property {number} data.actions.amount
   */
  editDerivativeFactTransformerRule: (entityId: string, derivativeFactTransformerId: string, data: object) => {
    return http.request(
      'put',
      `/entity/${entityId}/valuation/derivative/fact/transformer/${derivativeFactTransformerId}`,
      {
        data
      }
    )
  },
  /**
   * 删除businessData生成Valuation规则
   * @param {string} entityId 主体id
   * @param {string} derivativeFactTransformerId 规则id
   */
  deleteDerivativeFactTransformerRule: (entityId: string, derivativeFactTransformerId: string) => {
    return http.request(
      'delete',
      `/entity/${entityId}/valuation/derivative/fact/transformer/${derivativeFactTransformerId}`
    )
  },
  /**
   * businessData生成Valuation规则启用
   * @param {string} entityId 主体id
   * @param {string} derivativeFactTransformerId 规则id
   * @param {object} data
   * @property {boolean} data.enabled 是否启用
   */
  switchDerivativeFactTransformerRuleStatus: (entityId: string, derivativeFactTransformerId: string, data: object) => {
    return http.request(
      'put',
      `/entity/${entityId}/valuation/derivative/fact/transformer/${derivativeFactTransformerId}/status`,
      {
        data
      }
    )
  },
  /**
   * 执行所有businessData生成Valuation自动化规则
   * @param {string} entityId 主体id
   */
  executeAllDerivativeFactTransformerRule: (entityId: string) => {
    return http.request('post', `/entity/${entityId}/valuation/derivative/fact/transformer/execute`)
  },
  /**
   * 执行单个businessData生成Valuation自动化规则
   * @param {string} entityId 主体id
   * @param {string} derivativeFactTransformerId 规则id
   */
  executeDerivativeFactTransformerRule: (entityId: string, derivativeFactTransformerId: string) => {
    return http.request(
      'post',
      `/entity/${entityId}/valuation/derivative/fact/transformer/${derivativeFactTransformerId}/execute`
    )
  },
  /**
   * 获取Trade列表
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {number} data.page
   * @property {number} data.limit
   * @property {string} data.sortField
   * @property {string} data.sortDirection
   * @property {number[]} data.journalTypeIds  journal类型Id
   * @property {string[]} [data.type] Trade类型
   * @property {object} [data.amount] 金额
   * @property {object} [data.amountFC] 外币金额
   * @property {object} [data.datetime] 交易时间
   * @property {number[]} data.entityAccountId  主体账户Id
   * @property {string} [data.accountType] 账户类型
   * @property {string} [data.tradeHash] TradeId
   */
  getTradeTableList: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/trade`, { data })
  },
  /**
   * 获取GainLoss列表
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {number} data.page
   * @property {number} data.limit
   * @property {string} data.sortField
   * @property {string} data.sortDirection
   * @property {number[]} data.journalTypeIds  journal类型Id
   * @property {string[]} [data.type] gainLoss类型
   * @property {object} [data.amount] 金额
   * @property {object} [data.amountFC] 外币金额
   * @property {object} [data.datetime] 交易时间
   * @property {number[]} data.entityAccountId  主体账户Id
   * @property {string} [data.accountType] 账户类型
   * @property {string} [data.gainLossHash] TradeId
   */
  getGainLossTableList: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/gainLoss`, { data })
  },
  /**
   * 获取Trade详情
   * @param {string} entityId 主体id
   * @param {string} tradeId 交易id
   */
  getTradeDetail: (entityId: string, tradeId: string) => {
    return http.request<ResponseData<TradeDetailType>>('get', `/entity/${entityId}/trade/${tradeId}`)
  },
  /**
   * 获取GainLoss详情
   * @param {string} entityId 主体id
   * @param {string} gainLossId 损益id
   */
  getGainLossDetail: (entityId: string, gainLossId: string) => {
    return http.request<ResponseData<GainLossDetailType>>('get', `/entity/${entityId}/gainLoss/${gainLossId}`)
  },
  /**
   * 修改Trade交易详情
   * @param {string} entityId 主体id
   * @param {string} tradeId
   * @param {object} data
   * @property {string} [data.memo]  备注
   * @property {string[]} [data.transactionAttachmentIds] 附件ids
   */
  editTradeDetail: (entityId: string, tradeId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/trade/${tradeId}`, { data })
  },
  /**
   * 修改Gain/Loss详情
   * @param {string} entityId 主体id
   * @param {string} gainLossId
   * @param {object} data
   * @property {string} [data.memo]  备注
   * @property {string[]} [data.transactionAttachmentIds] 附件ids
   */
  editGainOrLossDetail: (entityId: string, gainLossId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/gainLoss/${gainLossId}`, { data })
  },
  /**
   * 获取Counterparty Identification Name列表
   * @param {string} entityId 主体id
   */
  getCounterpartyIdentificationNameList: (entityId: string) => {
    return http.request<ResponseData<string[]>>('post', `/entity/${entityId}/counterpartyIdentification/listName`)
  },
  /**
   * 获取CounterpartyTransformer规则列表
   * @param {string} entityId 主体id
   * @param {object} params
   * @property {number} params.page
   * @property {number} params.limit
   */
  getCounterpartyTransformerRuleList: (entityId: string, params: object) => {
    return http.request<ResponseData<AutomationRuleListType>>('get', `/entity/${entityId}/counterpartyTransformer`, {
      params
    })
  },
  /**
   * 添加CounterpartyTransformer自动化规则
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {string} data.name 规则名称
   * @property {Array} data.conditions 规则
   * @property {string} data.counterpartyField 对手方字段
   * @property {string} [data.identificationCodeName] 识别码
   * @property {string} data.extractRegex 提取规则
   * @property {boolean} data.sensitive 识别码敏感词
   */
  addCounterpartyTransformerRule: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/counterpartyTransformer`, { data })
  },
  /**
   * 修改CounterpartyTransformer配置规则
   * @param {string} entityId 主体id
   * @param {string} counterpartyTransformerId 规则id
   * @param {object} data
   * @property {string} data.name 规则名称
   * @property {Array} data.conditions 规则
   * @property {string} data.counterpartyField 对手方字段
   * @property {string} [data.identificationCodeName] 识别码
   * @property {string} data.extractRegex 提取规则
   * @property {boolean} data.sensitive 识别码敏感词
   */
  editCounterpartyTransformerRule: (entityId: string, counterpartyTransformerId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/counterpartyTransformer/${counterpartyTransformerId}`, { data })
  },
  /**
   * 删除CounterpartyTransformer规则
   * @param {string} entityId 主体id
   * @param {string} counterpartyTransformerId 规则id
   */
  deleteCounterpartyTransformerRule: (entityId: string, counterpartyTransformerId: string) => {
    return http.request('delete', `/entity/${entityId}/counterpartyTransformer/${counterpartyTransformerId}`)
  },
  /**
   * CounterpartyTransformer规则启用
   * @param {string} entityId 主体id
   * @param {string} counterpartyTransformerId 规则id
   * @param {object} data
   * @property {boolean} data.enabled 是否启用
   */
  switchCounterpartyTransformerRuleStatus: (entityId: string, counterpartyTransformerId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/counterpartyTransformer/${counterpartyTransformerId}/status`, {
      data
    })
  },
  /**
   * 修改CounterpartyTransformer自动化规则优先级
   * @param {string} entityId 主体id
   * @param {Array} data [{counterpartyTransformerId: '5341'}, {counterpartyTransformerId: '5234'}]
   */
  editCounterpartyTransformerRulePriority: (entityId: string, data: any[]) => {
    return http.request('put', `/entity/${entityId}/counterpartyTransformer/priority`, { data })
  },
  /**
   * 执行所有CounterpartyTransformer自动化规则
   * @param {string} entityId 主体id
   */
  executeAllCounterpartyTransformerRule: (entityId: string) => {
    return http.request('post', `/entity/${entityId}/counterpartyTransformer/execute`)
  }
}
