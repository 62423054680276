import { useGlobalStore } from '@/stores/modules/global'
import { ProjectDetailType } from '#/ProjectDetailTypes'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { RouteLocationNormalized, RouteLocationNormalizedLoaded } from 'vue-router'
import { ProjectPermissionLevel, ProjectReviewStatus, ProjectSubscribeStatus } from '#/ProjectTypes'

const checkFreeProjectStatus = (freeProject: ProjectDetailType) => {
  const searchParams = new URLSearchParams(window.location.search)
  const searchProjectId = searchParams.get('projectId')
  if (freeProject.projectReview?.status === ProjectReviewStatus.UNACTIVATION) {
    const freeRouteData: any = {
      name: 'landing-activate',
      query: { projectId: freeProject.projectId }
    }
    if (searchProjectId !== freeProject.projectId) {
      delete freeRouteData.query
    }
    return freeRouteData
  }
  if (!freeProject?.entityList?.length) {
    return { name: 'entity-settings', params: { projectId: freeProject.projectId } }
  }
  return null
}

export const checkProjectStatus = () => {
  const globalStore = useGlobalStore()
  const freeProject = globalStore.projectList.find((item) => item.plan === ProjectPermissionLevel.FREE)
  if (freeProject) {
    const freeRouterPathInfo = checkFreeProjectStatus(freeProject)
    if (freeRouterPathInfo) {
      return freeRouterPathInfo
    }
  }
  const waitPayMentProject = globalStore.projectList.find(
    (item) => item.subscribeStatus === ProjectSubscribeStatus.WAIT_PAYMENT
  )
  if (waitPayMentProject) {
    return { name: 'landing-subscription', query: { projectId: waitPayMentProject.projectId } }
  }
  const emptyEntityProject = globalStore.projectList.find((item) => !item?.entityList?.length)
  if (emptyEntityProject) {
    return { name: 'entity-settings', params: { projectId: emptyEntityProject.projectId } }
  }
  const currentProject = globalStore.projectList[0]
  if (currentProject) {
    if (currentProject.entityList?.length) {
      return {
        name: 'entity',
        params: {
          entityId: currentProject.entityList[0].entityId
        }
      }
    }
    return { name: 'entity-settings', params: { projectId: currentProject.projectId } }
  }
  return { name: 'landingPage' }
}

export const projectRouterGuider = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalizedLoaded,
  isInitMenu: boolean = false
) => {
  const globalStore = useGlobalStore()
  const userGlobalStore = useUserGlobalStore()
  const menuGlobalStore = useMenuGlobalStore()
  if (from.query?.ref || from?.redirectedFrom?.name === 'basic-free-project') {
    if (!globalStore.projectList?.length) {
      return { name: 'landing-apply', query: { ref: from.query?.ref, ...from?.redirectedFrom?.query } }
    }
    const freeProject = globalStore.projectList.find((item) => item.plan === ProjectPermissionLevel.FREE)
    if (!freeProject) {
      return { name: 'landing-apply', query: { ref: from.query?.ref, ...from?.redirectedFrom?.query } }
    }
    const freeRouterPathInfo = checkFreeProjectStatus(freeProject)
    if (freeRouterPathInfo) {
      return freeRouterPathInfo
    }
  }
  if (globalStore.projectList?.length) {
    if (isInitMenu && !menuGlobalStore.menuList.length) {
      await menuGlobalStore.menuListInit()
    }
    if (globalStore.entityListAll?.length) {
      const freeProject = globalStore.projectList.find((item) => item.plan === ProjectPermissionLevel.FREE)
      if (freeProject) {
        const freeRouterPathInfo = checkFreeProjectStatus(freeProject)
        if (freeRouterPathInfo) {
          return freeRouterPathInfo
        }
      }

      const unPaymentProject = globalStore.projectList.find((item) => !item?.entityList?.length)
      if (unPaymentProject) {
        if (unPaymentProject.subscribeStatus === ProjectSubscribeStatus.WAIT_PAYMENT) {
          return { name: 'landing-subscription', query: { projectId: unPaymentProject.projectId } }
        }
        if (!unPaymentProject?.entityList?.length) {
          return { name: 'entity-settings', params: { projectId: unPaymentProject.projectId } }
        }
      }

      if (menuGlobalStore.menuList[0]?.children?.[0]?.children?.[0]?.jumpUrl) {
        return {
          path: menuGlobalStore.menuList[0]?.children?.[0]?.children?.[0]?.jumpUrl
        }
      }
      return {
        name: 'entity',
        params: {
          entityId: userGlobalStore.user?.navigationSort?.[0]?.entityIds?.[0] ?? globalStore.entityListAll[0]?.entityId
        }
      }
    }
    return checkProjectStatus()
  }
  return { name: 'landingPage' }
}
