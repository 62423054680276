import type { Emitter } from 'mitt'
import mitt from 'mitt'

type Events = {
  chartEnlarged: Number
  onCheckProcessTableDataSource: any
  onCheckFinancialDataSource: any
  selectDataSource: any
  onStartSync: any
  resetList: any
  resetAccountList: any
  resetDerivativeList: any
  financialsDataInit: any
  onFetchIntervalSourceList: any
  resetDuplicateDataList: any
  onClickedRegenerate: any
  onPushOpenCollapse: { type: string; projectId: string; name: string; entityId?: string; isEdit?: boolean }
  onDeleteTableRow: string[]
  onEditTableRowEvent: any
  onResetBusinessDataRuleList: string
  setLocalVersion: string
  reconciliationTaskDataInit: any
  reconciliationTaskDataSetsInit: any
  reconciliationDetailsDataInit: any
  getReconciliationReportList: any
  getReconciliationMatchedList: any
}

export const emitter: Emitter<Events> = mitt<Events>()
