import '@/assets/fonts/font.css'
import { store } from '@/stores/index'
import { createApp, type Directive } from 'vue'
import App from './App.vue'
// import App from '@/pages/MaintenancePage.vue' // 维护中注释
import router from './router'
import './assets/js/google'
// eslint-disable-next-line import/no-unresolved
import 'virtual:svg-icons-register'
// 按需要引入 element-plus
import { globalRegister } from './gloable'
/// #if COMMAND === 'serve'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
/// #else
import 'element-plus/theme-chalk/el-loading.css'
import 'element-plus/theme-chalk/el-message.css'
/// #endif

import VueCookies from 'vue3-cookies'
// 自定义指令
import * as directives from '@/directive'

// 引入需要注册的全部组件
import globalComponent from '@/components/index'
import { useI18n } from '@/i18n/index'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import { preloadErrorReload, getUpdateVersion } from '@/lib/updateVersion'

const app = createApp(App)

app
  .use(globalRegister)
  .use(store)
  .use(VueCookies, { expireTimes: '-1', secure: true, path: '/', domain: import.meta.env.VITE_APP_COOKIES_DOMAIN })

  .use(useI18n)
  .use(router)
  .use(VueDOMPurifyHTML)
  .use(globalComponent)
/// #if COMMAND === 'serve'
app.use(ElementPlus)
/// #endif

app.config.idPrefix = 'elvId'

// 自定义指令
Object.keys(directives).forEach((key) => {
  app.directive(key, (directives as { [key: string]: Directive })[key])
})

app.mount('#app')

getUpdateVersion()
preloadErrorReload()
