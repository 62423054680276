<template>
  <svg :class="{ 'custom-fill': props.fill !== '' }">
    <use :xlink:href="symbolId" />
  </svg>
</template>

<script setup lang="ts">
const props = defineProps({
  name: {
    type: String,
    required: true,
    default: 'contract-report'
  },
  fill: {
    type: String,
    default: ''
  }
})

const symbolId = computed(() => `#icon-${props.name}`)
</script>

<style scoped>
.custom-fill {
  fill: v-bind('props.fill');
}

svg {
  outline: none;
}
</style>
